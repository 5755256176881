import "./selectable.css";
import * as common from "../common/common";
import * as SelectableTypes from "./types";
import TinyEventEmitter from "../tiny-event-emitter";


export default class Selectable extends TinyEventEmitter {

  selectgroup: string;

  private _mousedownEvent: any;
  private _mouseupEvent: any;
  private _keydownEvent: any;

  private _context: Document;

  constructor(selectgroup: string, context: Document = document) {

    super();


    //-- Set context

    this._context = context;

    this.selectgroup = selectgroup;

    this._mousedownEvent = this._mousedown.bind(this);
    this._mouseupEvent = this._mouseup.bind(this);
    this._keydownEvent = this._keydown.bind(this);


    //-- Initialize eventlisteners

    this._context.addEventListener("mousedown", this._mousedownEvent);
    this._context.addEventListener("mouseup", this._mouseupEvent);
    this._context.addEventListener("keydown", this._keydownEvent);

  }


  private _mousedown(ev: MouseEvent): void {

    let target = ev.target as Element;


    //-- Get target if click on nested element

    if(!target.classList.contains("selectable") && !target.classList.contains("selectable-container")){
      const parent = common.queryNextParentNodeBySelectors(target, ".selectable");

      if(parent !== undefined){
        target = parent;
      }
    }

    if(!target){
      return;
    }


    //-- Get parent .selectable if click on nested .selectable-container

    if(target.classList.contains("selectable-container")){
      const parentSelectable = common.queryNextParentNodeBySelectors(target, ".selectable");
      if(parentSelectable !== undefined){
        target = parentSelectable;
      }
    }

    if(!target){
      return;
    }


    //-- Check contextmenu

    if(ev.button === 2){
      if(target.classList.contains("selectable")){
        if(!target.classList.contains("selected")){
          this.deselectAll();
          this.select(target);
        }
      }
    }


    //-- Allow only left clicks from here

    if(ev.button !== 0){
      return;
    }


    //-- Check if target is selectabel

    if(target.classList.contains("selectable")){


      //-- Check if .selectable is inside this.container

      const parent = common.queryNextParentNodeBySelectors(target, ".selectable-container");

      if(parent === undefined){
        return;
      }

      if(parent.getAttribute("selectgroup") !== this.selectgroup){
        return;
      }


      //-- Check if ctrl/cmd is pressed

      if(ev.ctrlKey || ev.metaKey){


        //-- Select/Deselect

        if(target.classList.contains("selected")){
          target.classList.remove("selected");
        } else {
          this.select(target);
        }

        return;

      }


      //-- Check if shift is pressed

      if(ev.shiftKey){

        const initialSelection = this._getInitialSelection();

        if(initialSelection !== undefined){
          this._selectBlock(initialSelection, target);
          return;
        }
      }


      //-- Select on if not yet .selected

      if(!target.classList.contains("selected")){

        this.deselect();
        this.select(target);

        return;
      }

      return;

    }

    if(target.classList.contains("selectable-container")){


      //-- Check if target is this.container

      if(target.getAttribute("selectgroup") === this.selectgroup){
        return;
      }

      this.deselect();

      return;
    }


    //-- Do not deselect when clicking on contextmenu

    if(common.queryNextParentNodeBySelectors(target, ".chcr-contextmenu") !== undefined){
      return;
    }


    //-- Do not deselect when clicking on popover

    if(common.queryNextParentNodeBySelectors(target, ".chcr-popover") !== undefined){
      return;
    }


    //-- Deselect if click on any other element outside .selectable-container

    this.deselectAll();
  }


  private _mouseup(ev: MouseEvent) {

    const target = ev.target as Element;


    //-- Allow only left clicks

    if(ev.button !== 0){
      return;
    }


    //-- Check if target is selectabel

    if(target.classList.contains("selectable")){


      //-- Check if .selectable is inside this.container

      const parent = common.queryNextParentNodeBySelectors(target, ".selectable-container");

      if(parent === undefined){
        return;
      }

      if(parent.getAttribute("selectgroup") !== this.selectgroup){
        return;
      }


      //-- If no modifier key is pressed delete selection and select only target

      if(!ev.ctrlKey && !ev.metaKey && !ev.shiftKey){
        this.deselect();
        this.select(target);
      }

    }

  }


  private _keydown(ev: KeyboardEvent): void {

    if(ev.code === "ArrowDown" || ev.code === "ArrowUp" || ev.code === "ArrowRight" || ev.code === "ArrowLeft"){

      let nextElement: Element | undefined;
      const preselectedElement = this.getPreselectedElement();

      if(preselectedElement === undefined){
        return;
      }

      if(ev.code === "ArrowDown" || ev.code === "ArrowRight"){
        nextElement = this._getNextElement(preselectedElement);
      }
      if(ev.code === "ArrowUp" || ev.code === "ArrowLeft"){
        nextElement = this._getPreviousElement(preselectedElement);
      }

      if(!nextElement){
        return;
      }

      if(ev.shiftKey){

        const initialSelection = this._getInitialSelection();
        if(initialSelection !== undefined){
          this._selectBlock(initialSelection, nextElement);
        }

      } else {
        this._preSelect(nextElement);
      }

    }

    if(ev.code === "Space"){

      const preselectedElement = this.getPreselectedElement();

      if(preselectedElement !== undefined){

        if(preselectedElement.classList.contains("selected")){
          preselectedElement.classList.remove("selected");
        } else {
          preselectedElement.classList.add("selected");
        }
      }

    }

    if(ev.code === "Delete"){

      const selection = this.getSelection();

      this.emit("delete", <SelectableTypes.DeleteEvent>{"elements": selection});

    }
  }


  public deselect(): void {

    const selectables = this._getSelectables();

    for(let s = 0; s < selectables.length; s++){
      selectables[s].classList.remove("selected");
      selectables[s].classList.remove("preselect");
      selectables[s].classList.remove("ghost");
      selectables[s].classList.remove("initial-selection");
    }

  }


  public deselectAll(): void {

    const selectables = this.getAllSelectables();

    for(let s = 0; s < selectables.length; s++){
      selectables[s].classList.remove("selected");
      selectables[s].classList.remove("preselect");
      selectables[s].classList.remove("ghost");
      selectables[s].classList.remove("initial-selection");
    }

  }


  public getSelection(): Array<Element> {

    const selectables = Array.from(this._getSelectables());

    return selectables.filter((selectable => {
      return selectable.classList.contains("selected");
    }));

  }


  public getPreselectedElement(): Element | undefined {

    const selectables = this._getSelectables();

    for(let s = 0; s < selectables.length; s++){
      if(selectables[s].classList.contains("preselect")){
        return selectables[s];
      }
    }

    return undefined;

  }


  private _getNextElement(element: Element): Element | undefined {

    const selectables = this._getSelectables();
    const index = selectables.indexOf(element);

    if(index !== selectables.length){
      return selectables[index + 1];
    }

    return undefined;

  }


  private _getPreviousElement(element: Element): Element | undefined {

    const selectables = this._getSelectables();

    const index = selectables.indexOf(element);

    if(index !== 0){
      return selectables[index - 1];
    }

    return undefined;

  }


  private _preSelect(element: Element) {


    const selectables = this.getAllSelectables();

    for(let s = 0; s < selectables.length; s++){
      selectables[s].classList.remove("preselect");
    }

    element.classList.add("preselect");

  }


  public select(element: Element) {


    //-- Check if any element has .initial-selection class

    const selectables = this._getSelectables();
    let initial = true;
    for(let s = 0; s < selectables.length; s++){
      if(selectables[s].classList.contains("initial-selection")){
        initial = false;
        break;
      }
    }

    if(initial === true){
      this._selectInitial(element);
    }

    element.classList.add("selected");


    //-- Preselect element

    this._preSelect(element);


    //-- Emit change event

    const selection = this.getSelection();

    this.emit("change", selection);

  }


  private _selectInitial(element: Element) {

    this.deselectAll();

    const selectables = this._getSelectables();

    for(let s = 0; s < selectables.length; s++){
      selectables[s].classList.remove("initial-selection");
    }

    element.classList.add("initial-selection");

  }


  private _getInitialSelection(): Element | undefined {

    const selectables = this._getSelectables();

    for(let s = 0; s < selectables.length; s++){
      if(selectables[s].classList.contains("initial-selection")){
        return selectables[s];
      }
    }

    return undefined;
  }


  private _getSelectables(): Array<Element> {

    return Array.from(this._context.querySelectorAll(".selectable-container .selectable[selectgroup='" + this.selectgroup + "']"));

  }

  public getAllSelectables(): Array<Element> {

    return Array.from(this._context.querySelectorAll(".selectable"));

  }


  private _selectBlock(from: Element, to: Element): void {

    const selectables = this._getSelectables();

    const fromIndex = selectables.indexOf(from);
    const toIndex = selectables.indexOf(to);

    for(const selectable of selectables){
      selectable.classList.remove("selected");
    }

    if(fromIndex < toIndex){
      for(let i = fromIndex; i <= toIndex; i++){
        this.select(selectables[i]);
      }
    } else {
      for(let i = fromIndex; i >= toIndex; i--){
        this.select(selectables[i]);
      }
    }

  }

}