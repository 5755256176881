import "./contextmenu.css";
import * as ContextmenuTypes from "./types";
import TinyEventEmitter from "../tiny-event-emitter";

let posX = 0;
let posY = 0;

window.addEventListener("mousemove", ev => {
  posX = ev.clientX;
  posY = ev.clientY;
});

window.addEventListener("touchstart", updateTouchPosition);
window.addEventListener("touchmove", updateTouchPosition);

function updateTouchPosition(ev) {
  const event = ev as TouchEvent;
  if(event.touches === undefined){
    return;
  }
  posX = event.touches[0].screenX;
  posY = event.touches[0].screenY;
}


export default class Contextmenu extends TinyEventEmitter {

  private _target: Element;
  private _closeListener: any;
  private _sourceDocument: Document;
  private _targetDocument: Document;

  public contextmenu: HTMLDivElement;

  constructor(target: Element, html: string, options?: ContextmenuTypes.Options, sourceDocument: Document = document, targetDocument: Document = document) {

    super();

    if(options !== undefined){
      if(options.posX !== undefined){
        posX = options.posX;
      }
      if(options.posY !== undefined){
        posY = options.posY;
      }
    }

    this._closeListener = this.close.bind(this);
    this._target = target;
    this._sourceDocument = sourceDocument;
    this._targetDocument = targetDocument;


    //-- Remove old contextmenus

    const oldContextMenus = this._targetDocument.getElementsByClassName("chcr-contextmenu");
    for(let o = 0; 0 < oldContextMenus.length; o++){
      const parent = oldContextMenus[o].parentNode;
      if(parent === null){
        continue;
      }
      parent.removeChild(oldContextMenus[o]);
    }


    //-- Create new contextmenu

    this.contextmenu = this._targetDocument.createElement("div");
    this.contextmenu.classList.add("chcr-contextmenu");
    this.contextmenu.style.setProperty("left", posX + "px");
    this.contextmenu.style.setProperty("top", posY + "px");

    this.contextmenu.innerHTML = html;

    this._targetDocument.body.appendChild(this.contextmenu);


    //-- Update position if element is outside viewport

    if(posX + this.contextmenu.clientWidth > window.innerWidth){
      posX = window.innerWidth - this.contextmenu.clientWidth;
      this.contextmenu.style.setProperty("left", posX + "px");
    }

    if(posY + this.contextmenu.clientHeight > window.innerHeight){
      posY = window.innerHeight - this.contextmenu.clientHeight;
      this.contextmenu.style.setProperty("top", posY + "px");
    }


    //-- Initialize event listeners

    const listElements = this.contextmenu.getElementsByTagName("li");

    for(let l = 0; l < listElements.length; l++){
      listElements[l].addEventListener("mouseup", this._click.bind(this));
    }

    this._targetDocument.addEventListener("click", this._closeListener);
    this._targetDocument.addEventListener("dragstart", this._closeListener);

    if(this._sourceDocument !== this._targetDocument){
      this._sourceDocument.addEventListener("click", this._closeListener);
      this._sourceDocument.addEventListener("dragstart", this._closeListener);
    }

  }


  public close(): void {

    if(this.contextmenu === undefined){
      return;
    }


    //-- Remove event listeners

    this._targetDocument.removeEventListener("click", this._closeListener);
    if(this._sourceDocument !== this._targetDocument){
      this._sourceDocument.removeEventListener("click", this._closeListener);
    }

    const listElements = this.contextmenu.getElementsByTagName("li");

    if(listElements === undefined){
      return;
    }

    for(let l = 0; l < listElements.length; l++){
      listElements[l].removeEventListener("click", this._click);
    }


    //-- Remove contextmenu

    const parent = this.contextmenu.parentNode;
    if(parent === null){
      return;
    }

    parent.removeChild(this.contextmenu);

  }


  private _click(ev: MouseEvent) {

    if(ev.button !== 0){ // Left click only
      return;
    }

    this.emit("click", <ContextmenuTypes.ClickEvent>{ "target": ev.target as Element, "parent": this._target });
    this.close.bind(this);

  }
}
