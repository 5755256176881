import "./sortable.css";
import Selectable from "../selectable";
import * as common from "../common/common";
import * as SortableTypes from "./types";
import TinyEventEmitter from "../tiny-event-emitter";

export default class Sortable extends TinyEventEmitter {

  public sortgroup: string;
  public selectable: Selectable;

  private _sourceContainer: Element | undefined;

  private _dragoverEvent: (ev: DragEvent) => void;
  private _dragstartEvent: (ev: DragEvent) => void;
  private _dragendEvent: (ev: DragEvent) => void;
  private _dropEvent: (ev: DragEvent) => void;
  private _keydownEvent: (ev: KeyboardEvent) => void;

  private _awaitingAnimationFrame: boolean = false;
  private _alreadyCloned: boolean = false;
  private _alreadyDropped: boolean = false;

  private _originalOrder: Array<SortableTypes.Order> = [];
  private _originalSelection: Array<Element> = [];
  private _originalSourceContainer: Element | undefined;

  private _context: Document;

  constructor(sortgroup: string, context: Document = document) {

    super();

    this._context = context;

    this.sortgroup = sortgroup;

    this._dragoverEvent = this._dragover.bind(this);
    this._dragstartEvent = this._dragstart.bind(this);
    this._dragendEvent = this._dragend.bind(this);
    this._dropEvent = this._drop.bind(this);
    this._keydownEvent = this._keydown.bind(this);


    //-- Initialize eventlisteners

    this._context.addEventListener("dragover", this._dragoverEvent);
    this._context.addEventListener("dragstart", this._dragstartEvent);
    this._context.addEventListener("dragend", this._dragendEvent);
    this._context.addEventListener("drop", this._dropEvent);
    this._context.addEventListener("keydown", this._keydownEvent);


    //-- Initialize selectable

    this.selectable = new Selectable(this.sortgroup);

  }


  private _dragstart(ev: DragEvent): void {

    let target = ev.target as Element;


    //-- Set target to .sortable

    if(!target.classList.contains("sortable")){
      const nextMatchingParent = queryParentNodesBySelector(target, ".sortable");
      if(nextMatchingParent){
        target = nextMatchingParent[0];
      }
    }

    if(target === null){
      return;
    }


    //-- Check if sortgroups match

    const targetSortgroup = target.getAttribute("sortgroup");
    if(targetSortgroup === null){
      return;
    }

    if(targetSortgroup !== this.sortgroup){
      return;
    }


    //-- Setup draggable

    if(ev.dataTransfer !== null){
      ev.dataTransfer.setData("text", "data"); // Needed for firefox
    }


    //-- Set containers

    const parent = target.parentElement;

    if(parent === null){
      return;
    }

    this._sourceContainer = parent;
    this._originalSourceContainer = parent;


    //-- Reset values

    this._alreadyCloned = false;
    this._alreadyDropped = false;
    this._originalOrder = [];


    //-- Store original order

    const containers = this._getContainers();

    for(const container of containers){
      this._originalOrder.push({
        parent: container,
        children: Array.from(container.querySelectorAll(":scope > .sortable"))
      });
    }

  }


  private _dragover(ev: DragEvent): void {

    ev.preventDefault();


    //-- Abort if dragstart was not on this instance

    if(!this._sourceContainer){
      return;
    }


    let target = ev.target as Element;


    //-- Set target to .sortable or .sortable-container or .sortable-source if drag over nested element

    if(!target.classList.contains("sortable-container") && !target.classList.contains("sortable-source")){
      if(!target.classList.contains("sortable")){
        const nextMatchingParent = common.queryNextParentNodeBySelectors(target, ".sortable, .sortable-container, sortable-source");
        if(nextMatchingParent){
          target = nextMatchingParent;
        }
      }
    }

    if(target === null){
      return;
    }

    const parent = target.parentElement;

    if(parent === null){
      return;
    }


    //-- Check if parent is .sortable-source

    if(target.classList.contains("sortable-source") || parent.classList.contains("sortable-source")){
      this._resetSelection();
      return;
    }


    //-- Check if parent is .sortable-container

    if(!target.classList.contains("sortable-container") && !parent.classList.contains("sortable-container")){
      this._resetSelection();
      return;
    }


    //-- Get preselected elements

    const preselect = this.selectable.getPreselectedElement();

    if(!preselect){
      return;
    }


    //-- Check if sortgroups match

    if(target.classList.contains("sortable-container")){

      const targetSortgroup = target.getAttribute("sortgroup");
      const preselectSortgroup = preselect.getAttribute("sortgroup");

      if(targetSortgroup === null || preselectSortgroup === null){
        return;
      }

      if(targetSortgroup !== preselectSortgroup){
        if(ev.dataTransfer !== null){
          ev.dataTransfer.dropEffect = "none";
          ev.dataTransfer.effectAllowed = "none";
        }
        return;
      }

    } else {

      if(target.parentElement === null){
        return;
      }

      const targetParentSortgroup = target.parentElement.getAttribute("sortgroup");
      const preselectSortgroup = preselect.getAttribute("sortgroup");

      if(targetParentSortgroup === null || preselectSortgroup === null){
        return;
      }

      if(targetParentSortgroup !== preselectSortgroup){
        if(ev.dataTransfer !== null){
          ev.dataTransfer.dropEffect = "none";
          ev.dataTransfer.effectAllowed = "none";
        }
        return;
      }
    }


    //-- Abort if already requested an animation frame

    if(this._awaitingAnimationFrame === true){
      return;
    }

    this._awaitingAnimationFrame = true;


    //-- Request animation to make sure that DOM is already altered

    window.requestAnimationFrame(() => {

      this._awaitingAnimationFrame = false;

      if(this._sourceContainer === undefined){
        return;
      }


      const selection = this.selectable.getSelection();


      //-- Check if dragover itself

      for(const selectedElement of selection){
        if(target === selectedElement){
          return;
        }
      }


      //-- Set sort effect

      let sorteffect = (this._sourceContainer.getAttribute("sorteffect") as DataTransfer["dropEffect"]) || "move";

      if(sorteffect !== "move"){


        //-- Force copy if ctrl key is pressed AND copy is alowed

        if(ev.ctrlKey || ev.metaKey){
          if(sorteffect.toLowerCase().includes("copy")){
            sorteffect = "copy";
          }
        }

      }

      if(ev.dataTransfer !== null){
        ev.dataTransfer.dropEffect = sorteffect;
        ev.dataTransfer.effectAllowed = sorteffect;
      }


      //-- Clone elements if sorteffect is copy

      if(sorteffect === "copy" && this._alreadyCloned !== true){

        this._deselectAll();

        this._originalSelection = [];

        for(let s = 0; s < selection.length; s++){
          this._originalSelection.push(selection[s]);
          selection[s] = selection[s].cloneNode(true) as Element ;

          this.selectable.select(selection[s]);
        }

        this.emit("paste", <SortableTypes.PasteEvent>{ "elements": selection });

        this._alreadyCloned = true;

      }


      //-- Add ghost class

      for(let s = 0; s < selection.length; s++){
        selection[s].classList.add("ghost");
      }


      //-- Dragover on .sortable

      if(target.classList.contains("sortable")){

        if(target.parentElement === null){
          return;
        }


        //-- Update this.sourceContainer

        this._sourceContainer = target.parentElement;


        //-- Append if dragover last element

        if(target.nextElementSibling === null){
          for(let s = 0; s < selection.length; s++){
            this._sourceContainer.appendChild(selection[s]);
          }
          return;
        }


        //-- Insert before any other element

        const rect = target.getBoundingClientRect();
        const isOverVerticalCenter = ((ev.clientY - rect.top) / (rect.bottom - rect.top) > .5 ? true : false);
        const nextElement = target.nextElementSibling;
        let direction = "down";


        //-- Determine direction

        for(let s = 0; s < selection.length; s++){
          if(nextElement === selection[s]){
            direction = "up";
          }
        }

        if(isOverVerticalCenter === true){
          if(direction === "down"){
            for(let s = 0; s < selection.length; s++){
              this._sourceContainer.insertBefore(selection[s], nextElement);
            }
          }
        } else {
          for(let s = 0; s < selection.length; s++){
            this._sourceContainer.insertBefore(selection[s], target);
          }
        }

      }


      //-- Dragover on parent

      if(target.classList.contains("sortable-container")){


        //-- Check if target is own child

        for(let s = 0; s < selection.length; s++){
          if(isDescendant(selection[s], target)){
            return;
          }
        }


        //-- Abort if already inserted into parent

        if(this._sourceContainer === target && sorteffect !== "copy"){
          return;
        }


        //-- Update this.sourceContainer

        this._sourceContainer = target;


        //-- Check if parent has sortable children, if so put it under the last .sortable child, else put it at the bottom

        const children = target.children;
        let lastSortableChild: Element | undefined;

        if(children.length > 0){

          for(let c = 0; c < children.length; c++){
            const child = children[c];

            if(child.classList.contains("sortable")){
              lastSortableChild = child;
            }
          }

          if(lastSortableChild !== undefined){
            if(lastSortableChild.nextElementSibling !== null){


              //-- Sortable children and another element: Insert before other element

              // Store last element since target.nextElementSibling changes after elements get inserted
              const lastElement = lastSortableChild.nextElementSibling;

              for(let s = 0; s < selection.length; s++){
                target.insertBefore(selection[s], lastElement);
              }

            } else {


              //-- Sortable children but no other: Append to parent

              for(let s = 0; s < selection.length; s++){
                target.appendChild(selection[s]);
              }

            }
          } else {


            //-- No sortable children, but other element: Insert before other element.

            for(let s = 0; s < selection.length; s++){
              target.insertBefore(selection[s], children[0]);
            }

          }

        } else {


          //-- No children at all: Append to parent

          for(let s = 0; s < selection.length; s++){
            target.appendChild(selection[s]);
          }

        }

      }


    });

  }


  private _dragend(ev: DragEvent): void {

    ev.preventDefault();

    this._resetSelection();


    //-- Deselect all

    this._deselectAll();

  }


  private _resetSelection() {


    //-- Abort if dragstart was not on this instance

    if(!this._sourceContainer){
      return;
    }

    if(this._alreadyDropped){
      return;
    }


    //-- Get selected elements

    let selection = this.selectable.getSelection();


    //-- Remove clones

    if(this._alreadyCloned === true){

      for(let s = 0; s < selection.length; s++){
        const parent = selection[s].parentNode;

        if(parent === null){
          continue;
        }

        parent.removeChild(selection[s]);

      }


      //-- Reset selection

      this._deselectAll();

      selection = [];

      for(let o = 0; o < this._originalSelection.length; o++){
        selection[o] = this._originalSelection[o];
        this.selectable.select(this._originalSelection[o]);
      }

      this._sourceContainer = this._originalSourceContainer;
      this._alreadyCloned = false;

      return;

    } else {

      for(let o = 0; o < this._originalOrder.length; o++){
        for(let c = this._originalOrder[o].children.length - 1; c >= 0; c--){
          for(let s = 0; s < selection.length; s++){
            if(selection[s].isSameNode(this._originalOrder[o].children[c])){
              if(c <= this._originalOrder[o].children.length - 2){
                this._originalOrder[o].parent.insertBefore(selection[s], this._originalOrder[o].children[c + 1]);
              } else {
                if(this._originalOrder[o].parent.children.length > this._originalOrder[o].children.length){
                  this._originalOrder[o].parent.insertBefore(selection[s], this._originalOrder[o].children[c].nextElementSibling);
                } else {
                  this._originalOrder[o].parent.appendChild(selection[s]);
                }
              }
            }
          }
        }
      }

      this._sourceContainer = this._originalSourceContainer;

    }

  }


  private _drop(ev: DragEvent): void {

    ev.preventDefault();


    //-- Abort if dragstart was not on this instance

    if(!this._sourceContainer){
      return;
    }

    let target = ev.target as Element;


    //-- Set target to .sortable or .sortable-container or .sortable-source if drag over nested element

    if(!target.classList.contains("sortable-container") && !target.classList.contains("sortable-source")){
      if(!target.classList.contains("sortable")){
        const nextMatchingParent = common.queryNextParentNodeBySelectors(target, ".sortable, .sortable-container, sortable-source");
        if(nextMatchingParent){
          target = nextMatchingParent;
        }
      }
    }

    if(!target){
      return;
    }


    //-- Check if parent is .sortable-container

    if(!target.classList.contains("sortable-container") && (target.parentElement !== null && !target.parentElement.classList.contains("sortable-container"))){
      return;
    }


    //-- Get preselected element

    const preselect = this.selectable.getPreselectedElement();

    if(!preselect){
      return;
    }


    //-- Check if sortgroups match

    if(target.classList.contains("sortable-container")){

      const targetSortgroup = target.getAttribute("sortgroup");
      const preselectSortgroup = preselect.getAttribute("sortgroup");

      if(targetSortgroup === null || preselectSortgroup === null){
        return;
      }

      if(targetSortgroup !== preselectSortgroup){
        if(ev.dataTransfer !== null){
          ev.dataTransfer.dropEffect = "none";
          ev.dataTransfer.effectAllowed = "none";
        }
        return;
      }

    } else {

      if(target.parentElement === null){
        return;
      }

      const targetParentSortgroup = target.parentElement.getAttribute("sortgroup");
      const preselectSortgroup = preselect.getAttribute("sortgroup");

      if(targetParentSortgroup === null || preselectSortgroup === null){
        return;
      }

      if(targetParentSortgroup !== preselectSortgroup){
        if(ev.dataTransfer !== null){
          ev.dataTransfer.dropEffect = "none";
          ev.dataTransfer.effectAllowed = "none";
        }
        return;
      }

    }


    this._alreadyDropped = true;


    //-- Gather changes

    const newOrder: Array<SortableTypes.Order> = [];
    const containers = this._getContainers();

    for(const container of containers){
      newOrder.push({
        parent: container,
        children: Array.from(container.querySelectorAll(":scope > .sortable"))
      });
    }


    //-- Check if order has changed

    let changed = false;

    if(newOrder.length !== this._originalOrder.length){
      changed = true;
    } else {
      newOrder.forEach((list: any, listIndex) => {
        list.children.forEach((child, childIndex) => {
          if(child !== this._originalOrder[listIndex].children[childIndex]){
            changed = true;
          }
        });
      });
    }


    //-- Deselect all

    this._deselectAll();


    //-- Emit change event

    if(changed){
      this.emit("change", <SortableTypes.ChangeEvent>{ "order": newOrder, "original": this._originalOrder });
    }

  }


  private _keydown(ev: KeyboardEvent): void {

    if(ev.code === "Delete"){


      const target = this.selectable.getPreselectedElement();

      if(target === undefined){
        return;
      }

      if(!target.classList.contains("sortable")){
        return;
      }


      const nextMatchingParent = common.queryNextParentNodeBySelectors(target, ".sortable-container");

      if(nextMatchingParent === undefined){
        return;
      }


      //-- Check if sort effect contains move

      const sorteffect = nextMatchingParent.getAttribute("sorteffect") || "move";

      if(!sorteffect.toLowerCase().includes("move")){
        return;
      }


      const selection = this.selectable.getSelection();

      if(selection.length <= 0){
        return;
      }

      this.emit("delete", <SortableTypes.DeleteEvent>{"elements": selection});

    }

  }


  private _getContainers(): Array<Element> {
    let array: Array<Element> = Array.from(this._context.querySelectorAll(".sortable-container[sortgroup='" + this.sortgroup + "']"));
    array = [...array, ...Array.from(this._context.querySelectorAll(".sortable-source[sortgroup='" + this.sortgroup + "']"))];
    return array;
  }


  private _deselectAll(): void {


    //-- Get selected elements

    this.selectable.deselectAll();

  }

}


function queryParentNodesBySelector(element: Element, selector: string): Array<Element> | undefined {

  const array: Array<Element> = [];
  const stopSelector = "body";

  if(element.parentElement === null){
    return undefined;
  }

  element = element.parentElement;
  while(element){

    if(element.matches(selector)){
      array.push(element);
    } else if(stopSelector && element.matches(stopSelector)){
      break;
    }

    if(element.parentElement === null){
      return undefined;
    }

    element = element.parentElement;

  }

  return array;

}


function isDescendant(parent: Element, child: Element): boolean {

  let node = child.parentNode;
  while(node != null){
    if(node == parent){
      return true;
    }
    node = node.parentNode;
  }
  return false;

}