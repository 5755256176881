let identifierCounter = 0;

export function generateIdentifier(): string {
  identifierCounter++;
  const random = Math.floor(Math.random() * Math.floor(99));
  return "CH" + random + "" + new Date().getTime() + "" + identifierCounter;
}


export function removeDuplicatesInArray<T>(array: Array<T>): Array<T> {

  const a = array.concat();

  for(let i = 0; i < a.length; ++i){
    for(let j = i + 1; j < a.length; ++j){
      if(JSON.stringify(a[i]) === JSON.stringify(a[j])){
        a.splice(j--, 1);
      }
    }
  }

  return a;

}


export function calculateAndFormatDistributorPrice(price: number, margin: number = 0): string {
  return formatPrice(price / 100 * (100 - margin));
}


export function formatPrice(price: number): string {
  return price.toFixed(2);
}


export function cleanupSpacesInObject(object: any, excludeKeys: Array<string> = []) {
  if(!isObject(object)){
    return object;
  }
  for(const key in object){
    if(excludeKeys.includes(key)){
      continue;
    }
    if(typeof object[key] === "object"){
      object[key] = cleanupSpacesInObject(object[key], excludeKeys);
    }
    if(typeof object[key] !== "string"){
      continue;
    }
    object[key] = object[key].trim().replace(/ {2}/gi, "");
  }
  return object;
}


export function getAllArraysInObject(object: any): Array<any> {

  const arrayArray: Array<any> = [];

  function checkObject(obj: any, path: string) {

    for(const key in obj){
      if(typeof obj[key] === "object"){
        let newPath = path;

        if(newPath !== ""){
          newPath = newPath + ".";
        }

        newPath = newPath + key;

        if(isObject(obj[key])){
          checkObject(obj[key], newPath);
        } else if(isArray(obj[key])){


          //-- Reinclude point for hidden ellements (.element)

          const newPathArray = newPath.split(".");

          for(let n = newPathArray.length - 1; n >= 0; n--){
            if(n + 1 <= newPathArray.length){
              if(newPathArray[n] === ""){
                newPathArray[n + 1] = "." + newPathArray[n + 1];
                newPathArray.splice(n, 1);
              }
            }
          }

          arrayArray.push({ "key": key, "path": newPathArray });

          for(let a = 0; a < obj[key].length; a++){
            checkObject(obj[key][a], newPath);
          }

        }
      }
    }

  }

  checkObject(object, "");
  return arrayArray;

}


export function splitPathStringToArray(path: string): Array<string> {

  const pathArray = path.split(".");


  for(let p = pathArray.length - 1; p >= 0; p--){
    if(p + 1 <= pathArray.length){
      if(pathArray[p] === ""){
        pathArray[p + 1] = "." + pathArray[p + 1];
        pathArray.splice(p, 1);
      }
    }
  }

  return pathArray;

}


export function getParentPath(path: string): string {

  const parentPathArray = splitPathStringToArray(path);

  if(parentPathArray.length > 1){
    parentPathArray.pop();
  }

  const parentPath = parentPathArray.join(".");

  return parentPath;

}


export function removeFileExtionsion(fileName: string): string {
  return fileName.replace(/\.[^/.]+$/, "");
}


export function getFileExtension(fileName: string): string | undefined {
  return fileName.split(".").pop();
}


export function zeroFill(value: string | number, length: number): string {

  const string = value + "";

  let pad = "";
  for(let n = 0; n < length; n++){
    pad += "0";
  }

  return pad.substr(string.length) + string;

}

/**
 * Returns true if version b is newer than version a
 * @param a   Version like "1.0.3"
 * @param b   Version like "2.0.3"
 */
export function compareVersions(a: string, b: string): boolean {

  if(a === undefined || b === undefined){
    console.warn("compareVersions warning: versions can not be undefined: a: ", a, ", b: ", b);
    return true; // Update in doubt
  }

  a = a.replace("v", "");
  b = b.replace("v", "");

  const localArray = a.split(".");
  const onlineArray = b.split(".");

  if(onlineArray.length !== localArray.length){
    console.warn("compareVersions warning: Version patterns do not match. Comparing " + a + " with " + b);
    return false;
  }

  for(let l = 0; l < localArray.length; l++){
    if(+onlineArray[l] > +localArray[l]){
      return true;
    } else if(+onlineArray[l] < +localArray[l]){
      return false;
    }
  }

  return false;

}


export function getUrlVars(): any {
  //@ts-ignore
  if(window){
    const vars = {};
    //@ts-ignore
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value): any {
      vars[key] = value;
    });
    return vars;
  }
}


export function mapNumber(number: number, inMin: number, inMax: number, outMin: number, outMax: number) {
  return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}


export function getObjectByPath(obj: any, path: string, indices: Array<number>): any {

  try {
    let pathArray: Array<string> = [];

    if(typeof path === "string"){

      pathArray = path.split(/\.\.|\./g);

      if(pathArray.length >= 1 && pathArray[0] === ""){
        pathArray.shift();
        pathArray[0] = "." + pathArray[0];
      }

    }

    const currentPath = pathArray.shift();

    if(currentPath === undefined){
      return obj;
    }

    if(path.length > 0){
      if(isArray(obj[currentPath])){
        let index = indices.pop();
        if(index === undefined){
          index = 0;
        }
        return getObjectByPath(obj[currentPath][index], pathArray.join("."), indices);
      } else {
        return getObjectByPath(obj[currentPath], pathArray.join("."), indices);
      }
    } else {
      if(isArray(obj[currentPath])){
        if(indices.length > 0){
          const index = indices.shift() as number;
          return obj[currentPath][index];
        } else {
          return obj[currentPath];
        }
      } else {
        return obj;
      }
    }
  } catch (err){
    console.error("getObjectByPath error: ", err);
  }

}


export function getRenderedObjectFitSize(containOrCover: "contain" | "cover", parentWidth: number, parentHeight: number, childWidth: number, childHeight: number): { width: number; height: number; x: number; y: number; } {
  const doRatio = childWidth / childHeight;
  const cRatio = parentWidth / parentHeight;
  let width = parentWidth;
  let height = parentHeight;

  if(containOrCover === "contain" ? (doRatio > cRatio) : (doRatio < cRatio)){
    height = width / doRatio;
  } else {
    width = height * doRatio;
  }

  return {
    width,
    height,
    x: (parentWidth - width) / 2,
    y: (parentHeight - height) / 2
  };
}


export function getArrayByPath(obj: any, path: string, indices: Array<number> = []): any {

  let pathArray: Array<string> = [];

  if(typeof path === "string"){

    pathArray = path.split(/\.\.|\./g);

    if(pathArray.length >= 1 && pathArray[0] === ""){
      pathArray.shift();
      pathArray[0] = "." + pathArray[0];
    }

  }

  if(obj === undefined){
    return undefined;
  }

  const currentPath = pathArray.shift();

  if(currentPath === undefined){
    return obj;
  }

  if(pathArray.length > 0){
    if(isArray(obj[currentPath])){
      let index = indices.pop();
      if(index === undefined){
        index = 0;
      }
      return getArrayByPath(obj[currentPath][index], pathArray.join("."), indices);
    } else {
      return getArrayByPath(obj[currentPath], pathArray.join("."), indices);
    }
  } else {
    if(isArray(obj[currentPath])){
      if(indices.length > 0){
        indices.pop();
        return obj[currentPath];
      } else {
        return obj[currentPath];
      }
    } else {
      return obj;
    }
  }

}


export function getArrayIndicesInObjectByKeyPair(obj: any, key: Array<string>, value: string, indices:Array<number> = []): Array<number> | undefined {

  if(typeof obj !== "object"){
    return undefined;
  }

  if(isArray(obj)){
    for(let a = 0; a < obj.length; a++){
      const result = getArrayIndicesInObjectByKeyPair(obj[a], key, value, indices);
      if(result === undefined){
        continue;
      }
      if(result !== undefined){
        indices.push(a);
        return result;
      }
    }
    return undefined;
  } else {
    for(let k = 0; k < key.length; k++){
      if(obj[key[k]] !== undefined){
        if(obj[key[k]] == value){
          return indices;
        }
      }
      for(const oKey in obj){
        if(obj[oKey][key[k]] !== undefined){
          if(obj[oKey][key[k]] == value){
            return indices;
          }
        }
        if(typeof obj[oKey] === "object"){
          const result = getArrayIndicesInObjectByKeyPair(obj[oKey], [key[k]], value, indices);
          if(result !== undefined){
            return result;
          }
        }
      }
    }
    return undefined;
  }

}


export function isArray(a: any): a is Array<any> {
  return (!!a) && (a.constructor === Array);
}


export function isObject(a: any): a is Object {
  return (!!a) && (a.constructor === Object);
}


export const deepClone = <T>(target: T, excludeKeys?: Array<string>): T => {

  if(target === null){
    return target;
  }
  if(target instanceof Date){
    return new Date(target.getTime()) as any;
  }
  if(target instanceof Array){
    const cp = [] as any[];
    (target as any[]).forEach(v => { cp.push(v); });
    return cp.map((n: any) => deepClone<any>(n, excludeKeys)) as any;
  }
  if(typeof target === "object" && target !== {}){
    const cp = { };
    Object.keys(target).forEach(k => {
      if(excludeKeys === undefined || (isArray(excludeKeys) && excludeKeys.includes(k) !== true) || !isArray(excludeKeys)){
        cp[k] = deepClone<any>(target[k], excludeKeys);
      }
    });
    return cp as T;
  }
  return target;

};


export const deepEqual = (a: any, b: any, excludeKeys?: Array<string>): boolean => {

  if(a === b){
    return true;
  }

  if(a == null || typeof a != "object" || b == null || typeof b != "object"){
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if(keysA.length != keysB.length){
    return false;
  }

  for(const key of keysA){

    if(excludeKeys !== undefined && excludeKeys.includes(key)){
      continue;
    }

    if(!keysB.includes(key) || !deepEqual(a[key], b[key])){
      return false;
    }
  }

  return true;

};


export function isParseableJSON(json: string): boolean {
  try {
    JSON.parse(json);
    return true;
  } catch (err){
    return false;
  }
}


export function isStringifiableJSON(json: string): boolean {
  return isStringifyableJSON(json);
}

export function isStringifyableJSON(json: string): boolean {
  try {
    JSON.stringify(json);
    return true;
  } catch (err){
    return false;
  }
}


export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export function deCapitalize(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function animate(object: { timing?: (timeFraction: number) => number; draw: (progress: number) => void; duration: number; }): Promise<boolean> {

  //-- From https://javascript.info/js-animation#structured-animation and https://medium.com/allenhwkim/animate-with-javascript-eef772f1f3f3

  const start = performance.now();

  return new Promise(resolve => {

    const animate = time => {

      if(object.timing === undefined){
        object.timing = timeFraction => {
          return timeFraction;
        };
      }

      let timeFraction = (time - start) / object.duration;
      if(timeFraction > 1){
        timeFraction = 1;
      }

      const progress = object.timing(timeFraction) * 100;
      object.draw(progress); // draw it

      if(timeFraction < 1){
        requestAnimationFrame(animate);
      } else {
        resolve(true);
      }
    };

    requestAnimationFrame(animate);

  });
}

export function secondsToDhms(seconds) {

  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor(seconds % (3600 * 24) / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

  return dDisplay + hDisplay + mDisplay + sDisplay;

}


export function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      //@ts-ignore
      Object.defineProperty(derivedCtor.prototype, name, Object.getOwnPropertyDescriptor(baseCtor.prototype, name));
    });
  });
}

