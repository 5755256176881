import getBrowserFingerprint from "get-browser-fingerprint";
import md5 from "md5";
import { types } from "shared/interfaces/types";
import * as functions from "../../../../shared/functions/functions";
import * as websiteTypes from "../../../../shared/interfaces/website";
import * as constants from "shared-constants";

interface FilterEntry {
  key: string;
  value: string | number;
  operator?: "=" | "!=" | "<" | ">";
}

class API_ {

  private _accessToken: string | undefined;
  private _level: number | undefined;

  constructor() {


    //-- Restore access token

    if(this.accessToken !== undefined){

      if(document.body !== null){
        document.body.classList.add("signedin");
      } else {
        window.addEventListener("DOMContentLoaded", () => {
          document.body.classList.add("signedin");
        });
      }

    }


    //-- Restore level

    if(this.level !== undefined){

      if(document.body !== null){
        document.body.setAttribute("data-level", this.level + "");
      } else {
        window.addEventListener("DOMContentLoaded", () => {
          document.body.setAttribute("data-level", this.level + "");
        });
      }

    }

  }


  public async logout() {

    const result = await this._request("POST", "signout", {
      "token": this.accessToken,
      "fingerprint": getBrowserFingerprint(true)
    });

    this.accessToken = undefined;
    this.level = undefined;

    if(document.body !== null){
      document.body.classList.remove("signedin");
      document.body.removeAttribute("data-level");
    } else {
      window.addEventListener("DOMContentLoaded", () => {
        document.body.classList.remove("signedin");
        document.body.removeAttribute("data-level");
      });
    }

  }


  public set accessToken(token: string | undefined) {

    this._accessToken = token;

    if(typeof token === "undefined"){
      localStorage.removeItem("_httpAccessToken");
    } else {
      localStorage.setItem("_httpAccessToken", token);
    }

  }


  public get accessToken(): string | undefined {

    if(this._accessToken !== undefined && this._accessToken !== null && this._accessToken !== ""){
      return this._accessToken;
    }

    const accessToken = localStorage.getItem("_httpAccessToken");

    if(accessToken !== undefined && accessToken !== null && accessToken !== ""){
      return accessToken;
    }

    return undefined;

  }


  public set level(level: number | undefined) {

    this._level = level;

    if(typeof level === "undefined"){
      localStorage.removeItem("_level");
      document.body.removeAttribute("data-level");
    } else {
      localStorage.setItem("_level", level + "");
      document.body.setAttribute("data-level", level + "");
    }

  }


  public get level(): number | undefined {

    if(this._level !== undefined && this._level !== null){
      return this._level;
    }

    const level = localStorage.getItem("_level");

    if(level !== undefined && level !== null && level !== ""){
      return +level;
    }

    return undefined;

  }


  protected _request(method: "GET" | "POST", endpoint: string): Promise<any>;
  protected _request(method: "GET" | "POST", endpoint: string, body: Object): Promise<any>;
  protected _request(method: "GET" | "POST", endpoint: string, file?: File): Promise<any>;
  protected _request(method: "GET" | "POST", endpoint: string, body: Object, file: File): Promise<any>;
  protected _request(method: "GET" | "POST", endpoint: string, bodyFileOrUndefined?: Object, fileOrUndefined?: File): Promise<any> {

    let body: types.Object = {};
    let file: File | undefined;

    if(bodyFileOrUndefined !== undefined){
      if(typeof bodyFileOrUndefined === "object"){
        body = bodyFileOrUndefined;
      }
      if(bodyFileOrUndefined instanceof File){
        file = bodyFileOrUndefined;
      }
      if(fileOrUndefined instanceof File){
        file = fileOrUndefined;
      }
    }

    return new Promise((resolve, reject) => {

      const req = new XMLHttpRequest();

      req.responseType = "text";
      req.open(method, "https://" + constants.URLS.HTTP_SERVER_ADDRESS + "/api/" + endpoint, true);

      const formData = new FormData();


      //-- Add file

      if(file !== undefined){
        formData.append("files[]", file, file.name);
      }

      formData.append("json", JSON.stringify(body));


      //-- Set Bearer header

      const fingerprint = getBrowserFingerprint(true);

      if(this.accessToken !== undefined){
        req.setRequestHeader("Authorization", "Bearer " + md5(this.accessToken + fingerprint));
      }

      req.onreadystatechange = () => {
        if(req.readyState === XMLHttpRequest.DONE && req.status === 200){
          if(functions.isParseableJSON(req.responseText)){
            const response = JSON.parse(req.responseText);
            if(response.status === "error" && response.statuscode === 401){
              this.logout();
            }
            resolve(response);
          }
        } else if(req.status === 401){
          this.logout();
        }
      };

      if(method === "POST"){
        req.send(formData);
      } else {
        req.send();
      }

    });

  }


  public async checkIfResetPasswordHashIsValid(hash: string) {
    return await this._request("POST", "validate-pw-hash", { hash });
  }


  public async resetPassword(password: string, hash: string) {
    return await this._request("POST", "reset-password", { password, hash });
  }


  public async addCartItem(cartItem: { module?: string; product?: string; quantity: number; }) {
    return await this._request("POST", "store/cart/add", cartItem);
  }


  public async removeCartItem(cartItem: { module?: string | undefined; product?: string | undefined; }) {
    return await this._request("POST", "store/cart/delete", cartItem);
  }


  public async setCartItem(cartItem: { module?: string; product?: string; quantity?: number; reference?: string; }) {
    return await this._request("POST", "store/cart/set", cartItem);
  }


  public async getCart() {
    const response = await this._request("POST", "store/cart/get", {});

    if(response.statuscode === 401){
      this.logout();
    }

    return response;
  }


  public async checkout(cartItem: Array<websiteTypes.CartItem>) {
    return await this._request("POST", "store/cart/checkout", cartItem);
  }


  public async getProducts(index?: number);
  public async getProducts(index?: number, showcase?: boolean);
  public async getProducts(search?: string);
  public async getProducts(search?: string, showcase?: boolean);
  public async getProducts(index: number, search: string);
  public async getProducts(index: number, search: string, showcase: boolean);
  public async getProducts(indexOrSearch?: string | number, searchOrShowcaseOrUndefined?: string | boolean | undefined, showcaseOrUndefined?: boolean | undefined) {

    let from: number | undefined = undefined;
    let search: string | undefined = undefined;
    let showcase: boolean | undefined = undefined;

    if(typeof indexOrSearch === "number"){
      from = indexOrSearch;
      if(typeof searchOrShowcaseOrUndefined === "string"){
        search = searchOrShowcaseOrUndefined;
      } else if(typeof searchOrShowcaseOrUndefined === "boolean"){
        showcase = searchOrShowcaseOrUndefined;
      }
    } else {
      search = indexOrSearch;
      if(typeof searchOrShowcaseOrUndefined === "boolean"){
        showcase = searchOrShowcaseOrUndefined;
      }
    }

    if(typeof showcaseOrUndefined === "boolean"){
      showcase = showcaseOrUndefined;
    }

    if(search === ""){
      search = undefined;
    }

    return await this._request("POST", "products/get", { from, to: 9999, search, showcase });

  }


  public async getProductByIdentifier(identifier: string) {
    return await this._request("POST", "products/get", { identifier });
  }


  public async getLatestModules(index?: number);
  public async getLatestModules(index?: number, showcase?: boolean);
  public async getLatestModules(search?: string);
  public async getLatestModules(search?: string, showcase?: boolean);
  public async getLatestModules(index: number, search: string);
  public async getLatestModules(index: number, search: string, showcase: boolean);
  public async getLatestModules(indexOrSearch?: string | number, searchOrShowcaseOrUndefined?: string | boolean | undefined, showcaseOrUndefined?: boolean | undefined) {

    let from: number | undefined = undefined;
    let search: string | undefined = undefined;
    let showcase: boolean | undefined = undefined;

    if(typeof indexOrSearch === "number"){
      from = indexOrSearch;
      if(typeof searchOrShowcaseOrUndefined === "string"){
        search = searchOrShowcaseOrUndefined;
      } else if(typeof searchOrShowcaseOrUndefined === "boolean"){
        showcase = searchOrShowcaseOrUndefined;
      }
    } else {
      search = indexOrSearch;
      if(typeof searchOrShowcaseOrUndefined === "boolean"){
        showcase = searchOrShowcaseOrUndefined;
      }
    }

    if(typeof showcaseOrUndefined === "boolean"){
      showcase = showcaseOrUndefined;
    }

    if(search === ""){
      search = undefined;
    }

    return await this._request("POST", "modules/get/latest", { from, search, showcase });

  }


  public async getModules(index?: number);
  public async getModules(index?: number, showcase?: boolean);
  public async getModules(search?: string);
  public async getModules(search?: string, showcase?: boolean);
  public async getModules(index: number, search?: string | undefined);
  public async getModules(index: number, search: string, showcase: boolean);
  public async getModules(indexOrSearch?: string | number, searchOrShowcaseOrUndefined?: string | boolean | undefined, showcaseOrUndefined?: boolean | undefined) {

    let from: number | undefined = undefined;
    let search: string | undefined = undefined;
    let showcase: boolean | undefined = undefined;

    if(typeof indexOrSearch === "number"){
      from = indexOrSearch;
      if(typeof searchOrShowcaseOrUndefined === "string"){
        search = searchOrShowcaseOrUndefined;
      } else if(typeof searchOrShowcaseOrUndefined === "boolean"){
        showcase = searchOrShowcaseOrUndefined;
      }
    } else {
      search = indexOrSearch;
      if(typeof searchOrShowcaseOrUndefined === "boolean"){
        showcase = searchOrShowcaseOrUndefined;
      }
    }

    if(typeof showcaseOrUndefined === "boolean"){
      showcase = showcaseOrUndefined;
    }

    if(search === ""){
      search = undefined;
    }

    return await this._request("POST", "modules/get", { from, search, showcase });

  }


  // public async getModules(filter: { "categories": "all" | Array<string>, "from": number, "to": number }){
  //   return await this._request("POST", "store/modules", filter);
  // }


  public async getModuleChangelog(moduleIdentifier: string) {
    return await this._request("POST", "changelog/module", { module: moduleIdentifier });
  }


  public async getModuleByName(name: string) {
    return await this._request("POST", "modules/get", { name });
  }


  public async getModuleByTypeAndName(type: string, name: string) {
    return await this._request("POST", "modules/get", { type, name });
  }


  public async getModuleCount() {
    return await this._request("POST", "modules/count", { });
  }


  public async getModuleByIdentifier(identifier: string) {
    return await this._request("POST", "modules/get", { identifier });
  }


  public async getModuleById(id: number) {
    return await this._request("POST", "modules/get", { id });
  }


  public async getBillingData() {
    return await this._request("POST", "account/get", {});
  }


  public async changeAddress(address: {
    firstname: string;
    lastname: string;
    company: string;
    phone: string;
    street: string;
    housenumber: string;
    zip: string;
    city: string;
  }) {
    return await this._request("POST", "account/set", address);
  }


  public async changePassword(newPassword: string, oldPassword: string) {
    return await this._request("POST", "account/set/password", { newPassword, oldPassword });
  }


  public async changeProfilePicture(profileObject: { profilePicture?: string; logo?: string; }) {
    return await this._request("POST", "account/set/profile", profileObject);
  }


  public async getInvoiceById(id: number) {
    return await this._request("POST", "invoices/get", { id });
  }


  public async getInvoices(index?: number);
  public async getInvoices(search?: string);
  public async getInvoices(index: number, search: string);
  public async getInvoices(indexOrSearch?: string | number, searchOrUndefiend?: string | undefined) {

    let from: number | undefined = undefined;
    let search: string | undefined = undefined;

    if(typeof indexOrSearch === "number"){
      from = indexOrSearch;
      if(typeof searchOrUndefiend == "string"){
        search = searchOrUndefiend;
      }
    } else {
      search = indexOrSearch;
    }

    if(search === ""){
      search = undefined;
    }

    return await this._request("POST", "invoices/get", { from, search });

  }


  public async getRetailers(index?: number);
  public async getRetailers(search?: string);
  public async getRetailers(index: number, search: string);
  public async getRetailers(indexOrSearch?: string | number, searchOrUndefiend?: string | undefined) {

    let from: number | undefined = undefined;
    let search: string | undefined = undefined;

    if(typeof indexOrSearch === "number"){
      from = indexOrSearch;
      if(typeof searchOrUndefiend === "string"){
        search = searchOrUndefiend;
      }
    } else {
      search = indexOrSearch;
    }

    if(search === ""){
      search = undefined;
    }

    return await this._request("POST", "retailers/get", { from, search });

  }


  public async getOrderById(id: number) {
    return await this._request("POST", "accounting/orders/get", { id });
  }


  public async getReleaseById(id: number) {
    return await this._request("POST", "releases/get", { id });
  }


  public async getReleases(index?: number);
  public async getReleases(search?: string);
  public async getReleases(index: number, search?: string | undefined);
  public async getReleases(indexOrSearch?: string | number | undefined, searchOrUndefiend?: string | undefined) {

    let from: number | undefined = undefined;
    let search: string | undefined = undefined;

    if(typeof indexOrSearch === "number"){
      from = indexOrSearch;
      if(typeof searchOrUndefiend == "string"){
        search = searchOrUndefiend;
      }
    } else {
      search = indexOrSearch;
    }

    if(search === ""){
      search = undefined;
    }

    return await this._request("POST", "releases/get", { from, search });

  }


  public async createRelease(releaseObject: {
      "app"?: string;
      "creator"?: string;
      "automa"?: string;
      "changelog": string;
      "releaseDate": Date;
    }) {
    return await this._request("POST", "releases/create", releaseObject);
  }


  public async createProduct(productObject: {
      "identifier": string;
      "name": string;
      "stock": number;
      "deliveryDurationInDays": number;
      "url": string;
      "shortDescription": string;
      "showcase": boolean;
      "type": number;
      "tags": string;
      "price": string;
      "image": string;
    }) {
    return await this._request("POST", "products/create", productObject);
  }


  public async createModule(moduleObject: {
      "name": string;
      "version": string;
      "description": string;
      "changelog": string;
      "shortDescription": string;
      "documentation": string;
      "showcase": boolean;
      "tags": string;
      "price": string;
      "module": any;
      "images": string;
    }) {
    return await this._request("POST", "modules/create", moduleObject);
  }


  public async uploadModule(releaseObject: {
      "app"?: string;
      "creator"?: string;
      "automa"?: string;
      "changelog": string;
      "releaseDate": Date;
    }) {
    return await this._request("POST", "releases/create", releaseObject);
  }


  public async login(email: string, password: string): Promise<{ login: boolean; firstLogin?: boolean; }> {

    const fingerprint = getBrowserFingerprint(true);

    const result = await this._request("POST", "signin", {
      email,
      password,
      fingerprint
    });

    if(result.status === "success"){

      if(result.token !== undefined){
        this.accessToken = result.token;
        document.body.classList.add("signedin");
      }

      if(result.level !== undefined){
        this.level = result.level;
      }

      return { login: true, firstLogin: result.firstLogin === 1 };
    } else {
      return { login: false };
    }

  }


  public async forgotPassword(email: string): Promise<boolean> {

    const result = await this._request("POST", "forgot-pw", {
      email
    });

    return result.status === "success";

  }


  public async register(registerObject: {
    "email": string;
    "firstname": string;
    "lastname": string;
    "company": string;
    "phone": string;
    "street": string;
    "zip": string;
    "city": string;
  }): Promise<boolean> {

    const result = await this._request("POST", "register", registerObject);

    if(result.status === "success"){
      return true;
    } else {
      return false;
    }

  }


  public hasAccessToken() {
    return this.accessToken !== undefined;
  }

}

class AccountingAPI_ extends API_ {

  constructor() {
    super();
  }


  public async getCustomers() {
    return await this._request("POST", "accounting/customers/get", {});
  }


  public async getBillingDataByCompanyID(companyID: string) {
    return await this._request("POST", "accounting/account/get", { companyID });
  }


  public async uploadCamt54(camt54content: File) {
    return await this._request("POST", "accounting/camt54/upload", camt54content);
  }


  public async getInvoices(filter?: Array<FilterEntry>);
  public async getInvoices(index?: number);
  public async getInvoices(index?: number, filter?: Array<FilterEntry>);
  public async getInvoices(search?: string);
  public async getInvoices(search?: string, filter?: Array<FilterEntry>);
  public async getInvoices(index?: number, search?: string);
  public async getInvoices(index?: number, search?: string, filter?: Array<FilterEntry>);
  public async getInvoices(indexOrSearchOrFilter?: string | number | Array<FilterEntry>, searchOrFilterOrUndefined?: string | undefined | Array<FilterEntry>, filterOrUndefined?: Array<FilterEntry> | undefined) {

    let from: number | undefined = undefined;
    let search: string | undefined = undefined;
    let filter: Array<FilterEntry> | undefined = undefined;

    if(typeof indexOrSearchOrFilter === "number"){
      from = indexOrSearchOrFilter;
      if(typeof searchOrFilterOrUndefined === "string"){
        search = searchOrFilterOrUndefined;
      }
      if(typeof searchOrFilterOrUndefined === "object"){
        filter = searchOrFilterOrUndefined;
      }
    } else if(typeof indexOrSearchOrFilter === "string"){
      search = indexOrSearchOrFilter;
      if(typeof searchOrFilterOrUndefined === "object"){
        filter = searchOrFilterOrUndefined;
      }
    } else if(typeof indexOrSearchOrFilter === "object"){
      filter = indexOrSearchOrFilter;
    }

    if(typeof filterOrUndefined === "object"){
      filter = filterOrUndefined;
    }

    if(search === ""){
      search = undefined;
    }

    return await this._request("POST", "accounting/invoices/get", { from, search, filter });

  }


  public async saveTrackingNumber(trackingNumber: string, id: number) {
    return await this._request("POST", "accounting/invoices/set", { trackingNumber, id });
  }


  public async saveOrderStatus(orderStatus: number, id: number) {
    return await this._request("POST", "accounting/invoices/set", { orderStatus, id });
  }


  public async getInvoiceById(id: number) {
    return await this._request("POST", "accounting/invoices/get", { id });
  }


  public async saveInvoice(positions: Array<websiteTypes.CartItem>, customer: number, id?: number) {
    return await this._request("POST", "accounting/invoices/create", { positions, id, customer });
  }


  public async saveDiscount(discount: number, discountReason: string, id: number) {
    return await this._request("POST", "accounting/invoices/set", { discount, id, discountReason });
  }


  public async convertToInvoice(id: number) {
    return await this._request("POST", "accounting/invoices/convert", { id });
  }


  public async sendInvoiceMailAgain(id: number) {
    return await this._request("POST", "accounting/invoices/send", { id });
  }

}

const API = new API_();

export const AccountingAPI = new AccountingAPI_();

export default API;