import API from "../modules/api";
import VirtualInfiniteScrolling from "../modules/infinite-scrolling";
import Popup from "../../../shared/modules/popup";
import Sortable from "../../../shared/modules/sortable";
import Contextmenu from "../../../shared/modules/contextmenu";
import * as ContextmenuTypes from "../../../shared/modules/contextmenu/types";

import md5 from "md5";

import * as constants from "shared-constants";
import * as functions from "../../../shared/functions/functions";
import { types } from "../../../shared/interfaces/types";

import "../styles/modules.css";

const instances: Array<Modules> = [];
export default class Modules {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _virtualInfiniteScroller: VirtualInfiniteScrolling | undefined;

  constructor() {


    //-- Destroy old instances

    Modules.destroy();

    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);


    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("module-search")){
      const search = (target as HTMLInputElement).value;
      // const search = isNaN(+value) ? value : +value;
      const result = await API.getLatestModules(search, false);

      if(result.status === "success"){
        if(this._virtualInfiniteScroller !== undefined){
          this._virtualInfiniteScroller.setItems(result.modules);
        }
      }

    }

    if(target.classList.contains("import-module")){

      const inputTarget = target as HTMLInputElement;

      if(inputTarget.files === null){
        return;
      }

      if(inputTarget.files.length !== 1){
        return;
      }

      const reader = new FileReader();

      reader.onloadend = async ev => {
        try {

          const fileTarget = ev.target;

          if(fileTarget === null){
            return;
          }

          const fileContent = fileTarget.result;

          if(fileContent === null){
            return;
          }

          if(typeof fileContent !== "string"){
            return;
          }

          if(!functions.isParseableJSON(fileContent)){
            return;
          }

          const module: types.Module = JSON.parse(fileContent);

          if(module.type === undefined){
            return;
          }


          //-- Check if module exists

          let dbEntry;

          const result = await API.getModuleByIdentifier(module.identifier);

          if(result.status === "success" && result.modules.length >= 1){
            dbEntry = result.modules[0];
          }


          //-- New Module

          const modulePopup = new ModulePopup(module, dbEntry);

        } catch (err){
          console.error("Module import error: ", err);
        }

      };

      if(inputTarget.files[0].size > 2000000){ // 2MB
        return;
      }

      reader.readAsText(inputTarget.files[0]);

    }

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.closest(".new-module") !== null){
      const moduleInput = document.querySelector(".import-module") as HTMLInputElement;
      moduleInput.click();
    }

    if(target.closest(".module-open") !== null){

      const moduleElement = target.closest(".module-item");

      if(moduleElement !== null){
        const id = moduleElement.getAttribute("id");
        if(id !== null){
          // this._openModule(+id);
        }
      }

    }

  }


  public async renderModules() {

    // const level = document.body.dataset.level ?? 0;

    // if(level < 4){
    //   return;
    // }

    const searchInput = document.querySelector(".module-search") as HTMLInputElement;
    const search = searchInput !== null ? searchInput.value : undefined;
    const result = await API.getLatestModules(search, false);

    if(typeof result.modules !== "object"){
      return;
    }

    const items = result.modules;

    if(this._virtualInfiniteScroller === undefined){
      this._initializeScrolling(items);
    } else {
      this._virtualInfiniteScroller.setItems(items);
    }

  }


  private _initializeScrolling(items) {

    const infiniteScrollContainer = document.querySelector("main.modules .infinite-scroll") as HTMLElement;
    this._virtualInfiniteScroller = new VirtualInfiniteScrolling(infiniteScrollContainer, items);

    this._virtualInfiniteScroller.renderItem = module => {

      const li = document.createElement("li");
      li.setAttribute("id", module.ID);
      li.classList.add("module-item");

      let heroImage = "";

      let images: Array<string> = [];

      if(functions.isParseableJSON(module.Images)){
        images = JSON.parse(module.Images);
      }

      if(images.length > 0){
        heroImage = `<img src="${constants.URLS.DOWNLOADS_MODULE_BASE_PATH + module.Identifier + "/images/" + images[0]}" />`;
      } else {
        heroImage = `<img src="${constants.URLS.CREATOR_DEVICE_TYPES_PATH + module.Type}.svg" />`;
      }

      li.innerHTML = `
        <div class="module-infos">
          <div class="module-hero">${heroImage}</div>
          <div class="module-type"><span class="label">Vorschau: </span><span class="chip ${module.Type}">${module.Type}</span></div>
          <div class="module-name"><span class="label">Name: </span><span>${module.Name}</span></div>
          <div class="module-version"><span class="label">Version: </span><span>${module.Version}</span></div>
          <div class="module-price"><span class="label">Preis: </span><span>${module.Price}</span></div>
          <div class="module-description"><span class="label">Beschreibung: </span>${module.ShortDescription}</div>
        </div>
      `;

      return li;

    };

    this._virtualInfiniteScroller.on("loadRequest", async index => {

      const searchInput = document.querySelector(".module-search") as HTMLInputElement;

      const result = await API.getLatestModules(index + 1, searchInput.value, false);

      if(result.status === "success"){
        if(result.modules.length > 0){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.addItems(result.modules);
          }
        }
      }

    });

    this._virtualInfiniteScroller.init();

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    if(this._virtualInfiniteScroller === undefined){
      return;
    }

    this._virtualInfiniteScroller.destroy();

  }

}


class ModulePopup {

  private _versionChangedEvent: (ev: Event) => void;
  private _contextMenuEvent: (ev: Event) => void;
  private _imageInputChangedEvent: (ev: Event) => void;
  private _saveEvent: (ev: Event) => void;

  private _modulePopup: Popup;
  private _module: types.Module;
  private _imageSortable: Sortable;
  private _dbEntry: any;

  constructor(module: types.Module, dbEntry: any) {

    this._module = module;
    this._dbEntry = dbEntry;

    let description = "";
    let shortDescription = "";
    let documentation = "";
    let version = "";
    let tags = "";
    let price = 0;
    let imageHTML = "";
    let showcaseChecked = "";
    const illegalModuleImportsOrExports: Array<string> = [];


    //-- Check for illegal imports or exports

    if(this._module.commands !== undefined){
      for(const command of this._module.commands){
        if(typeof command.command === "string" && (command.command.includes("import") || command.command.includes("export"))){
          illegalModuleImportsOrExports.push(command.name);
        }
      }
    }
    if(this._module.creator !== undefined){
      for(const creatorCommand of this._module.creator){
        if(typeof creatorCommand.command === "string" && (creatorCommand.command.includes("import") || creatorCommand.command.includes("export"))){
          illegalModuleImportsOrExports.push(creatorCommand.name);
        }
      }
    }
    if(this._module.gui !== undefined){
      if(typeof this._module.gui === "string" && (this._module.gui.includes("import") || this._module.gui.includes("export"))){
        illegalModuleImportsOrExports.push("App");
      }
    }
    if(this._module.main !== undefined){
      if(typeof this._module.main === "string" && (this._module.main.includes("import") || this._module.main.includes("export"))){
        illegalModuleImportsOrExports.push("Controller");
      }
    }
    if(this._module.shared !== undefined){
      if(typeof this._module.shared === "string" && (this._module.shared.includes("import") || this._module.shared.includes("export"))){
        illegalModuleImportsOrExports.push("Shared");
      }
    }

    const illegalImportsOrExportsWarningHidden = illegalModuleImportsOrExports.length <= 0 ? " hidden " : " ";


    //-- Override standard values by entry if provided

    if(dbEntry !== undefined){

      description = dbEntry.Description;
      shortDescription = dbEntry.ShortDescription;
      documentation = dbEntry.Documentation;
      version = dbEntry.Version;
      tags = dbEntry.Tags;
      price = dbEntry.Price;
      showcaseChecked = dbEntry.ShowCase == 1 ? " checked " : "";

      const entryImages = JSON.parse(dbEntry.Images);

      for(const image of entryImages){
        imageHTML += `
          <li class="sortable selectable" sortgroup="images" selectgroup="images">
            <img newname="${image}" src="${constants.URLS.DOWNLOADS_MODULE_BASE_PATH + module.identifier + "/images/" + image }" />
          </li>
        `;
      }

    }

    const popupHTML = `
      <div class="module-popup">
        <div class="flexbox">
          <div class="main width-80">
          
            <div class="warning ${illegalImportsOrExportsWarningHidden}">
              <h5>!!! Warnung !!!</h5>
              <p>Das Modul enthält möglicherweise Import- oder Export-Anweisungen. Diese werden nicht unterstützt und verursachen dass die VM nicht gestartet werden kann. Überlege dir gut ob du weisst was du tust!</p>
              <ul>
                ${illegalModuleImportsOrExports.map(name => `<li>${name}</li>`).join("")}
              </ul>
            </div>

            <div class="chcr-label">
              <label>Name</label>
            </div>
            <input type="text" maxlength="25" class="width-100 chcr-input name" autocomplete="off" placeholder="${module.name}" value="${module.name}" />
            </br>
            <div class="chcr-label textarea">
              <label>Changelog</label>
            </div>
            <textarea class="width-100 chcr-input changelog" autocomplete="off" placeholder=""></textarea>
            </br>
            <div class="chcr-label textarea">
              <label>Beschreibung</label>
            </div>
            <textarea class="width-100 chcr-input description" autocomplete="off" placeholder="">${description}</textarea>
            </br>
            <div class="chcr-label textarea">
              <label>Kurzbeschreibung</label>
            </div>
            <textarea class="width-100 chcr-input short-description" autocomplete="off" placeholder="">${shortDescription}</textarea>
            </br>
            <div class="chcr-label textarea">
              <label>Dokumentation</label>
            </div>
            <textarea class="width-100 chcr-input documentation" autocomplete="off" placeholder="">${documentation}</textarea>
            </br>
            <div class="chcr-label">
            <label>Tags</label>
            </div>
            <input type="text" maxlength="99" class="width-100 chcr-input tags" autocomplete="off" placeholder="" value="${tags}" />
            </br>
            <div class="chcr-label"><label>Preis CHF:</label></div><input type="number" max="5000" min="0" class="width-50 chcr-input price" autocomplete="off" placeholder="" value="${price}" /><div class="chcr-label"><label>Version</label></div>
            <input type="text" maxlength="10" class="width-50 chcr-input version" autocomplete="off" placeholder="1.0.0" value="${version}" />
            </br>
            <h3 class="chcr-title">
              Anzeigeeinstellungen
            </h3>
            </br>
            <label class="chcr-input-checkbox showcase-label">
              <input type="checkbox" class="showcase" autocomplete="off" ${showcaseChecked}><span class="label">In der Übersicht anzeigen</span>
            </label>
          </div>

          <div class="image-list">
            <ul class="sortable-container selectable-container selectable-multi" sortgroup="images" selectgroup="images">
              ${imageHTML}
            </ul>
            <progress class="width-100 hidden" max="100" value="0"></progress>
            </br>
            <label class="add-image">
              <input type="file" multiple accept="image/*">
              <span class="chip">+ &nbsp; Bild hinzufügen</span>
            </label>

          </div>

        </div>
      </div>

    `;

    this._modulePopup = new Popup(this._module.name, popupHTML, {
      width: 80,
      closeOnBackdropClick: false
    });

    // modulePopup.popup.querySelector(".add-module").addEventListener("mousedown", (ev) => {
    //   this._modulePopup.popup.querySelector(".chcr-import-modules").val("");
    // });


    //-- Initialize sortable

    this._imageSortable = new Sortable("images");


    //-- Add eventlisteners

    this._versionChangedEvent = this._versionChanged.bind(this);
    this._contextMenuEvent = this._contextMenu.bind(this);
    this._imageInputChangedEvent = this._imageInputChanged.bind(this);
    this._saveEvent = this._uploadModule.bind(this);

    this._modulePopup.popup.querySelector(".content input.version")?.addEventListener("input", this._versionChangedEvent);
    this._modulePopup.popup.querySelector(".content .image-list ul")?.addEventListener("contextmenu", this._contextMenuEvent);
    this._modulePopup.popup.querySelector(".content .image-list .add-image input")?.addEventListener("change", this._imageInputChangedEvent);
    this._modulePopup.on("save", this._saveEvent);


    //-- Check version

    this._versionChanged();

  }


  private _contextMenu(ev: Event) {

    ev.preventDefault();

    const imageContextmenu = new Contextmenu(ev.target as HTMLElement, `
      <ul>
        <li class="delete">Entfernen</li>
      </ul>
    `);


    //-- Initialize device contextmenu

    imageContextmenu.on("click", (ev: ContextmenuTypes.ClickEvent) => {

      if(ev.target.classList.contains("delete")){

        const selection = this._imageSortable.selectable.getSelection();

        for(let s = 0; s < selection.length; s++){
          selection[s].parentNode?.removeChild(selection[s]);
        }

      }

    });

  }


  private _imageInputChanged(ev: Event) {

    const inputTarget = ev.target as HTMLInputElement;

    if(inputTarget === null){
      return;
    }

    if(inputTarget.files !== null){

      for(let f = 0; f < inputTarget.files.length; f++){

        const reader = new FileReader();

        reader.onloadend = ev => {

          if(inputTarget.files === null){
            return;
          }

          if(ev.target === null){
            return;
          }

          const name = md5(ev.target.result);

          const fileTypeArr = inputTarget.files[f].name.split(".");
          const fileType = fileTypeArr[fileTypeArr.length - 1];

          const li = document.createElement("li");
          li.classList.add("sortable", "selectable");
          li.setAttribute("sortgroup", "images");
          li.setAttribute("selectgroup", "images");

          const img = document.createElement("img");
          img.setAttribute("newname", name + "." + fileType);
          img.setAttribute("originalname", inputTarget.files[f].name);
          img.setAttribute("src", ev.target.result as string);

          li.appendChild(img);
          this._modulePopup.popup.querySelector(".content .image-list ul")?.appendChild(li);

        };

        reader.readAsDataURL(inputTarget.files[f]);

      }
    }

  }


  private _versionChanged(ev?: Event) {

    if(this._dbEntry === undefined){
      return;
    }

    const versionInput = this._modulePopup.popup.querySelector(".content input.version") as HTMLInputElement;

    if(versionInput === null){
      return;
    }

    const version = versionInput.value;

    if(functions.compareVersions(this._dbEntry.Version, version)){
      versionInput.classList.remove("invalid");
    } else {
      versionInput.classList.add("invalid");
    }

  }


  private _uploadModule() {

    const name = (this._modulePopup.popup.querySelector(".content input.name") as HTMLInputElement).value;
    const version = (this._modulePopup.popup.querySelector(".content input.version") as HTMLInputElement).value;
    const description = (this._modulePopup.popup.querySelector(".content textarea.description") as HTMLInputElement).value;
    const changelog = (this._modulePopup.popup.querySelector(".content textarea.changelog") as HTMLInputElement).value;
    const shortDescription = (this._modulePopup.popup.querySelector(".content textarea.short-description") as HTMLInputElement).value;
    const documentation = (this._modulePopup.popup.querySelector(".content textarea.documentation") as HTMLInputElement).value;
    const showcase = (this._modulePopup.popup.querySelector(".content input.showcase") as HTMLInputElement).checked;
    const tags = (this._modulePopup.popup.querySelector(".content input.tags") as HTMLInputElement).value;
    const price = (this._modulePopup.popup.querySelector(".content input.price") as HTMLInputElement).value;

    const imageList: Array<string> = [];

    this._module.name = name;


    //-- Upload files --//

    const uploadFiles = () => {

      const images = (this._modulePopup.popup.querySelector(".content .image-list .add-image input[type='file']") as HTMLInputElement).files;

      if(images === null){
        return;
      }

      const formData = new FormData();

      for(let f = 0; f < images.length; f++){


        //-- Allow only images

        if(!images[f].type.match("image.*")){
          continue;
        }


        //-- Get new filename

        const name = this._modulePopup.popup.querySelector(".content .image-list ul li img[originalname='" + images[f].name + "']")?.getAttribute("newname");

        if(name !== null && name !== ""){
          formData.append("files[]", images[f], name);
        }

      }

      formData.append("device", deviceIdentifier!);
      formData.append("module", this._module.identifier);
      formData.append("images", JSON.stringify(this._module.files ?? []));
      formData.append("uploadModule", "true");

      const request = new XMLHttpRequest();

      request.open("POST", constants.URLS.UPLOAD_PATH, true);

      request.upload.onprogress = ev => {

        const progressElement = this._modulePopup.popup.querySelector("progress");

        if(progressElement !== null){
          progressElement.classList.remove("hidden");
          progressElement.value = (100 / ev.total * ev.loaded);
        }

      };

      request.onload = () => {
        if(request.status === 200){
          this._modulePopup.close();
          location.reload();
        }
      };

      request.send(formData);

    };


    //-- Read and delete deviceIdentifier

    const deviceIdentifier = this._module._deviceIdentifier;

    if(deviceIdentifier === undefined){
      return;
    }


    //-- Verify that version was increased

    if(this._dbEntry !== undefined){
      if(!functions.compareVersions(this._dbEntry.Version, version)){
        this._modulePopup.popup.querySelector(".content input.version")?.classList.add("invalid");
        return;
      }
    }


    //-- Generate array from dom

    const images = this._modulePopup.popup.querySelectorAll(".content .image-list ul li");

    for(let i = 0; i < images.length; i++){

      const image = images[i].querySelector("img");
      const newName = image?.getAttribute("newname");

      if(newName){
        imageList.push(newName);
      }

    }

    API.createModule({
      "name": this._module.name,
      "version": version,
      "description": description,
      "changelog": changelog,
      "shortDescription": shortDescription,
      "showcase": showcase,
      "documentation": documentation,
      "tags": tags,
      "price": price,
      "module": this._module,
      "images": JSON.stringify(imageList)
    }).then(response => {

      if(response.status === "success"){
        uploadFiles();
      }

    }).catch(err => {
      console.error("dev-create-release error: ", err);
    });

  }

}


