import "./popup.css";
import TinyEventEmitter from "../tiny-event-emitter";

interface PopupOptions {
  nextPopup?: boolean,
  showSaveButton?: boolean,
  saveButtonLabel?: string,
  cancelButtonLabel?: string,
  closeOnBackdropClick?: boolean,
  sidebar?: string,
  cssClass?: string,
  stack?: boolean,
  saveOnEnter?: boolean
  width?: number;
}

export default class Popup extends TinyEventEmitter {

  static currentIndex: number = 0;
  static _backdrop: Element;

  public popup: HTMLDivElement;
  public opened: boolean;

  public index: number;

  private _backdropCloseListener: any;
  private _title: string | undefined;
  private _html: string;
  private _options: PopupOptions | undefined;

  private _keyDownEvent: (ev: KeyboardEvent) => void;

  constructor(html: string, options?: PopupOptions);
  constructor(title: string, html: string, options?: PopupOptions);
  constructor(titleOrHtml: string, htmlOrOptions?: string | PopupOptions | undefined, options?: PopupOptions) {

    super();

    if(options !== undefined){
      this._title = titleOrHtml;
      this._html = htmlOrOptions as string;
      this._options = options;
    } else {
      if(htmlOrOptions !== undefined){
        if(typeof htmlOrOptions === "string"){
          this._title = titleOrHtml;
          this._html = htmlOrOptions;
        } else {
          this._html = titleOrHtml;
          this._options = htmlOrOptions;
        }
      } else {
        this._html = titleOrHtml;
      }
    }

    this.opened = true;

    this._backdropCloseListener = this.close.bind(this);


    //-- Check if popups exist

    const otherPopups = document.getElementsByClassName("chcr-popup").length;

    this.index = otherPopups + 1;


    //-- Create popup

    this.popup = document.createElement("div");
    this.popup.classList.add("chcr-popup");
    this.popup.classList.add("next");
    this.popup.setAttribute("index", this.index + "");


    //-- Set css class

    if(this._options?.cssClass !== undefined){
      this.popup.classList.add(this._options?.cssClass);
    }


    //-- Set width

    if(this._options !== undefined){
      if(this._options.width !== undefined){
        this.popup.style.width = this._options.width + "vw";
        this.popup.style.left = ((100 - this._options.width) / 2) + "vw";
      }
    }


    //-- Create backdrop if it doesn't exist yet

    let createBackdrop = true;

    if(otherPopups > 0){
      const backdrop = document.querySelector(".chcr-popup-backdrop");
      if(backdrop !== null){
        Popup._backdrop = backdrop;
        createBackdrop = false;
      }
    }

    if(createBackdrop === true){
      Popup.currentIndex = 1;
      Popup._backdrop = document.createElement("div");
      Popup._backdrop.setAttribute("class", "chcr-popup-backdrop");
      Popup._backdrop = document.body.appendChild(Popup._backdrop);
    }


    //-- Set animations

    if(otherPopups === 0){
      this.popup.classList.remove("next");
      this.popup.classList.add("current");
    }


    //-- Set buttons

    let buttons = "";

    if(otherPopups > 0){
      const label = (this._options !== undefined && this._options.cancelButtonLabel !== undefined ? this._options.cancelButtonLabel : "Back");
      buttons += `
        <button class="chcr-button gray back pointer-effects pointer-effect-parallax pointer-effect-glow pointer-effect-shadow">
          ${label}
        </button>
      `;
    } else {
      const label = (this._options !== undefined && this._options.cancelButtonLabel !== undefined ? this._options.cancelButtonLabel : "Cancel");
      buttons += `
        <button class="chcr-button gray close pointer-effects pointer-effect-parallax pointer-effect-glow pointer-effect-shadow">
          ${label}
        </button>
      `;
    }

    if(this._options !== undefined && this._options.nextPopup === true){
      const label = (this._options !== undefined && this._options.saveButtonLabel !== undefined ? this._options.saveButtonLabel : "Next");
      buttons += `
        <button class="chcr-button highlighted next pointer-effects pointer-effect-parallax pointer-effect-glow pointer-effect-shadow">
          ${label}
        </button>
      `;
    } else {
      if(this._options === undefined || this._options.showSaveButton !== false){
        const label = (this._options !== undefined && this._options.saveButtonLabel !== undefined ? this._options.saveButtonLabel : "Save");
        buttons += `
          <button class="chcr-button highlighted save pointer-effects pointer-effect-parallax pointer-effect-glow pointer-effect-shadow">
            ${label}
          </button>
        `;
      }
    }


    //-- Set content

    let popupHTML = "";

    if(this._options?.sidebar !== undefined){

      popupHTML += `
        <div class="splitview">
          <div class="sidebar">
            ${this._options.sidebar}
          </div>
          <div class="content-container">
            <div class="title">
              ${this._title}
            </div>
            <div class="content">
              ${this._html}
            </div>
            <div class="buttons">
              ${buttons}
            </div>
          </div>
        </div>
      `;

    } else {

      popupHTML += `
        <div class="content-container">
      `;

      if(this._title !== undefined){
        popupHTML += `
          <div class="title">
            ${this._title}
          </div>
        `;
      }

      popupHTML += `
        <div class="content">
          ${this._html}
        </div>
        <div class="buttons">
          ${buttons}
        </div>
      `;

      popupHTML += `
        </div>
      `;

    }

    this.popup.innerHTML = popupHTML;


    //-- Append popup

    this.popup = document.body.appendChild(this.popup);

    if(otherPopups > 0){
      this.next();
    }


    //-- Event listeners

    if(this._options !== undefined && this._options.nextPopup === true){

      const next = this.popup.querySelector(".chcr-button.next");
      if(next !== null){
        next.addEventListener("click", this._next.bind(this), false);
      }

    } else {

      const save = this.popup.querySelector(".chcr-button.save");
      if(save !== null){
        save.addEventListener("click", this._save.bind(this), false);
      }

    }

    if(otherPopups > 0){

      const back = this.popup.querySelector(".chcr-button.back");
      if(back !== null){
        back.addEventListener("click", this.back.bind(this), false);
      }

    } else {

      const close = this.popup.querySelector(".chcr-button.close");
      if(close !== null){
        close.addEventListener("click", this.close.bind(this), false);
      }

    }

    Popup._backdrop.removeEventListener("click", this._backdropCloseListener);

    if(this._options?.closeOnBackdropClick !== false){
      Popup._backdrop.addEventListener("click", this._backdropCloseListener);
    }

    this._keyDownEvent = this._keyDown.bind(this);

    document.addEventListener("keydown", this._keyDownEvent);

  }


  private _keyDown(ev: KeyboardEvent) {
    if(ev.key.toLowerCase() === "enter"){
      if(this._options?.saveOnEnter === true){
        this._save();
      }
    }
  }


  public shake() {

    this.popup.classList.add("shake");
    setTimeout(() => {
      this.popup.classList.remove("shake");
    }, 500);

  }


  public next() {

    Popup.currentIndex++;

    const nextToCurrentPopup = document.querySelector(".chcr-popup[index='" + (Popup.currentIndex) + "']");

    if(nextToCurrentPopup === null){
      return;
    }

    nextToCurrentPopup.classList.remove("next");
    nextToCurrentPopup.classList.add("current");

    if(this._options?.stack === true){
      nextToCurrentPopup.classList.add("fade-in");
    } else {
      nextToCurrentPopup.classList.add("from-next-to-current");
    }


    //-- Move previous popup

    const currentToPreviousPopup = document.querySelector(".chcr-popup[index='" + (Popup.currentIndex - 1) + "']");

    if(currentToPreviousPopup === null){
      return;
    }

    currentToPreviousPopup.classList.remove("current");
    currentToPreviousPopup.classList.remove("from-previous-to-current");
    currentToPreviousPopup.classList.remove("from-previous-to-current-scale");
    currentToPreviousPopup.classList.remove("from-next-to-current");
    currentToPreviousPopup.classList.remove("from-next-to-current-scale");
    currentToPreviousPopup.classList.add("previous");

    if(this._options?.stack === true){
      currentToPreviousPopup.classList.add("from-current-to-previous-scale");
    } else {
      currentToPreviousPopup.classList.add("from-current-to-previous");
    }

  }


  public back() {

    if(this.index !== Popup.currentIndex){
      return;
    }

    Popup.currentIndex--;

    const currentToNextPopup = document.querySelector(".chcr-popup[index='" + (Popup.currentIndex + 1) + "']");

    if(currentToNextPopup === null){
      return;
    }

    currentToNextPopup.classList.remove("current");
    currentToNextPopup.classList.remove("from-next-to-current");

    if(this._options?.stack === true){
      currentToNextPopup.classList.add("fade-out");
    } else {
      currentToNextPopup.classList.add("from-current-to-next");
    }

    currentToNextPopup.classList.add("next");


    //-- Remove event listener, because they do not get properly removed by garbage collection

    Popup._backdrop.removeEventListener("click", this._backdropCloseListener);

    this.emit("back", null);

    setTimeout(() => {
      this._remove(currentToNextPopup as Element);
    }, 300);


    //-- Move previous popup

    const previousToCurrentPopup = document.querySelector(".chcr-popup[index='" + Popup.currentIndex + "']");

    if(previousToCurrentPopup === null){
      return;
    }

    previousToCurrentPopup.classList.remove("previous");
    previousToCurrentPopup.classList.remove("from-current-to-previous");
    previousToCurrentPopup.classList.remove("from-current-to-previous-scale");
    previousToCurrentPopup.classList.add("current");

    if(this._options?.stack === true){
      previousToCurrentPopup.classList.add("from-previous-to-current-scale");
    } else {
      previousToCurrentPopup.classList.add("from-previous-to-current");
    }

  }


  private _save() {

    if(this.index !== Popup.currentIndex){
      return;
    }

    this.emit("save", null);

  }


  private _next() {

    if(this.index !== Popup.currentIndex){
      return;
    }

    this.emit("next", null);

  }


  private _remove(popup: Element) {

    const parent = popup.parentNode;

    if(parent){
      parent.removeChild(popup);
    }

  }


  public close() {

    if(this.index !== Popup.currentIndex){
      return;
    }

    const popups = document.querySelectorAll(".chcr-popup");

    for(let p = 0; p < popups.length; p++){

      const index = +popups[p].getAttribute("index")!;

      if(!index){
        return;
      }

      if(index === this.index){

        this.popup.classList.add("close");
        Popup._backdrop.classList.add("close");

        this.opened = false;

        this.emit("close", null);

        setTimeout(() => {
          this._remove(this.popup);
          this.destroy();
          const parent = Popup._backdrop.parentNode;
          if(parent !== null){
            parent.removeChild(Popup._backdrop);
          }
          this.emit("closed");
        }, 300);

      } else {
        this._remove(popups[p]);
      }

    }
  }


  public destroy() {
    document.removeEventListener("keydown", this._keyDownEvent);
  }

}