import Popover from "../../../shared/modules/popover";
import { AccountingAPI } from "../modules/api";
import controlHomeQRBill from "../../../qr-bill/index";
import { BlobStream } from "../../../dynamic-imports/swissqrbill";
import VirtualInfiniteScrolling from "../modules/infinite-scrolling";

import "../styles/manage-orders.css";

const instances: Array<Orders> = [];

export default class Orders {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _virtualInfiniteScroller: VirtualInfiniteScrolling | undefined;

  constructor() {


    //-- Destroy old instances

    Orders.destroy();

    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("order-search")){

      const searchInput = document.querySelector(".order-search") as HTMLInputElement;
      const search = searchInput !== null ? searchInput.value : undefined;
      const result = await AccountingAPI.getInvoices(search, [{ key: "OrderStatus", value: 2, operator: "!=" }]);

      if(result.status === "success"){
        if(this._virtualInfiniteScroller !== undefined){
          this._virtualInfiniteScroller.setItems(result.invoices);
        }
      }

    }
    if(target.classList.contains("orderstatus-select")){

      const orderElement = target.closest(".order-item");

      if(orderElement !== null){

        const id = orderElement.getAttribute("id");
        const value = (target as HTMLSelectElement).value;

        if(id === null){
          return;
        }

        AccountingAPI.saveOrderStatus(+value, +id);

      }
    }

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.closest(".invoice-open") !== null){

      const orderElement = target.closest(".order-item");

      if(orderElement !== null){

        const id = orderElement.getAttribute("id");

        if(id === null){
          return;
        }

        this._openInvoice(+id);

      }

    }

    if(target.closest(".trackingnumber-edit") !== null){

      const orderElement = target.closest(".order-item");

      if(orderElement !== null){

        const id = orderElement.getAttribute("id");
        const trackingNumber = target.getAttribute("trackingnumber");

        if(id === null){
          return;
        }

        const popover = new Popover(target, `
          <input type="text" autofocus="" class="trackingnumber-input chcr-input inline text-align-center" value="${trackingNumber}" />
        `);

        popover.on("close", () => {

          const value = (popover.content.querySelector(".trackingnumber-input") as HTMLInputElement).value;
          AccountingAPI.saveTrackingNumber(value, +id);
          target.setAttribute("trackingnumber", value);

          const trackingNumberEditButton = orderElement.querySelector(".trackingnumber-edit");

          if(trackingNumberEditButton !== null){
            if(value === ""){
              trackingNumberEditButton.innerHTML = " + Hinzufügen";
            } else {
              trackingNumberEditButton.innerHTML = value;
            }
          }

        });

      }

    }

  }


  public async renderOrders() {

    const searchInput = document.querySelector(".order-search") as HTMLInputElement;
    const search = searchInput !== null ? searchInput.value : undefined;
    const result = await AccountingAPI.getInvoices(search, [{ key: "OrderStatus", value: 2, operator: "!=" }]);

    if(typeof result.invoices !== "object"){
      return;
    }

    const items = result.invoices;

    if(this._virtualInfiniteScroller === undefined){
      this._initializeScrolling(items);
    } else {
      this._virtualInfiniteScroller.setItems(items);
    }

  }


  private async _openInvoice(id: number) {

    const invoiceResult = await AccountingAPI.getInvoiceById(id);

    if(invoiceResult.status !== "success"){
      return;
    }

    if(invoiceResult.invoices.length <= 0){
      return;
    }

    const invoice = invoiceResult.invoices[0];

    const accountResult = await AccountingAPI.getBillingDataByCompanyID(invoice.CompanyID);

    if(accountResult.status !== "success"){
      return;
    }

    if(accountResult.account === undefined){
      return;
    }

    const account = accountResult.account;

    const stream = new BlobStream();

    await controlHomeQRBill.generateSwissQRBill(invoice, account, stream);

    window.open(stream.toBlobURL("application/pdf"), "_blank");

  }


  private _initializeScrolling(items) {

    const infiniteScrollContainer = document.querySelector("main.orders .infinite-scroll") as HTMLElement;
    this._virtualInfiniteScroller = new VirtualInfiniteScrolling(infiniteScrollContainer, items);

    this._virtualInfiniteScroller.renderItem = order => {

      const li = document.createElement("li");
      li.setAttribute("id", order.ID);
      li.classList.add("order-item");
      li.setAttribute("comment", order.Comment ?? "");

      li.innerHTML = `
        <div class="order-infos">
          <div class="order-number"><span class="label">Rechnungsnummer: </span><span>${order.ID}</span></div>
          <div class="order-company"><span class="label">Firma: </span><span>${order.Company}</span></div>
          <div class="order-address"><span class="label">Adresse: </span><span>${order.Street} ${order.HouseNumber} ${order.Zip} ${order.City}</span></div>
          <div class="order-amount-open"><span class="label">Offener Betrag: </span><span>CHF: 0.-</span></div>
          <div class="order-tracking"><span class="label">Tracking: </span><span class="chip trackingnumber-edit" trackingnumber="${order.TrackingNumber}" >${order.TrackingNumber !== "" ? order.TrackingNumber : " + Hinzufügen"}</span><a target="_blank" class="external tracking-number" href="https://service.post.ch/ekp-web/ui/entry/search/${order.TrackingNumber.replace(/\./g, "")}"><i class="tracking-open f7-icons ">arrow_up_right_square</i></a></div>
          <div class="order-amount-state"><span class="label">Status: </span><span>
            <select class="orderstatus-select chcr-input no-padding">
              <option value="0" ${+order.OrderStatus === 0 ? " selected " : ""}>Offen</option>
              <option value="1" ${+order.OrderStatus === 1 ? " selected " : ""}>Versandbereit</option>
              <option value="2" ${+order.OrderStatus === 2 ? " selected " : ""}>Abgeschlossen</option>
            </select>
          </div>
          <div class="order-invoice">
            <i class="invoice-open f7-icons">arrow_up_right_square</i>
          </div>
        </div>
      `;

      return li;

    };

    this._virtualInfiniteScroller.on("loadRequest", async index => {

      const searchInput = document.querySelector(".order-search") as HTMLInputElement;

      const result = await AccountingAPI.getInvoices(index + 1, searchInput.value, [{ key: "OrderStatus", value: 2, operator: "!=" }]);

      if(result.status === "success"){
        if(result.invoices.length > 0){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.addItems(result.invoices);
          }
        }
      }

    });

    this._virtualInfiniteScroller.init();

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    if(this._virtualInfiniteScroller === undefined){
      return;
    }

    this._virtualInfiniteScroller.destroy();

  }

}