
import "./style.css";

export default class Skeleton {

  private _container: HTMLElement | null;

  constructor(selector: string) {
    this._container = document.querySelector(selector);
    this.enable();
  }


  public enable() {

    if(this._container === null){
      return;
    }

    if(this._container.classList.contains("skeleton-active")){
      return;
    }

    this._container.classList.add("skeleton-active");

  }


  public disable() {

    if(this._container === null){
      return;
    }

    if(this._container.classList.contains("skeleton-active")){
      this._container.classList.remove("skeleton-active");
    }

  }

}