import API from "../modules/api";
import VirtualInfiniteScrolling from "../modules/infinite-scrolling";
import Popup from "../../../shared/modules/popup";

import * as constants from "shared-constants";
import * as functions from "../../../shared/functions/functions";

import "../styles/manage-products.css";

const instances: Array<ManageProducts> = [];
export default class ManageProducts {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _virtualInfiniteScroller: VirtualInfiniteScrolling | undefined;

  constructor() {


    //-- Destroy old instances

    ManageProducts.destroy();

    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("product-search")){
      const search = (target as HTMLInputElement).value;
      // const search = isNaN(+value) ? value : +value;
      const result = await API.getProducts(search, false);

      if(result.status === "success"){
        if(this._virtualInfiniteScroller !== undefined){
          this._virtualInfiniteScroller.setItems(result.products);
        }
      }

    }

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;
    const li = target.closest("li");

    if(target.closest(".new-product") !== null){
      new ProductPopup();
    }

    if(target.closest(".product-edit") !== null){

      const identifier = li?.getAttribute("identifier");

      if(identifier === null || identifier === undefined){
        return;
      }

      const result = await API.getProductByIdentifier(identifier);

      if(result.status !== "success" || result.products.length <= 0){
        return;
      }

      new ProductPopup(result.products[0]);

    }

  }


  public async renderProducts() {

    const searchInput = document.querySelector(".product-search") as HTMLInputElement;
    const search = searchInput !== null ? searchInput.value : undefined;
    const result = await API.getProducts(search, false);

    if(typeof result.products !== "object"){
      return;
    }

    const items = result.products;

    if(this._virtualInfiniteScroller === undefined){
      this._initializeScrolling(items);
    } else {
      this._virtualInfiniteScroller.setItems(items);
    }

  }


  private _initializeScrolling(items) {

    const infiniteScrollContainer = document.querySelector("main.products .infinite-scroll") as HTMLElement;
    this._virtualInfiniteScroller = new VirtualInfiniteScrolling(infiniteScrollContainer, items);

    this._virtualInfiniteScroller.renderItem = product => {

      const li = document.createElement("li");
      li.setAttribute("identifier", product.Identifier);
      li.classList.add("product-item");

      li.innerHTML = `
        <div class="product-infos">
          <div class="product-hero"><img src="${constants.URLS.DOWNLOADS_PRODUCTS_BASE_PATH + product.Identifier + "/images/" + product.Image}" /></div>
          <div class="product-name"><span class="label">Name: </span><span>${product.Name}</span></div>
          <div class="product-stock"><span class="label">Lager: </span><span>${product.Stock}</span></div>
          <div class="product-price"><span class="label">Preis: </span><span>${product.Price}</span></div>
          <div class="product-description"><span class="label">Beschreibung: </span>${product.ShortDescription}</div>
          <div class="product-edit"><span class="chip">Bearbeiten</span></div>
        </div>
      `;

      return li;

    };

    this._virtualInfiniteScroller.on("loadRequest", async index => {

      const searchInput = document.querySelector(".product-search") as HTMLInputElement;

      const result = await API.getProducts(index + 1, searchInput.value, false);

      if(result.status === "success"){
        if(result.products.length > 0){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.addItems(result.products);
          }
        }
      }

    });

    this._virtualInfiniteScroller.init();

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    if(this._virtualInfiniteScroller === undefined){
      return;
    }

    this._virtualInfiniteScroller.destroy();

  }

}


class ProductPopup {

  private _imageInputChangedEvent: (ev: Event) => void;
  private _saveEvent: (ev: Event) => void;

  private _productPopup: Popup;

  constructor(dbEntry?: any) {

    let name = "";
    let shortDescription = "";
    let url = "";
    let type = 2;
    let tags = "";
    let price = 0;
    let stock = 0;
    let deliveryDurationInDays = 0;
    let image = "";
    let showcaseChecked = "";
    let identifier = functions.generateIdentifier();


    //-- Override standard values by entry if provided

    if(dbEntry !== undefined){

      identifier = dbEntry.Identifier;
      name = dbEntry.Name;
      shortDescription = dbEntry.ShortDescription;
      url = dbEntry.URL;
      tags = dbEntry.Tags;
      price = dbEntry.Price;
      type = +dbEntry.Type;
      stock = dbEntry.Stock;
      image = dbEntry.Image;
      deliveryDurationInDays = dbEntry.DeliveryDurationInDays;
      showcaseChecked = dbEntry.ShowCase == 1 ? " checked " : "";

    }

    const popupHTML = `
      <div class="product-popup">
        <div class="flexbox">
          <div class="main">
            <div class="chcr-label">
              <label>Name</label>
            </div>
            <input type="text" maxlength="25" class="width-100 chcr-input name" autocomplete="off" placeholder="${name}" value="${name}" />
            </br>
            <div class="chcr-label">
              <label>URL</label>
            </div>
            <input type="text" maxlength="25" class="width-100 chcr-input url" autocomplete="off" placeholder="${url}" value="${url}" />
            </br>
            <div class="chcr-label textarea">
              <label>Kurzbeschreibung</label>
            </div>
            <textarea class="width-100 chcr-input short-description" autocomplete="off" placeholder="">${shortDescription}</textarea>
            </br>
            <div class="chcr-label">
              <label>Kategorie</label>
            </div>
            <select class="chcr-input type-selection width-100">
              <option value="1" ${type === 1 ? " selected " : " "}>Controller</option>
              <option value="2" ${type === 2 ? " selected " : " "}>Zubehör</option>
            </select>
            </br>
            <div class="chcr-label">
              <label>Lager</label>
            </div>
            <input type="number" class="width-100 chcr-input stock" autocomplete="off" placeholder="${stock}" value="${stock}" />
            </br>
            <div class="chcr-label">
              <label>Lieferzeit</label>
            </div>
            <input type="number" class="width-100 chcr-input deliveryDurationInDays" autocomplete="off" placeholder="${deliveryDurationInDays}" value="${deliveryDurationInDays}" />
            </br>
            <div class="chcr-label">
            <label>Tags</label>
            </div>
            <input type="text" maxlength="99" class="width-100 chcr-input tags" autocomplete="off" placeholder="" value="${tags}" />
            </br>
            <div class="chcr-label"><label>Preis CHF:</label></div><input type="number" max="5000" min="0" class="width-100 chcr-input price" autocomplete="off" placeholder="" value="${price}" />
            </br>
            <input type="hidden" maxlength="99" class="width-100 chcr-input identifier" autocomplete="off" placeholder="" value="${identifier}" />
            <h3 class="chcr-title">
              Anzeigeeinstellungen
            </h3>
            </br>
            <label class="chcr-input-checkbox showcase-label">
              <input type="checkbox" class="showcase" autocomplete="off" ${showcaseChecked}><span class="label">In der Übersicht anzeigen</span>
            </label>
          </div>

          <div class="image-list">

            <img newname="${image}" src="${constants.URLS.DOWNLOADS_PRODUCTS_BASE_PATH + identifier + "/images/" + image }" />
            <progress class="width-100 hidden" max="100" value="0"></progress>
            </br>
            <label class="add-image">
              <input type="file" accept="image/*">
              <span class="chip">Bild ändern</span>
            </label>

          </div>

        </div>
      </div>

    `;

    this._productPopup = new Popup(name !== "" ? name : "Produkt erstellen", popupHTML);


    //-- Add eventlisteners

    this._imageInputChangedEvent = this._imageInputChanged.bind(this);
    this._saveEvent = this._createProduct.bind(this);

    this._productPopup.popup.querySelector(".content .image-list .add-image input")?.addEventListener("change", this._imageInputChangedEvent);
    this._productPopup.on("save", this._saveEvent);

  }


  private _imageInputChanged(ev: Event) {

    const inputTarget = ev.target as HTMLInputElement;

    if(inputTarget === null){
      return;
    }

    if(inputTarget.files !== null){

      for(let f = 0; f < inputTarget.files.length; f++){

        const reader = new FileReader();

        reader.onloadend = ev => {

          if(inputTarget.files === null){
            return;
          }

          if(ev.target === null){
            return;
          }

          const fileTypeArr = inputTarget.files[f].name.split(".");
          const fileType = fileTypeArr[fileTypeArr.length - 1];

          const img = this._productPopup.popup.querySelector(".image-list img") as HTMLImageElement;

          img.setAttribute("newname", "hero" + "." + fileType);
          img.setAttribute("originalname", inputTarget.files[f].name);
          img.setAttribute("src", ev.target.result as string);

        };

        reader.readAsDataURL(inputTarget.files[f]);

      }
    }

  }


  private _createProduct() {

    const name = (this._productPopup.popup.querySelector(".content input.name") as HTMLInputElement).value;
    const stock = +(this._productPopup.popup.querySelector(".content input.stock") as HTMLInputElement).value;
    const deliveryDurationInDays = +(this._productPopup.popup.querySelector(".content input.deliveryDurationInDays") as HTMLInputElement).value;
    const url = (this._productPopup.popup.querySelector(".content input.url") as HTMLInputElement).value;
    const shortDescription = (this._productPopup.popup.querySelector(".content textarea.short-description") as HTMLInputElement).value;
    const showcase = (this._productPopup.popup.querySelector(".content input.showcase") as HTMLInputElement).checked;
    const tags = (this._productPopup.popup.querySelector(".content input.tags") as HTMLInputElement).value;
    const price = (this._productPopup.popup.querySelector(".content input.price") as HTMLInputElement).value;
    const type = +(this._productPopup.popup.querySelector(".content .type-selection") as HTMLSelectElement).value;
    const identifier = (this._productPopup.popup.querySelector(".content input.identifier") as HTMLInputElement).value;


    //-- Upload files --//

    const uploadFiles = () => {

      const images = (this._productPopup.popup.querySelector(".content .image-list .add-image input[type='file']") as HTMLInputElement).files;

      if(images === null || images.length <= 0){
        return;
      }

      const formData = new FormData();


      //-- Allow only images

      if(!images[0].type.match("image.*")){
        return;
      }


      //-- Get new filename

      const fileName = this._productPopup.popup.querySelector(".content .image-list img[originalname='" + images[0].name + "']")?.getAttribute("newname");

      if(fileName !== null && fileName !== ""){
        formData.append("files[]", images[0], fileName);
      }

      formData.append("product", identifier);
      formData.append("uploadProduct", "true");

      const request = new XMLHttpRequest();

      request.open("POST", constants.URLS.UPLOAD_PATH, true);

      request.upload.onprogress = ev => {

        const progressElement = this._productPopup.popup.querySelector("progress");

        if(progressElement !== null){
          progressElement.classList.remove("hidden");
          progressElement.value = (100 / ev.total * ev.loaded);
        }

      };

      request.onload = () => {
        if(request.status === 200){
          this._productPopup.close();
          location.reload();
        }
      };

      request.send(formData);

    };


    //-- Generate array from dom

    const img = this._productPopup.popup.querySelector(".content .image-list img")?.getAttribute("newname") as string;

    API.createProduct({
      "name": name,
      "stock": stock,
      "deliveryDurationInDays": deliveryDurationInDays,
      "url": url,
      "shortDescription": shortDescription,
      "showcase": showcase,
      "tags": tags,
      "type": type,
      "price": price,
      "identifier": identifier,
      "image": img
    }).then(response => {

      if(response.status === "success"){
        uploadFiles();
        this._productPopup.close();
      }

    }).catch(err => {
      console.error("dev-create-release error: ", err);
    });

  }

}
