export function readData(key: string): string | undefined {
  const item = localStorage.getItem(key);
  if(item !== null){
    return item;
  }

  return undefined;
}


export function writeData(key: string, value: string): void {
  localStorage.setItem(key, value);
}


export function queryNextParentNodeBySelectors(element: Element, selector: string): Element | undefined {
  const selectors = selector.split(",");

  const stopSelector = "body";

  if(element.parentElement === null){
    return undefined;
  }

  element = element.parentElement;
  while(element){
    for(const thisSelector of selectors){
      if(element.matches(thisSelector)){
        return element;
      } else if(stopSelector && element.matches(stopSelector)){
        break;
      }
    }

    if(element.parentElement === null){
      return undefined;
    }

    element = element.parentElement;
  }

  return undefined;
}


export function queryParentNodesBySelector(element: Element, selector: string): Array<Element> {
  const array: Array<Element> = [];
  const stopSelector = "body";
  element = element.parentElement as Element;
  while(element){
    if(element.matches(selector)){
      array.push(element);
    } else if(stopSelector && element.matches(stopSelector)){
      break;
    }
    element = element.parentElement as Element;
  }

  return array;
}


export function isDescendant(parent: Element, child: Element): boolean {

  let node = child.parentNode;
  while(node != null){
    if(node == parent){
      return true;
    }
    node = node.parentNode;
  }
  return false;

}


export function getCSSVariable(variable: string, element: Element = document.documentElement): string {
  variable = variable.startsWith("--") ? variable : "--" + variable;
  return window.getComputedStyle(element).getPropertyValue(variable).trim();
}


export function getScrollParent(element: HTMLElement): HTMLElement { //https://stackoverflow.com/a/49186677/10209765

  const regex = /(auto|scroll)/;

  const parents = (_node, ps) => {
    if(_node.parentNode === null){ return ps; }
    return parents(_node.parentNode, ps.concat([_node]));
  };

  const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop);
  const overflow = _node => style(_node, "overflow") + style(_node, "overflow-y") + style(_node, "overflow-x");
  const scroll = _node => regex.test(overflow(_node));

  const scrollParent = _node => {
    if(!(_node instanceof HTMLElement || _node instanceof SVGElement)){
      return;
    }

    const ps = parents(_node.parentNode, []);

    for(let i = 0; i < ps.length; i += 1){
      if(scroll(ps[i])){
        return ps[i];
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(element);

}