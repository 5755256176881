import "../styles/store.css";
import "../styles/cart.css";

import API from "../modules/api/index";
import * as functions from "../../../shared/functions/functions";
import VirtualInfiniteScrolling from "../modules/infinite-scrolling";
import Skeleton from "../modules/skeleton-elements";
import Details from "../../../shared/modules/details";
import ImageViewer from "../modules/image-viewer";
import Popup from "../../../shared/modules/popup";
import Router from "../modules/page-router/index";


// Import third party modules

import { marked } from "marked";
import * as websiteTypes from "../../../shared/interfaces/website";
import * as constants from "shared-constants";


//-- Render MwSt.

const renderMwSt: boolean = false;

const instances: Array<Store> = [];

export default class Store {

  private _clickEvent: (ev: Event) => void;
  private _inputChangedEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;

  private _cartArray: Array<websiteTypes.CartItem> = [];

  private _virtualInfiniteScroller: VirtualInfiniteScrolling | undefined;

  private _moduleCountCache: number | undefined;

  private static TAGS = ["Beleuchtung", "TV", "Audio", "Heizung"];

  public billingData: any;

  constructor() {


    //-- Destroy old instances

    Store.destroy();

    instances.push(this);


    //-- Make sure Details is in build

    Details.isOpen("");
    ImageViewer.register();


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputChangedEvent = this._inputChange.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("change", this._inputChangedEvent);
    document.addEventListener("input", this._inputInputEvent);

    this._getBillingData();

    if(document.readyState === "complete" || document.readyState === "interactive"){
      this.updateCart();
    } else {
      window.addEventListener("DOMContentLoaded", () => {
        this.updateCart();
      });
    }


    //-- Load tags

    const tagList = document.querySelector("main.store .store-search-container ul.tags");

    if(tagList !== null){
      for(const tag of Store.TAGS){
        const li = document.createElement("li");
        li.classList.add("chip", "tag");
        li.innerText = tag;
        tagList.appendChild(li);
      }
    }

  }


  public static getInstance() {
    for(const instance of instances){
      return instance;
    }
  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _getModuleCount() {

    if(this._moduleCountCache === undefined){
      const result = await API.getModuleCount();
      if(result.status === "success"){
        this._moduleCountCache = result.count;
      }
    }

    return this._moduleCountCache;

  }


  private async _getBillingData(): Promise<any> {

    if(this.billingData === undefined && API.hasAccessToken() === true){

      const result = await API.getBillingData();

      this.billingData = result.account;

      return this.billingData;

    } else {
      return Promise.resolve(this.billingData);
    }

  }


  public set cart(cartObject: any) {
    if(cartObject.status === "success"){
      this._cartArray = cartObject.cart;
      this._updateCartBadge();
      this._updateCartPage();
    }
  }


  public get cart() {
    return this._cartArray;
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("store-search")){
      this.renderModules();
    }

  }


  private async _inputChange(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("cart-reference-input")){

      const cartItem = target.closest(".cart-item") as HTMLInputElement;
      const module = cartItem.getAttribute("module") ?? undefined;
      const product = cartItem.getAttribute("product") ?? undefined;

      const value = (target as HTMLInputElement).value;

      if(module === null && product === null){
        return;
      }

      this.cart = await API.setCartItem({ module, product, "reference": value });

    }

    if(target.classList.contains("cart-quantity-input")){

      const cartItem = target.closest(".cart-item") as HTMLInputElement;
      const module = cartItem.getAttribute("module") ?? undefined;
      const product = cartItem.getAttribute("product") ?? undefined;

      const value = +(target as HTMLInputElement).value;

      if(module === undefined && product === undefined){
        return;
      }

      this.cart = await API.setCartItem({ module, product, "quantity": value });

    }

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;


    //-- Checkout

    if(target.classList.contains("checkout") || target.closest(".checkout")){

      const cartContainer = document.querySelector("main.cart");

      if(cartContainer === null){
        return;
      }

      const cartItems = cartContainer.querySelectorAll("li.cart-item");

      if(cartItems.length === 0){
        return;
      }

      for(let c = 0; c < cartItems.length; c++){
        const referenceInput = cartItems[c].querySelector(".cart-reference-input") as HTMLInputElement;
        referenceInput.checkValidity();
        if(referenceInput.validity.valid === false){
          referenceInput.focus();
          return;
        }
      }

      const response = await API.checkout(this.cart);

      if(response.cart !== undefined){
        this.cart = response;
      }

      if(response.status === "success"){

        const orderSuccessfulContainer = document.querySelector("main.cart .cart-order-successful");
        const noItemsContainer = document.querySelector("main.cart .cart-no-items");
        const hasItemsContainer = document.querySelector("main.cart .cart-has-items");

        if(orderSuccessfulContainer !== null && noItemsContainer !== null &&  hasItemsContainer !== null){

          orderSuccessfulContainer.classList.remove("hidden");
          noItemsContainer.classList.add("hidden");
          hasItemsContainer.classList.add("hidden");

          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto"
          });

        }

      }

    }


    //-- Add cart item

    if(target.classList.contains("add-to-cart") || target.closest(".add-to-cart")){

      if(API.accessToken === undefined){
        window.location.reload();
      }

      const module = target.closest(".add-to-cart")?.getAttribute("module");

      if(!module){
        return;
      }

      this.cart = await API.addCartItem({ module, quantity: 1 });

    }


    //-- Tags

    if(target.classList.contains("tag") || target.closest(".tag")){

      const tag = target.closest(".tag")?.innerHTML;

      if(!tag){
        return;
      }

      const searchInput = document.querySelector(".store-search") as HTMLInputElement;
      const previousValue = searchInput.value;
      let newValue = "";

      if(previousValue === ""){
        newValue = tag;
      } else {
        let tagFound = false;
        for(let t = 0; t < Store.TAGS.length; t++){
          if(previousValue.indexOf(Store.TAGS[t]) >= 0){
            newValue = previousValue.replace(Store.TAGS[t], tag);
            tagFound = true;
          }
        }
        if(tagFound === false){
          newValue = tag + " " + previousValue;
        }
      }

      newValue = newValue.trim().replace(/ {2}/gi, "");
      searchInput.value = newValue;

      this.renderModules();

    }


    //-- Remove cart item

    if(target.classList.contains("cart-item-remove")){

      const module = target.closest(".cart-item")?.getAttribute("module");
      const product = target.closest(".cart-item")?.getAttribute("product");

      if(module === null && product === null){
        return;
      }

      this.cart = await API.removeCartItem({ module: module ?? undefined, product: product ?? undefined });

    }


    //-- Change cart item quantity

    if(target.classList.contains("cart-quantity-plus") || target.classList.contains("cart-quantity-minus")){

      const quantityInput = target.parentElement?.querySelector(".cart-quantity-input") as HTMLInputElement;
      const cartItem = target.closest(".cart-item") as HTMLInputElement;
      const module = cartItem.getAttribute("module") ?? undefined;
      const product = cartItem.getAttribute("product") ?? undefined;

      if(cartItem === null){
        return;
      }

      if(quantityInput === null){
        return;
      }

      let value = +quantityInput.value;

      if(target.classList.contains("cart-quantity-plus")){
        if(value < 20){
          value ++;
          quantityInput.value = value + "";
        }
      } else if(target.classList.contains("cart-quantity-minus")){
        if(value >= 2){
          value --;
          quantityInput.value = value + "";
        }
      }

      if(module === undefined && product === undefined){
        return;
      }

      this.cart = await API.setCartItem({ module, product, "quantity": value });

    }

  }


  public async loadCartPage() {
    await this.updateCart();
    this._renderCart();
  }


  public async updateCart() {
    if(API.accessToken !== undefined){
      this.cart = await API.getCart();
    }
  }


  private _updateCartBadge() {
    const cartBadges = document.querySelectorAll(".cart-badge");
    if(cartBadges.length > 0){
      for(let c = 0; c < cartBadges.length; c++){
        if(this.cart.length > 0){
          cartBadges[c].setAttribute("data-badge", this.cart.length + "");
        } else {
          cartBadges[c].removeAttribute("data-badge");
        }
      }
    }
  }


  private async _renderCart() {

    const cartContainer = document.querySelector("main.cart");

    if(cartContainer === null){
      return;
    }

    const renderMwStClass = renderMwSt === true ? " " : " hidden ";

    const productContainer = cartContainer.querySelector(".cart-product-container");
    const moduleContainer = cartContainer.querySelector(".cart-module-container");

    if(moduleContainer === null || productContainer === null){
      return;
    }


    //-- Render products

    let cartProductHTML = "";

    for(let c = 0; c < this.cart.length; c++){
      if(this.cart[c].product !== undefined){
        cartProductHTML += `
          <li class="cart-item skeleton" product="${this.cart[c].product}">
            <div class="cart-item-hero">

            </div>
            <div class="cart-description">
              <a class="product-link"><h3 class="cart-item-name">Name</h3></a>
              <p class="cart-item-description">Description</h3>
            </div>
            <div class="cart-reference">
              <input class="cart-reference-input" type="text" placeholder="Referenz" required />
            </div>
            <div class="cart-quantity-and-price">
              <div class="cart-item-price"></div>
              <div class="cart-item-price-without-mwst ${renderMwStClass}"></div>
              <div class="cart-quantity-row">
                <input type="number" class="cart-quantity-input" min="1" max="20" value="${this.cart[c].quantity}" />
                <button class="cart-quantity-minus">-</button>
                <button class="cart-quantity-plus">+</button>
              </div>
              <div class="cart-item-remove">
                Entfernen
              </div>
            </div>
          </li>
        `;
      }
    }

    productContainer.innerHTML = cartProductHTML;


    //-- Render modules

    let cartModuleHTML = "";

    for(let c = 0; c < this.cart.length; c++){
      if(this.cart[c].module !== undefined){
        cartModuleHTML += `
          <li class="cart-item skeleton" module="${this.cart[c].module}">
            <div class="cart-item-hero">

            </div>
            <div class="cart-description">
              <a class="module-link"><h3 class="cart-item-name">Name</h3></a>
              <p class="cart-item-description">Description</h3>
            </div>
            <div class="cart-reference">
              <input class="cart-reference-input" type="text" placeholder="Referenz" required />
            </div>
            <div class="cart-quantity-and-price">
              <div class="cart-item-price"></div>
              <div class="cart-item-price-without-mwst ${renderMwStClass}"></div>
              <div class="cart-quantity-row">
                <input type="number" class="cart-quantity-input" min="1" max="20" value="${this.cart[c].quantity}" />
                <button class="cart-quantity-minus">-</button>
                <button class="cart-quantity-plus">+</button>
              </div>
              <div class="cart-item-remove">
                Entfernen
              </div>
            </div>
          </li>
        `;
      }
    }

    moduleContainer.innerHTML = cartModuleHTML;

    const skeleton = new Skeleton(".cart.skeleton-container");

    this._updateCartPage();

    const result = await this._getBillingData();

    const mwstContainer = document.querySelector("main.cart .cart-mwst");

    if(mwstContainer !== null && renderMwSt === false){
      mwstContainer.classList.add("hidden");
    }

    const deliveryAddressElement = document.querySelector("main.cart .cart-delivery-address");

    if(deliveryAddressElement !== null){
      deliveryAddressElement.innerHTML = `
        ${result.FirstName} ${result.Name}</br>
        ${result.Company}</br>
        ${result.Street} ${result.HouseNumber ?? ""}</br>
        ${result.Zip} ${result.City}
      `;
    }

    setTimeout(() => {
      skeleton.disable();
    }, 100);

  }


  private async _updateCartPage() {

    const noItemsElement = document.querySelector("main.cart .cart-no-items");
    const hasItemsElement = document.querySelector("main.cart .cart-has-items");

    if(noItemsElement !== null){
      if(this.cart.length > 0){
        noItemsElement.classList.add("hidden");
      } else {
        noItemsElement.classList.remove("hidden");
      }
    }

    if(hasItemsElement !== null){
      if(this.cart.length > 0){
        hasItemsElement.classList.remove("hidden");
      } else {
        hasItemsElement.classList.add("hidden");
      }
    }

    const cartContainer = document.querySelector("main.cart");

    if(cartContainer === null){
      return;
    }

    const productContainer = cartContainer.querySelector(".cart-product-container");
    const moduleContainer = cartContainer.querySelector(".cart-module-container");

    if(moduleContainer === null || productContainer === null){
      return;
    }


    //-- Remove no longer existing elements

    const cartModuleItemElements = moduleContainer.querySelectorAll("li[module]");
    const cartProductItemElements = productContainer.querySelectorAll("li[product]");

    cartModuleItemElementLoop: for(let e = cartModuleItemElements.length - 1; e >= 0; e--){
      for(let c = 0; c < this.cart.length; c++){
        if(this.cart[c].module !== undefined){
          if(this.cart[c].module === cartModuleItemElements[e].getAttribute("module")){
            continue cartModuleItemElementLoop;
          }
        }
      }
      cartModuleItemElements[e].parentElement?.removeChild(cartModuleItemElements[e]);
    }

    cartProductItemElementLoop: for(let e = cartProductItemElements.length - 1; e >= 0; e--){
      for(let c = 0; c < this.cart.length; c++){
        if(this.cart[c].product !== undefined){
          if(this.cart[c].product === cartProductItemElements[e].getAttribute("product")){
            continue cartProductItemElementLoop;
          }
        }
      }
      cartProductItemElements[e].parentElement?.removeChild(cartProductItemElements[e]);
    }

    let total = 0;


    //-- Update still existing elements

    for(let c = 0; c < this.cart.length; c++){

      let cartItem: Element | undefined | null;

      if(this.cart[c].module !== undefined){

        cartItem = moduleContainer.querySelector("li[module='" + this.cart[c].module + "']");

        if(cartItem){

          const linkElement = cartItem.querySelector(".module-link");
          if(linkElement !== null){
            linkElement.setAttribute("href", this._convertModuleTypeAndNameToStoreURL(this.cart[c].type, this.cart[c].name));
          }

          const heroElement = cartItem.querySelector(".cart-item-hero") as HTMLElement;
          if(heroElement !== null){
            const imgPath = constants.URLS.DOWNLOADS_MODULE_BASE_PATH + this.cart[c].module + "/images/";
            heroElement.style.setProperty("background-image", "url(" + imgPath + this.cart[c].hero + ")");
          }

        }

      } else if(this.cart[c].product !== undefined){

        cartItem = productContainer.querySelector("li[product='" + this.cart[c].product + "']");

        if(cartItem){

          const linkElement = cartItem.querySelector(".product-link");
          if(linkElement !== null){
            linkElement.setAttribute("href", this.cart[c].url);
          }

          const heroElement = cartItem.querySelector(".cart-item-hero") as HTMLElement;
          if(heroElement !== null){
            const imgPath = constants.URLS.DOWNLOADS_PRODUCTS_BASE_PATH + this.cart[c].product + "/images/";
            heroElement.style.setProperty("background-image", "url(" + imgPath + this.cart[c].hero + ")");
          }

        }

      }

      if(!cartItem){
        return;
      }

      const nameElement = cartItem.querySelector(".cart-item-name");
      if(nameElement !== null){
        nameElement.innerHTML = this.cart[c].name;
      }

      const descriptionElement = cartItem.querySelector(".cart-item-description");
      if(descriptionElement !== null){
        descriptionElement.innerHTML = this.cart[c].description;
      }

      const referenceInput = cartItem.querySelector(".cart-reference-input");
      if(referenceInput !== null){
        (referenceInput as HTMLInputElement).value = this.cart[c].reference ?? "";
      }

      const quantityInput = cartItem.querySelector(".cart-quantity-input");
      if(quantityInput !== null){
        (quantityInput as HTMLInputElement).value = this.cart[c].quantity;
      }

      const priceElement = cartItem.querySelector(".cart-item-price");
      if(priceElement !== null){
        priceElement.innerHTML = "CHF: " + await this._calculateAndFormatDistributorPrice(this.cart[c].quantity * this.cart[c].price);
      }

      const priceWithoutMwStElement = cartItem.querySelector(".cart-item-price-without-mwst");
      if(priceWithoutMwStElement !== null){
        const price = this.cart[c].quantity * this.cart[c].price;
        priceWithoutMwStElement.innerHTML = "Exkl. MwSt. CHF: " + await this._calculateAndFormatDistributorPrice(price - (price / 100 * constants.MWST));
      }

      total += this.cart[c].quantity * this.cart[c].price;

    }

    const sumElement = cartContainer.querySelector(".cart-sum");
    if(sumElement !== null){
      sumElement.innerHTML = "CHF: " + await this._calculateAndFormatDistributorPrice(total - (total / 100 * constants.MWST));
    }

    const mwstElement = cartContainer.querySelector(".cart-mwst");
    if(mwstElement !== null){
      mwstElement.innerHTML = constants.MWST + "%";
    }

    const mwstSumElement = cartContainer.querySelector(".cart-mwst-sum");
    if(mwstSumElement !== null){
      mwstSumElement.innerHTML = "CHF: " + await this._calculateAndFormatDistributorPrice(total / 100 * constants.MWST);
    }

    const totalElement = cartContainer.querySelector(".cart-total");
    if(totalElement !== null){
      totalElement.innerHTML = "CHF: " + await this._calculateAndFormatDistributorPrice(total);
    }

    const orderButton = cartContainer.querySelector(".cart-price-total");
    if(orderButton !== null){
      orderButton.innerHTML = "Für CHF " + await this._calculateAndFormatDistributorPrice(total);
    }

  }


  private async _calculateAndFormatDistributorPrice(price: number): Promise<string> {

    const result = await this._getBillingData();
    let margin = 0;
    if(result !== undefined){
      margin = result.Margin;
    }

    return functions.calculateAndFormatDistributorPrice(price, margin);

  }


  public async renderModuleByTypeAndName(type: string, name: string) {

    const result = await API.getModuleByTypeAndName(type, name);

    if(result.status === "success" && result.modules.length >= 1){
      setTimeout(() => {
        this._renderModule(result.modules[0]);
      }, 0);
    }

  }


  private async _renderModule(module: any) {

    const container = document.querySelector("main.store-module");

    if(container === null){
      return;
    }


    //-- Disable skeleton

    const skeleton = new Skeleton(".store-module.skeleton-container");
    skeleton.disable();

    const heroElement = container.querySelector(".hero") as HTMLElement;

    let images: Array<string> = [];

    if(functions.isParseableJSON(module.Images)){
      images = JSON.parse(module.Images);
    }

    if(heroElement !== null){

      let hero = "";

      if(images.length > 0){
        hero = constants.URLS.DOWNLOADS_MODULE_BASE_PATH + module.Identifier + "/images/" + images[0];
        heroElement.classList.remove("generic");
      } else {
        hero = constants.URLS.CREATOR_DEVICE_TYPES_PATH + module.Type + ".svg";
        heroElement.classList.add("generic");
      }

      if(images.length > 1){
        document.querySelector("main.store-module .screenshots")?.classList.remove("hidden");
      } else {
        document.querySelector("main.store-module .screenshots")?.classList.add("hidden");
      }

      heroElement.style.setProperty("background-image", "url(" + hero + ")");

    }

    const versionElement = container.querySelector(".version");

    if(versionElement !== null){
      versionElement.innerHTML = "Version: " + module.Version;
    }

    const protocolElement = container.querySelector(".protocol");

    if(protocolElement !== null){
      protocolElement.classList.add(module.Type);
      protocolElement.innerHTML = module.Type;
    }

    if(module.ProfilePicture !== undefined && module.ProfilePicture !== ""){

      const profilePictureContainerElement = container.querySelector(".profile-picture-container");
      const profilePictureElement = container.querySelector(".profile-picture") as HTMLImageElement;

      if(profilePictureElement !== null){

        profilePictureElement.onload = () => {
          profilePictureContainerElement?.classList.remove("hidden");
        };

        profilePictureElement.setAttribute("src", constants.URLS.DOWNLOADS_COMPANIES_BASE_PATH + module.DeveloperID + "/account/images/" + module.ProfilePicture);
      }

    }

    const developerNameElement = container.querySelector(".developer-name");

    if(developerNameElement !== null){
      developerNameElement.innerHTML = module.Company;
    }

    const titleElement = container.querySelector(".title");

    if(titleElement !== null){
      titleElement.innerHTML = module.Name;
    }

    const descriptionElement = container.querySelector(".description");

    if(descriptionElement !== null){
      descriptionElement.innerHTML = marked(module.Description);
    }

    const priceElement = container.querySelector(".price");

    if(priceElement !== null){
      priceElement.innerHTML = "CHF: " + await this._calculateAndFormatDistributorPrice(module.Price);
    }

    const priceUVPElement = container.querySelector(".price-uvp");

    if(priceUVPElement !== null){
      priceUVPElement.innerHTML = "UVP CHF: " + functions.formatPrice(module.Price);
    }

    const addToCartElement = container.querySelector(".add-to-cart") as HTMLElement;

    if(addToCartElement !== null){
      if(+module.Price === 0){
        addToCartElement.style.setProperty("display", "none");
      } else {
        addToCartElement.setAttribute("module", module.Identifier);
      }
    }

    const imagesElement = container.querySelector("ul.images");

    if(imagesElement !== null){

      if(images.length > 1){
        for(let i = 1; i < images.length; i++){

          const li = document.createElement("li");
          const img = document.createElement("img");

          img.classList.add("image-viewer");
          img.setAttribute("src", constants.URLS.DOWNLOADS_MODULE_BASE_PATH + module.Identifier + "/images/" +  images[i]);

          li.appendChild(img);
          imagesElement.appendChild(li);

        }
      }

    }

    const changelogAreaElement = container.querySelector(".changelog-area");

    if(changelogAreaElement !== null){

      const recentChangelogVersionElement = changelogAreaElement.querySelector(".changelog-recent .version");
      const recentChangelogChangelogElement = changelogAreaElement.querySelector(".changelog-recent .changelog");

      if(recentChangelogVersionElement !== null && recentChangelogChangelogElement !== null){
        recentChangelogVersionElement.innerHTML = module.Version;
        recentChangelogChangelogElement.innerHTML = marked(module.Changelog);
      }

      API.getModuleChangelog(module.Identifier).then(result => {

        if(result.status !== "success"){
          return;
        }

        const changelog = result.changelog.changelog;
        const changelogElement = changelogAreaElement.querySelector(".version-history .changelog");

        if(changelogElement !== null){
          changelogElement.innerHTML = marked(changelog);
        }

      });
    }


  }


  public async renderModules() {

    const searchInput = document.querySelector(".store-search") as HTMLInputElement;
    const search = searchInput !== null ? searchInput.value : undefined;
    const result = await API.getLatestModules(search);

    if(typeof result.modules !== "object"){
      return;
    }

    const items = result.modules;

    if(this._virtualInfiniteScroller === undefined){
      this._initializeScrolling(items);
    } else {
      this._virtualInfiniteScroller.setItems(items);
    }


    //-- Update counter

    const storeCounter = document.querySelector(".store-module-counter");

    if(storeCounter !== null){
      this._getModuleCount().then(count => {
        if(count === 1){
          storeCounter.innerHTML = `Das ist erst ${items.length} Modul von insgesamt ${count}`;
        } else {
          storeCounter.innerHTML = `Das sind erst ${items.length} Module von insgesamt ${count}`;
        }
      });
    }

  }


  private async _initializeScrolling(items: any) {

    const infiniteScrollContainer = document.querySelector("main.store .store-module-container ul.infinite-scroll") as HTMLElement;
    this._virtualInfiniteScroller = new VirtualInfiniteScrolling(infiniteScrollContainer, items);


    //-- Get margin

    const result = await this._getBillingData();
    let margin = 0;
    if(result !== undefined){
      margin = result.Margin;
    }

    this._virtualInfiniteScroller.renderItem = module => {

      const li = document.createElement("li");
      li.setAttribute("identifier", module.Identifier);
      li.classList.add("module");

      let image = "";
      const imgPath = constants.URLS.DOWNLOADS_MODULE_BASE_PATH + module.Identifier + "/images/";

      if(functions.isParseableJSON(module.Images)){
        const images = JSON.parse(module.Images);
        if(images.length > 0){
          image = `<img class="hero" src="${imgPath + images[0]}" />`;
        } else {
          image = `<img class="hero generic" src="${constants.URLS.CREATOR_DEVICE_TYPES_PATH + module.Type + ".svg"}" />`;
        }
      }

      li.innerHTML = `
        <div class="module-content">
          <a href="${this._convertModuleTypeAndNameToStoreURL(module.Type, module.Name)}">
            ${image}
            <div class="description-container">
              <div class="name">
                ${module.Name}
              </div>
              <div class="description">
                ${module.ShortDescription}
              </div>
            </div>
          </a>
          <ul class="labels">
            <li class="chip ${module.Type}">v${module.Version}</li>
              ${
  API.accessToken !== undefined && module.Price !== 0 ?
    `<li class="chip bold add-to-cart margin-left-auto" module="${module.Identifier}">${+module.Price === 0 ? "Kostenlos" : "<i class='f7-icons'>cart_fill_badge_plus</i> CHF: " + functions.calculateAndFormatDistributorPrice(+module.Price, margin)}</li>`
    :
    `<li class="chip bold margin-left-auto price">${+module.Price === 0 ? "Kostenlos" : "CHF: " + functions.calculateAndFormatDistributorPrice(+module.Price, margin)}</li>`
}
          </ul>
        </div>
      `;

      return li;

    };


    this._virtualInfiniteScroller.getColumnsCount = (container: HTMLElement): number  => {
      if(window.innerWidth >= 768){
        return Math.floor(container.getBoundingClientRect().width / 400);
      } else {
        return 1;
      }
    };


    this._virtualInfiniteScroller.on("loadRequest", async index => {

      const searchInput = document.querySelector(".store-search") as HTMLInputElement;

      const result = await API.getLatestModules(index + 1, searchInput.value);

      if(result.status === "success"){
        if(result.modules.length > 0){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.addItems(result.modules);
          }
        }
      }

    });

    this._virtualInfiniteScroller.init();

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("change", this._inputChangedEvent);
    document.removeEventListener("input", this._inputInputEvent);

    if(this._virtualInfiniteScroller !== undefined){
      this._virtualInfiniteScroller.destroy();
    }

  }


  public async login(email: string, password: string) {

    const loginResult = await API.login(email, password);

    if(loginResult.login === true){
      await this._getBillingData();
      await this.updateCart();
    }

    return loginResult;

  }


  public async forgotPassword(email: string): Promise<boolean> {
    return await API.forgotPassword(email);
  }


  private _convertModuleTypeAndNameToStoreURL(type: string, name: string): string {
    return constants.URLS.WEBSITE_STORE_MODULE_URL + type + "/" + encodeURIComponent(name.replace(/ /g, "-")).toLowerCase();
  }


  public async logout() {


    //-- Reset cached billing data

    this.billingData = undefined;

    await API.logout();

    window.location.href = "/home";

  }


  public showLoginScreen() {

    const loginScreenContent = `
      <div class="login-screen">
        <div class="logo">
          <img src="./assets/controlHome-logo-stacked.svg" />
        </div>
        <form>
          <div class="chcr-label"><label>Email</label></div><input type="email" class="chcr-input login-email-input width-100" autofocus autocomplete="email" placeholder="Email" /></br>
          <div class="chcr-label"><label>Passwort</label></div><input type="password" class="chcr-input login-password-input width-100" placeholder="Password" autocomplete="current-password" /></br>
        </form>
        <div class="login-forgot-password-status">
        </div>
        <span class="login-forgot-password">Passwort vergessen</span>
      </div>
    `;

    const loginScreen = new Popup(loginScreenContent, {
      cancelButtonLabel: "Abbrechen",
      saveButtonLabel: "Login",
      saveOnEnter: true
    });


    //-- Login

    loginScreen.on("save", async() => {

      const emailInput = loginScreen.popup.querySelector(".login-email-input") as HTMLInputElement;
      const passwordInput = loginScreen.popup.querySelector(".login-password-input") as HTMLInputElement;

      const email = emailInput.value;
      const password = passwordInput.value;


      //-- Validate email

      //@ts-ignore
      if(email === "" || !emailInput.checkValidity()){
        loginScreen.shake();
        return;
      }

      const { login, firstLogin } = await this.login(email, password);

      if(login === true){
        loginScreen.close();
        if(firstLogin === true){
          Router.navigate("/settings#password");
        }
      } else {
        loginScreen.shake();
      }

    });


    //-- Forgot Password

    const forgotPasswordLink = loginScreen.popup.querySelector(".login-forgot-password");

    if(forgotPasswordLink !== null){

      forgotPasswordLink.addEventListener("click", async ev => {

        const emailInput = loginScreen.popup.querySelector(".login-email-input") as HTMLInputElement;
        const email = emailInput.value;

        const forgotPasswortButton = loginScreen.popup.querySelector(".login-forgot-password") as HTMLInputElement;
        const forgotPasswordStatus = loginScreen.popup.querySelector(".login-forgot-password-status") as HTMLInputElement;

        if(await this.forgotPassword(email)){
          forgotPasswordStatus.innerHTML = "Es wurde eine Email mit Anweisungen an die angegebene Email Adresse geschickt.";
        } else {
          forgotPasswordStatus.innerHTML = "Es ist ein Fehler aufgetreten, bitte wenden Sie sich an controlHome.";
        }

        forgotPasswortButton.classList.add("hidden");

      });
    }

  }


}

