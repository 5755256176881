import VirtualScroller from "virtual-scroller";
import TinyEventEmitter from "../../../../shared/modules/tiny-event-emitter";


export default class VirtualInfiniteScrolling extends TinyEventEmitter {


  private _container: HTMLElement;
  private _virtualInfiniteScroller?: VirtualScroller<HTMLElement, Array<any>>;
  private _items: Array<any>;

  public renderItem?: (item: any) => Node;
  public getColumnsCount?: (container: HTMLElement) => number;


  constructor(container: HTMLElement, items: Array<any>) {

    super();

    //@ts-ignore
    // window.VirtualScrollerDebug = true;

    this._container = container;
    this._items = items;

  }


  public init() {

    this._virtualInfiniteScroller = new VirtualScroller(() => this._container, this._items, { onStateChange: this._onStateChange.bind(this), getColumnsCount: this._getColumnsCount.bind(this) });


    //-- Start VirtualScroller listening for scroll events.

    this._virtualInfiniteScroller.listen();

  }


  private _onStateChange(newState, prevState): void {

    // console.log("onStateChange", newState, prevState);

    // if(prevState !== undefined){
    //   if(newState.scrollY === prevState.scrollY){
    //     return;
    //   }
    // }


    //-- Set paddingTop and paddingBottom on the container element

    // this._container.style.paddingTop = Math.round(newState.beforeItemsHeight) + "px";
    // this._container.style.paddingBottom = Math.round(newState.afterItemsHeight) + "px";

    this._container.style.paddingTop = newState.beforeItemsHeight + "px";
    this._container.style.paddingBottom = newState.afterItemsHeight + "px";

    // Perform an intelligent "diff" re-render as the user scrolls the page.
    // This also requires that the list of `items` hasn't been changed.
    // On initial render, `prevState` is `undefined`.

    if(prevState !== undefined && this._items === prevState.items){


      //-- Remove no longer visible items

      for(let i = prevState.lastShownItemIndex; i >= prevState.firstShownItemIndex; i--){
        if(i >= newState.firstShownItemIndex && i <= newState.lastShownItemIndex){
          // The item is still visible.
        } else {
          // The item is no longer visible. Remove it.
          this._container.removeChild(this._container.childNodes[i - prevState.firstShownItemIndex]);
        }
      }


      //-- Add newly visible items

      for(let i = newState.firstShownItemIndex, prependBefore = this._container.firstChild; i <= newState.lastShownItemIndex; i++){
        if(i >= prevState.firstShownItemIndex && i <= prevState.lastShownItemIndex){
          // The item is already being rendered.
          // Next items will be appended rather than prepended.
          prependBefore = null;
        } else {

          const node = this._renderItem(this._items[i]);

          if(node !== undefined){
            if(prependBefore){
              this._container.insertBefore(node, prependBefore);
            } else {
              this._container.appendChild(node);
            }
          }

        }
      }

    } else {


      //-- Re-render the list from scratch.

      while(this._container.firstChild){
        this._container.removeChild(this._container.firstChild);
      }

      for(let i = newState.firstShownItemIndex; i <= newState.lastShownItemIndex; i++){

        const node = this._renderItem(this._items[i]);

        if(node !== undefined){
          this._container.appendChild(node);
        }

      }

    }

    if(prevState !== undefined){
      if(newState.lastShownItemIndex === this._items.length - 1 && prevState.lastShownItemIndex !== this._items.length - 1){
        this.emit("loadRequest", newState.lastShownItemIndex);
      }
    }

  }


  private _renderItem(item: any): Node | undefined {

    if(typeof this.renderItem === "function"){
      return this.renderItem(item);
    }

  }


  private _getColumnsCount(): number {

    if(typeof this.getColumnsCount === "function"){
      return this.getColumnsCount(this._container);
    }

    return 1;

  }


  public addItems(items: Array<any>) {
    if(this._virtualInfiniteScroller === undefined){
      return;
    }
    this._items = [...this._items, ...items];
    this._virtualInfiniteScroller.setItems(this._items);
  }


  public setItems(items: Array<any>) {
    if(this._virtualInfiniteScroller === undefined){
      return;
    }
    this._items = items;
    this._virtualInfiniteScroller.setItems(this._items);
  }


  public destroy() {
    if(this._virtualInfiniteScroller === undefined){
      return;
    }
    this._virtualInfiniteScroller.stop();
  }

}