import "../styles/home.css";

const instances: Array<Home> = [];

export default class Home {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _observer: IntersectionObserver | undefined;
  private _intersectionEvent: (entries: IntersectionObserverEntry[]) => void;
  private _resizeEvent: (ev: Event) => void;
  private _smartphoneScrollEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _windowHeight: number;

  private _smartphonePositionTop: number | undefined;
  private _smartphoneLastParralaxOffsetY: number = 0;
  private _smartphoneParallaxElement: HTMLElement;

  private _animationFrameRequested: boolean = false;

  constructor() {


    //-- Destroy old instances

    Home.destroy();
    instances.push(this);


    //-- Store window height to avoid repainting

    this._windowHeight = window.innerHeight;


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._smartphoneScrollEvent = this._smartphoneScroll.bind(this);
    this._intersectionEvent = this._intersection.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);
    this._resizeEvent = this.resize.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);
    window.addEventListener("resize", this._resizeEvent);
    window.addEventListener("orientationchange", this._resizeEvent);


    //-- Get elements

    this._smartphoneParallaxElement = document.querySelector("main.home .smartphone-parallax") as HTMLElement;


    //-- Set up intersection observer for non firefox browsers

    this._observer = new IntersectionObserver(this._intersectionEvent, { threshold: 0 });
    this._observer.observe(this._smartphoneParallaxElement);

  }


  public resize(ev) {
    this._windowHeight = window.innerHeight;
  }


  private _intersection(entries: IntersectionObserverEntry[]) {

    for(const entry of entries){
      if(entry.target.classList.contains("smartphone-parallax")){
        if(entry.isIntersecting){
          window.addEventListener("scroll", this._smartphoneScrollEvent, { passive: true });
        } else {
          window.removeEventListener("scroll", this._smartphoneScrollEvent);
        }
      }
    }

  }


  private async _smartphoneScroll(ev: Event) {

    if(this._smartphonePositionTop === undefined){
      this._smartphonePositionTop = this._smartphoneParallaxElement.getBoundingClientRect().top + window.scrollY;
      return;
    }

    if(await this._requestAnimationFrame() === false){
      return;
    }

    const percent = 100 / this._windowHeight * (window.scrollY - this._smartphonePositionTop);

    if(percent > 100 || percent < 0){
      return;
    }

    this._smartphoneParallaxElement.style.setProperty("transform", `translateY(${(percent) + "px"})`);

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _requestAnimationFrame(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      if(this._animationFrameRequested === true){
        resolve(false);
      } else {

        this._animationFrameRequested = true;

        requestAnimationFrame(() => {
          resolve(true);
          this._animationFrameRequested = false;
        });
      }

    });
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    if(this._observer !== undefined){
      this._observer.disconnect();
    }

  }

}