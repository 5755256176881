import API, { AccountingAPI } from "../modules/api";
import VirtualInfiniteScrolling from "../modules/infinite-scrolling";
import controlHomeQRBill from "../../../qr-bill/index";
import { BlobStream } from "../../../dynamic-imports/swissqrbill";
import Popover from "../../../shared/modules/popover";
import * as functions from "../../../shared/functions/functions";
import "../styles/invoices.css";

const instances: Array<Invoices> = [];

export default class Invoices {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _virtualInfiniteScroller: VirtualInfiniteScrolling | undefined;

  constructor() {


    //-- Destroy old instances

    Invoices.destroy();

    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("invoice-search")){

      const searchInput = document.querySelector(".invoice-search") as HTMLInputElement;
      const search = searchInput !== null ? searchInput.value : undefined;
      let result;

      if((document.querySelector("main.invoices .show-all") as HTMLInputElement).checked){
        result = await AccountingAPI.getInvoices(search);
      } else {
        result = await API.getInvoices(search);
      }

      if(result.status === "success"){
        if(this._virtualInfiniteScroller !== undefined){
          this._virtualInfiniteScroller.setItems(result.invoices);
        }
      }

    }

    if(target.classList.contains("show-all")){

      const searchInput = document.querySelector(".invoice-search") as HTMLInputElement;
      const search = searchInput !== null ? searchInput.value : undefined;

      if((target as HTMLInputElement).checked){

        const result = await AccountingAPI.getInvoices(search);

        if(result.status === "success"){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.setItems(result.invoices);
          }
        }

      } else {

        const result = await API.getInvoices(search);

        if(result.status === "success"){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.setItems(result.invoices);
          }
        }
      }

    }

    if(target.classList.contains("camt54-file-input")){

      const inputTarget = target as HTMLInputElement;

      if(inputTarget.files === null){
        return;
      }

      if(inputTarget.files.length !== 1){
        return;
      }


      //-- Upload camt54

      const zip = inputTarget.files[0];
      const uploadResponse = await AccountingAPI.uploadCamt54(zip);

      if(uploadResponse.status === "success"){
        window.location.reload();
      }

    }

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.closest(".invoice-open") !== null){

      const invoiceElement = target.closest(".invoice-item");

      if(invoiceElement !== null){
        const id = invoiceElement.getAttribute("id");
        if(id !== null){
          this._openInvoice(+id);
        }
      }

    }
    if(target.closest(".invoice-resend-mail") !== null){

      const invoiceElement = target.closest(".invoice-item");

      if(invoiceElement !== null){
        const id = invoiceElement.getAttribute("id");
        if(id !== null){
          if(confirm("Are you sure you want to send the invoice again to the customer?")){
            AccountingAPI.sendInvoiceMailAgain(+id);
          }
        }
      }

    }
    if(target.closest(".invoice-edit") !== null){

      const invoiceElement = target.closest(".invoice-item");

      if(invoiceElement !== null){
        const id = invoiceElement.getAttribute("id");
        if(id !== null){
          this._editInvoice(+id);
        }
      }

    }
    if(target.closest(".invoice-comment") !== null){

      const invoiceElement = target.closest(".invoice-item");

      if(invoiceElement !== null){
        const comment = invoiceElement.getAttribute("comment");
        if(comment !== null){
          new Popover(target, `
            <div class="padded-content">
              ${comment}
            </div>
          `);
        }
      }
    }
    if(target.closest(".invoice-discount") !== null){

      const invoiceElement = target.closest(".invoice-item");

      if(invoiceElement !== null){

        const discountReason = invoiceElement.getAttribute("discount-reason");
        const discount = invoiceElement.getAttribute("discount");

        if(discount !== null){
          new Popover(target, `
            <div class="padded-content">
              <p>Nachlass von CHF: <span class="text-color-red">- ${functions.formatPrice(+discount)}</span></p>
              <p>Grund: ${discountReason}</p>
            </div>
          `);
        }
      }

    }
    if(target.closest(".invoice-manage-discount") !== null){

      const invoiceElement = target.closest(".invoice-item");

      if(invoiceElement !== null){

        const discountReason = invoiceElement.getAttribute("discount-reason");
        const discount = invoiceElement.getAttribute("discount");

        if(discount !== null){
          const manageDiscountPopower = new Popover(target, `
            <div class="padded-content">
              <label>Nachlass: <input type="number" autofocus="${functions.formatPrice(+discount)}" placeholder="Nachlass" class="discount-input chcr-input inline width-100" value="${functions.formatPrice(+discount)}"></label></br>
              <label>Grund: <input type="text" autofocus="" placeholder="${discountReason}" class="discount-reason-input chcr-input inline width-100" value="${discountReason}"></label>
            </div>
          `);

          manageDiscountPopower.on("beforeclose", async() => {

            const discount = +(manageDiscountPopower.content.querySelector(".discount-input") as HTMLInputElement).value;
            const discountReason = (manageDiscountPopower.content.querySelector(".discount-reason-input") as HTMLInputElement).value;
            const id = invoiceElement.getAttribute("id");

            if(id !== null){
              if(+discount > 0 && discountReason !== ""){
                const result = await AccountingAPI.saveDiscount(discount, discountReason, +id);
                if(result.status === "success"){
                  window.location.reload();
                }
              }
            }

          });

        }
      }

    }
    if(target.closest(".upload-camt54") !== null){
      const camt54Input = document.querySelector(".camt54-file-input") as HTMLInputElement;
      camt54Input.click();
    }

  }


  private async _editInvoice(id: number) {
    window.location.href = "/create-invoice?id=" + id;
  }


  private async _openInvoice(id: number) {

    let invoiceResult;
    let accountResult;

    if((document.querySelector("main.invoices .show-all") as HTMLInputElement).checked){
      invoiceResult = await AccountingAPI.getInvoiceById(id);
    } else {
      invoiceResult = await API.getInvoiceById(id);
    }

    if(invoiceResult.status !== "success"){
      return;
    }

    if(invoiceResult.invoices.length <= 0){
      return;
    }

    const invoice = invoiceResult.invoices[0];

    if((document.querySelector("main.invoices .show-all") as HTMLInputElement).checked){
      accountResult = await AccountingAPI.getBillingDataByCompanyID(invoice.CompanyID);
    } else {
      accountResult = await API.getBillingData();
    }

    if(accountResult.status !== "success"){
      return;
    }

    if(accountResult.account === undefined){
      return;
    }

    const account = accountResult.account;

    const stream = new BlobStream();

    await controlHomeQRBill.generateSwissQRBill(invoice, account, stream);

    window.open(stream.toBlobURL("application/pdf"), "_blank");

  }


  public async renderInvoices() {

    const searchInput = document.querySelector(".invoice-search") as HTMLInputElement;
    const search = searchInput !== null ? searchInput.value : undefined;
    const result = await API.getInvoices(search);

    if(typeof result.invoices !== "object"){
      return;
    }

    const items = result.invoices;

    if(this._virtualInfiniteScroller === undefined){
      this._initializeScrolling(items);
    } else {
      this._virtualInfiniteScroller.setItems(items);
    }

  }


  private _initializeScrolling(items) {

    const infiniteScrollContainer = document.querySelector("main.invoices .infinite-scroll") as HTMLElement;
    this._virtualInfiniteScroller = new VirtualInfiniteScrolling(infiniteScrollContainer, items);

    this._virtualInfiniteScroller.renderItem = invoice => {

      const li = document.createElement("li");

      li.setAttribute("id", invoice.ID);
      li.classList.add("invoice-item");

      li.setAttribute("comment", invoice.Comment ?? "");
      li.setAttribute("discount", Math.abs(+invoice.Discount) + "" ?? "");
      li.setAttribute("discount-reason", invoice.DiscountReason ?? "");

      let status = "";
      let orderStatus = "";
      let edit = "";
      let comment = "";
      let discount = "";

      if(invoice.Comment !== undefined && invoice.Comment !== ""){
        comment = "<i class=\"invoice-comment f7-icons\">bubble_left</i>";
      }
      if(+invoice.Discount > 0 && invoice.DiscountReason !== undefined && invoice.DiscountReason !== ""){
        discount = "<i class=\"invoice-discount f7-icons\">info_circle</i>";
      }

      if(+invoice.Status === controlHomeQRBill.InvoiceStatus.Offer){
        status = "Offerte";
        edit = "<i class=\"invoice-edit f7-icons\">pencil_circle</i>";
      } else if(+invoice.Status === controlHomeQRBill.InvoiceStatus.GameOver){
        status = "Betreibung";
      } else if(+invoice.Status === controlHomeQRBill.InvoiceStatus.Invoice || +invoice.Status === controlHomeQRBill.InvoiceStatus.Order){
        status = +invoice.Total + +invoice.ReminderFee - Math.abs(+invoice.Discount) <= +invoice.AmountPaid ? "Bezahlt" : "Offen";
      } else if(+invoice.Status === controlHomeQRBill.InvoiceStatus.Reminder1){
        status = +invoice.Total + +invoice.ReminderFee - Math.abs(+invoice.Discount) <= +invoice.AmountPaid ? "Bezahlt" : "Erinnerung";
      } else if(+invoice.Status === controlHomeQRBill.InvoiceStatus.Reminder2){
        status = +invoice.Total + +invoice.ReminderFee - Math.abs(+invoice.Discount) <= +invoice.AmountPaid ? "Bezahlt" : "1. Mahnung";
      } else if(+invoice.Status === controlHomeQRBill.InvoiceStatus.Reminder3){
        status = +invoice.Total + +invoice.ReminderFee - Math.abs(+invoice.Discount) <= +invoice.AmountPaid ? "Bezahlt" : "2. Mahnung";
      }

      if(+invoice.OrderStatus === controlHomeQRBill.OrderStatus.Received){
        orderStatus = "In Arbeit";
      } else if(+invoice.OrderStatus === controlHomeQRBill.OrderStatus.ReadyToShip){
        orderStatus = "Versandbereit";
      } else if(+invoice.OrderStatus === controlHomeQRBill.OrderStatus.Done){

        orderStatus = "Abgeschlossen";

        if(invoice.TrackingNumber !== ""){
          orderStatus = `Versendet: <a class="external" href="https://service.post.ch/ekp-web/ui/entry/search/${invoice.TrackingNumber}">${invoice.TrackingNumber}</a>`;
        }

      }

      li.innerHTML = `
        <div class="invoice-infos">
          <div class="invoice-number"><span class="label">Rechnungsnummer: </span><span>${invoice.ID}</span></div>
          <div class="invoice-date"><span class="label">Rechnungsdatum: </span><span>${new Date(invoice.Created).toLocaleDateString()}</span></div>
          <div class="invoice-deadline"><span class="label">Fälligkeitsdatum: </span><span>${new Date(invoice.PaymentDeadline).toLocaleDateString()}</span></div>
          <div class="invoice-amount-open"><span class="label">Offener Betrag: </span><span>CHF: ${functions.formatPrice(+invoice.Total + +invoice.ReminderFee - Math.abs(+invoice.AmountPaid) - Math.abs(+invoice.Discount))}</span></div>
          <div class="invoice-amount-total"><span class="label">Rechnungstotal: </span><span>CHF: ${functions.formatPrice(+invoice.Total)}</span></div>
          <div class="invoice-order-state"><span class="label">Status: </span><span>${orderStatus}</div>
          <div class="invoice-amount-state ${+invoice.Total - Math.abs(+invoice.Discount) <= +invoice.AmountPaid ? "paid" : "open"}"><span class="label">Zahlung: </span><span>${status} <i class="invoice-open f7-icons">arrow_up_right_square</i></span>${edit}${comment}${discount}<i class="invoice-manage-discount level-4 f7-icons">percent</i><i class="invoice-resend-mail level-4 f7-icons">envelope</i></div>
        </div>
      `;

      return li;

    };

    this._virtualInfiniteScroller.on("loadRequest", async index => {

      const searchInput = document.querySelector(".invoice-search") as HTMLInputElement;

      const result = await API.getInvoices(index + 1, searchInput.value);

      if(result.status === "success"){
        if(result.invoices.length > 0){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.addItems(result.invoices);
          }
        }
      }

    });

    this._virtualInfiniteScroller.init();

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    if(this._virtualInfiniteScroller === undefined){
      return;
    }

    this._virtualInfiniteScroller.destroy();

  }

}