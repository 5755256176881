import API from "../modules/api";
import "../styles/controller.css";
import Store from "./store";
import * as functions from "../../../shared/functions/functions";

const instances: Array<Controller> = [];

const identifier = "CH5316247114242781";

export default class Controller {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _scrollEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  public billingData: any;

  // private _intersectionEvent: (entries: IntersectionObserverEntry[]) => void;

  // private _observer: IntersectionObserver;

  private _controllerAnimationElement: HTMLObjectElement;
  private _controllerHighlightElement: SVGRectElement | undefined;
  private _controllerHighlightStartingPosition: number | undefined;

  private _animationFrameRequested: boolean = false;

  constructor(storeInstance?: Store) {


    //-- Destroy old instances

    Controller.destroy();
    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._scrollEvent = this._scroll.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);
    // this._intersectionEvent = this._intersection.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("scroll", this._scrollEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);


    //-- Get elements

    this._controllerAnimationElement = document.querySelector("main.controller object.controller-animation") as HTMLObjectElement;

    this._controllerAnimationElement.addEventListener("load", () => {
      this._controllerHighlightElement = this._controllerAnimationElement.contentDocument!.querySelector("g#Highlight rect") as SVGRectElement;
      this._controllerHighlightStartingPosition = +this._controllerHighlightElement.getAttribute("x")!;
    });


    //-- Set up intersection observer

    // this._observer = new IntersectionObserver(this._intersectionEvent, { threshold: 0 });
    // this._observer.observe(this._controllerAnimationElement);


    //-- Set identifiers

    document.querySelectorAll(".add-to-cart").forEach(element => {
      element.setAttribute("product", identifier);
    });



    //-- Get price

    API.getProductByIdentifier(identifier).then(async products => {

      if(products.status !== "success" || products.products.length === 0){
        return;
      }

      const product = products.products[0];

      console.log(product);

      const priceElement = document.querySelector(".price");

      if(priceElement !== null){
        priceElement.innerHTML = "CHF: " + await this._calculateAndFormatDistributorPrice(product.Price);
      }

      const priceUVPElement = document.querySelector(".price-uvp");

      if(priceUVPElement !== null){
        priceUVPElement.innerHTML = "UVP CHF: " + functions.formatPrice(product.Price);
      }

    });


  }


  // private _intersection(entries: IntersectionObserverEntry[]) {

  //   for(const entry of entries){
  //     if(entry.target.classList.contains("controller-animation")){
  //       if(entry.isIntersecting){
  //         console.log("play");
  //         this._controllerAnimationElement.play();
  //       } else {
  //         console.log("pause");
  //         this._controllerAnimationElement.pause();
  //       }
  //     }
  //   }

  // }


  private async _calculateAndFormatDistributorPrice(price: number): Promise<string> {

    const result = await this._getBillingData();
    let margin = 0;
    if(result !== undefined){
      margin = result.Margin;
    }

    return functions.calculateAndFormatDistributorPrice(price, margin);

  }


  private async _getBillingData(): Promise<any> {

    if(this.billingData === undefined && API.hasAccessToken() === true){

      const result = await API.getBillingData();

      this.billingData = result.account;

      return this.billingData;

    } else {
      return Promise.resolve(this.billingData);
    }

  }


  private async _scroll(ev: Event) {

    if(!await this._requestAnimationFrame()){
      return;
    }

    if(this._controllerHighlightElement !== undefined && this._controllerHighlightStartingPosition !== undefined){

      if(window.scrollY + window.innerHeight >= this._controllerAnimationElement.offsetTop + (this._controllerAnimationElement.offsetHeight / 2)){

        const percent = 100 / window.innerHeight * (window.scrollY + window.innerHeight - this._controllerAnimationElement.offsetTop - (this._controllerAnimationElement.offsetHeight / 2));

        if(percent > 0 && percent < 100){
          this._controllerHighlightElement.style.setProperty("transform", "translateX(-" + (percent / 100 * this._controllerHighlightStartingPosition) + "px)");
        }

      }

    }

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _requestAnimationFrame(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      if(this._animationFrameRequested === true){
        resolve(false);
      } else {

        this._animationFrameRequested = true;

        requestAnimationFrame(() => {
          resolve(true);
          this._animationFrameRequested = false;
        });
      }

    });
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("add-to-cart") || target.closest(".add-to-cart")){

      if(API.accessToken === undefined){
        window.location.reload();
      }

      const product = target.closest(".add-to-cart")?.getAttribute("product");

      if(!product){
        return;
      }

      const storeInstance = Store.getInstance();

      if(storeInstance !== undefined){
        storeInstance.cart = await API.addCartItem({ product, quantity: 1 });
      } else {
        await API.addCartItem({ product, quantity: 1 });
        window.location.reload();
      }

    }

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    // this._observer.disconnect();

  }

}