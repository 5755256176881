
import "../../shared/styles/classes.css";
import "../../shared/styles/theme.css";
import "../../shared/styles/common.css";
import "../../shared/styles/shared.css";
import "./styles/style.css";
import "./styles/header.css";
import "./styles/footer.css";
import "./styles/loginscreen.css";
import "./styles/cards.css";

import "framework7-icons/package/css/framework7-icons.css";

import router from "./modules/page-router/index";
import API from "./modules/api/index";
import Store from "./scripts/store";
import Popover from "../../shared/modules/popover";
import * as constants from "shared-constants";

window.addEventListener("DOMContentLoaded", ev => {

  const accountLinkElement = document.querySelector(".navigation ul li.account-link") as HTMLElement;

  if(accountLinkElement !== null){

    accountLinkElement.addEventListener("click", ev => {

      const level = document.body.dataset.level ?? 0;

      let adminLinks = "";

      if(+level >= 4){
        adminLinks += `
          <li class="has-link"><a href="/manage-orders">Bestellungen</a></li>
          <li class="has-link"><a href="/manage-products">Produkte</a></li>
          <li class="has-link"><a href="/manage-modules">Module</a></li>
          <li class="has-link"><a href="/manage-releases">Releases</a></li>
          <li class="has-link"><a href="/manage-retailers">Händler</a></li>
        `;
      }

      let profilePicture = "";

      if(store.billingData !== undefined && store.billingData.ProfilePicture !== ""){
        profilePicture = `
          <div class="profile-picture-container hidden">
            <div class="profile-picture-frame">
              <img src="${constants.URLS.DOWNLOADS_COMPANIES_BASE_PATH + store.billingData.CompanyID + "/account/images/" + store.billingData.ProfilePicture}" class="profile-picture" />
            </div>
          </div>
        `;
      }

      const accountMenu = new Popover(accountLinkElement, `
        ${profilePicture}
        <ul>
          ${adminLinks}
          <li class="has-link"><a href="/invoices">Rechnungen</a></li>
          <li class="has-link"><a href="/settings">Einstellungen</a></li>
          <li class="has-link red"><a href="/logout">Abmelden</a></li>
        </ul>
      `, { allowScroll: false, width: 192 });

      const profilePictureContainerElement = accountMenu.popover.querySelector(".profile-picture-container") as HTMLImageElement;
      const profilePictureElement = accountMenu.popover.querySelector(".profile-picture-container .profile-picture") as HTMLImageElement;

      if(profilePictureElement !== null){
        profilePictureElement.onload = () => {
          profilePictureContainerElement.classList.remove("hidden");
        };
      }

      accountMenu.content.addEventListener("click", () => {
        accountMenu.close();
      });

    });

  }

  const creatorLinkElement = document.querySelector(".navigation ul li.creator-link") as HTMLElement;

  if(creatorLinkElement !== null){

    creatorLinkElement.addEventListener("click", ev => {

      const accessToken = API.accessToken;

      if(accessToken !== undefined){
        window.location.href = constants.URLS.CREATOR_BASE_PATH + "overview?token=" + accessToken;
      } else {
        window.location.href = constants.URLS.CREATOR_BASE_PATH + "overview";
      }

    });

  }

  const creatorBetaLinkElement = document.querySelector(".navigation ul li.creator-beta-link") as HTMLElement;

  if(creatorBetaLinkElement !== null){

    creatorBetaLinkElement.addEventListener("click", ev => {

      const accessToken = API.accessToken;

      if(accessToken !== undefined){
        window.location.href = constants.URLS.CREATOR_BETA_BASE_PATH + "overview?token=" + accessToken;
      } else {
        window.location.href = constants.URLS.CREATOR_BETA_BASE_PATH + "overview";
      }

    });

  }


  //-- Observe header

  const headerObserver = document.querySelector(".header-observer");

  if(headerObserver !== null){
    const observer = new IntersectionObserver(headerIntersection, { threshold: 0 });
    observer.observe(headerObserver);
  }

});


function headerIntersection(entries: IntersectionObserverEntry[]) {

  const header = document.querySelector(".header");

  if(header === null){
    return;
  }

  for(const entry of entries){
    if(entry.target.classList.contains("header-observer")){
      if(entry.isIntersecting){
        header.classList.add("no-background");
      } else {
        header.classList.remove("no-background");
      }
    }
  }

}


const store = new Store();

router.always(url => {


  //-- Check if just hash changed

  if(url === router.currentRoute){
    return;
  }

  let path = url;
  path = path === "/" ? "/home" : path;

  const pathArray = path.split("/");
  pathArray.shift();


  //-- Login

  if(pathArray[0] === "login"){
    if(API.hasAccessToken() !== true){
      store.showLoginScreen();
      if(router.currentRoute === undefined){
        path = "/home";
        pathArray[0] = "home";
      } else {
        return;
      }
    } else {
      path = "/home";
      pathArray[0] = "home";
    }
  }


  //-- Logout

  if(pathArray[0] === "logout"){
    if(API.hasAccessToken() === true){
      store.logout();
      return;
    }
  }


  //-- Register retailer

  if(pathArray[0] === "register-retailer"){

    const level = document.body.dataset.level ?? 0;

    if(level < 0 || API.hasAccessToken() !== true){
      path = "/home";
      pathArray[0] = "home";
    }

  }


  //-- Store module

  if(pathArray[0] === "store" && pathArray[1] === "module"){
    path = "/store-module";
  }


  //-- Trigger beforeRouteLeave event

  const event = new Event("beforerouteleave");
  document.dispatchEvent(event);


  //-- Load new route

  const [onprogess, onload] = router._loadURL(path);

  onprogess.then(progress => {
    const progressbarElement = document.querySelector(".header .progressbar") as HTMLElement;
    const progressElement = progressbarElement.querySelector(".progress") as HTMLElement;
    if(progressbarElement !== undefined && progressbarElement !== undefined){
      progressbarElement.classList.add("visible");
      progressElement.style.width = progress + "vw";
    }
  });

  onload.then(async content => {

    const pageContent = document.querySelectorAll(".page-content");
    const header = document.querySelector(".header");

    if(pageContent.length > 0){

      pageContent[0].innerHTML = content as string;


      //-- Scroll to top

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto"
      });

    }

    const progressbarElement = document.querySelector(".header .progressbar") as HTMLElement;
    const progressElement = progressbarElement.querySelector(".progress") as HTMLElement;

    if(progressbarElement !== undefined && progressbarElement !== undefined){
      setTimeout(() => {
        progressElement.style.width = 0 + "";
        requestAnimationFrame(() => {
          progressbarElement.classList.remove("visible");
        });
      }, 100);
    }

    switch (pathArray[0]){
      case "home":{
        const Home = (await import(/* webpackChunkName: "home.ts" */ "./scripts/home")).default;
        const home = new Home();
        break;
      }
      case "products":{
        const Products = (await import(/* webpackChunkName: "products.ts" */ "./scripts/products")).default;
        const products = new Products();
        products.renderProducts();
        break;
      }
      case "controller":{
        const Controller = (await import(/* webpackChunkName: "controller.ts" */ "./scripts/controller")).default;
        const controller = new Controller();
        break;
      }
      case "docs":{
        const Docs = (await import(/* webpackChunkName: "updates.ts" */ "./scripts/docs")).default;
        const docs = new Docs();
        break;
      }
      case "blog":{
        const Updates = (await import(/* webpackChunkName: "updates.ts" */ "./scripts/updates")).default;
        const updateContainer = document.querySelector("main.blog .latest-updates") as HTMLElement;
        const updates = new Updates(updateContainer);
        updates.renderReleases();
        break;
      }
      case "invoices":{
        const Invoices = (await import(/* webpackChunkName: "invoices.ts" */ "./scripts/invoices")).default;
        const invoices = new Invoices();
        invoices.renderInvoices();
        break;
      }
      case "create-invoice":{
        const CreateInvoice = (await import(/* webpackChunkName: "create-invoice.ts" */ "./scripts/create-invoice")).default;
        const createInvoice = new CreateInvoice();
        break;
      }
      case "manage-retailers":{
        const Retailers = (await import(/* webpackChunkName: "retailers.ts" */ "./scripts/retailers")).default;
        const retailers = new Retailers();
        retailers.renderRetailers();
        break;
      }
      case "manage-releases":{
        const Releases = (await import(/* webpackChunkName: "releases.ts" */ "./scripts/releases")).default;
        const releases = new Releases();
        releases.renderReleases();
        break;
      }
      case "settings":{
        const Settings = (await import(/* webpackChunkName: "settings.ts" */ "./scripts/settings")).default;
        const settings = new Settings();
        break;
      }
      case "manage-products":{
        const ManageProducts = (await import(/* webpackChunkName: "manage-products.ts" */ "./scripts/manage-products")).default;
        const manageProducts = new ManageProducts();
        manageProducts.renderProducts();
        break;
      }
      case "manage-orders":{
        const ManageOrders = (await import(/* webpackChunkName: "manage-orders.ts" */ "./scripts/manage-orders")).default;
        const manageOrders = new ManageOrders();
        manageOrders.renderOrders();
        break;
      }
      case "manage-modules":{
        const Modules = (await import(/* webpackChunkName: "modules.ts" */ "./scripts/modules")).default;
        const modules = new Modules();
        modules.renderModules();
        break;
      }
      case "register-retailer":{

        const registerForm = document.querySelector(".register-form") as HTMLFormElement;

        if(registerForm !== null){

          registerForm.onsubmit = async ev => {

            ev.preventDefault();

            const emailInput = registerForm.querySelector(".register-email-input") as HTMLInputElement;
            const firstnameInput = registerForm.querySelector(".register-firstname-input") as HTMLInputElement;
            const lastnameInput = registerForm.querySelector(".register-lastname-input") as HTMLInputElement;
            const companyInput = registerForm.querySelector(".register-company-input") as HTMLInputElement;
            const phoneInput = registerForm.querySelector(".register-phone-input") as HTMLInputElement;
            const streetInput = registerForm.querySelector(".register-street-input") as HTMLInputElement;
            const housenumberInput = registerForm.querySelector(".register-housenumber-input") as HTMLInputElement;
            const zipInput = registerForm.querySelector(".register-zip-input") as HTMLInputElement;
            const cityInput = registerForm.querySelector(".register-city-input") as HTMLInputElement;

            const email = emailInput.value;
            const firstname = firstnameInput.value;
            const lastname = lastnameInput.value;
            const company = companyInput.value;
            const phone = phoneInput.value;
            const housenumber = housenumberInput.value;
            const street = streetInput.value;
            const zip = zipInput.value;
            const city = cityInput.value;

            const registerObject = {
              email,
              firstname,
              lastname,
              company,
              phone,
              housenumber,
              street,
              zip,
              city
            };

            if(await API.register(registerObject) === true){
              router.navigate("/home");
            } else {
              console.log("failure");
            }

            return false;

          };

        }

        break;
      }
      case "store":{

        const store = new Store();

        if(pathArray.length === 1){


          //-- Load modules

          store.renderModules();

        } else if(pathArray.length === 4){


          //-- Load module page

          if(pathArray[1] === "module"){
            store.renderModuleByTypeAndName(pathArray[2], pathArray[3]);
          }

        }

        break;
      }
      case "cart":{
        const store = new Store();
        store.loadCartPage();
        break;
      }
      case "password-reset":{

        const PasswordReset = (await import(/* webpackChunkName: "reset-password.ts" */ "./scripts/reset-password")).default;
        new PasswordReset();

        break;
      }
    }

  });

});