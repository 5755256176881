import API from "../modules/api";
import VirtualInfiniteScrolling from "../modules/infinite-scrolling";
import Popup from "../../../shared/modules/popup";
import * as constants from "shared-constants";

import { marked } from "marked";

import "../styles/releases.css";

const instances: Array<Releases> = [];
export default class Releases {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _virtualInfiniteScroller: VirtualInfiniteScrolling | undefined;

  constructor() {


    //-- Destroy old instances

    Releases.destroy();

    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("release-search")){

      const search = (target as HTMLInputElement).value;

      const result = await API.getReleases(search);

      if(result.status === "success"){
        if(this._virtualInfiniteScroller !== undefined){
          this._virtualInfiniteScroller.setItems(result.releases);
        }
      }

    }

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.closest(".new-release") !== null){
      const releasePopup = new ReleasePopup();
    }

    if(target.closest(".release-open") !== null){

      const releaseElement = target.closest(".release-item");

      if(releaseElement !== null){
        const id = releaseElement.getAttribute("id");
        if(id !== null){
          console.log("clicked on ", id);
          // this._openRelease(+id);
        }
      }

    }

  }


  public async renderReleases() {

    const searchInput = document.querySelector(".release-search") as HTMLInputElement;
    const search = searchInput !== null ? searchInput.value : undefined;
    const result = await API.getReleases(search);

    if(typeof result.releases !== "object"){
      return;
    }

    const items = result.releases;

    if(this._virtualInfiniteScroller === undefined){
      this._initializeScrolling(items);
    } else {
      this._virtualInfiniteScroller.setItems(items);
    }

  }


  private _initializeScrolling(items) {

    const infiniteScrollContainer = document.querySelector("main.releases .infinite-scroll") as HTMLElement;
    this._virtualInfiniteScroller = new VirtualInfiniteScrolling(infiniteScrollContainer, items);

    this._virtualInfiniteScroller.renderItem = release => {

      const li = document.createElement("li");
      li.setAttribute("id", release.ID);
      li.classList.add("release-item");

      li.innerHTML = `
        <div class="release-infos">
          <div class="release-platform"><span class="label">Platform: </span><span>${release.Type}</span></div>
          <div class="release-version"><span class="label">Version: </span><span>${release.Version}</span></div>
          <div class="release-date"><span class="label">Releasedate: </span><span>${new Date(release.ReleaseDate).toLocaleDateString()}</span></div>
          <div class="release-changelog"><span class="label">Changelog: </span><span>${marked(release.Changelog)}</span></div>
          <div class="release-status"><span class="label">Status: </span><span class="chip ${release.Released == true ? "released" : "pending"}">${release.Released ? "Released" : "Pending"}</span></div>
        </div>
      `;

      return li;

    };

    this._virtualInfiniteScroller.on("loadRequest", async index => {

      const searchInput = document.querySelector(".release-search") as HTMLInputElement;

      const result = await API.getReleases(index + 1, searchInput.value);

      if(result.status === "success"){
        if(result.releases.length > 0){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.addItems(result.releases);
          }
        }
      }

    });

    this._virtualInfiniteScroller.init();

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    if(this._virtualInfiniteScroller === undefined){
      return;
    }

    this._virtualInfiniteScroller.destroy();

  }

}


class ReleasePopup {

  private _releaseChangedEvent: (ev: Event) => void;
  private _saveEvent: (ev: Event) => void;

  private _releasePopup: Popup;

  constructor() {

    const changelog = "";


    //-- Calculate current timestampt for scheduled update

    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(now.getTime() - offset);
    const formattedDate = adjustedDate.toISOString().substring(0, 16); // For minute precision

    const popupHTML = `
      <div class="release-popup">
        <div class="chcr-label textarea">
          <label>Changelog</label>
        </div>
        <textarea class="width-100 chcr-input changelog" autocomplete="off" placeholder="">${changelog}</textarea>
        </br>
        </br>
        <h3>Version</h3>
        <div class="release-version-row">
          <div class="release-version-entry">
            <div class="chcr-label">
              <label>Automa</label>
            </div>
            <input type="text" maxlength="99" class="chcr-input version automa" disabled autocomplete="off" placeholder="-" value="" />
          </div>
          <div class="release-version-entry">
            <div class="chcr-label">
              <label>App</label>
            </div>
            <input type="text" maxlength="99" class="chcr-input version app" disabled autocomplete="off" placeholder="-" value="" />
          </div>
          <div class="release-version-entry">
            <div class="chcr-label">
              <label>Creator</label>
            </div>
            <input type="text" maxlength="99" class="chcr-input version creator" disabled autocomplete="off" placeholder="-" value="" />
          </div>
        </div>
        </br>
        <div class="chcr-label">
          <label>Release planen</label>
        </div>
        <input class="chcr-input width-100 datetime" type="datetime-local" value="${formattedDate}" min="${formattedDate}"/>
        </br>
        <label class="add-release chip">
          <input class="import-releases" type="file" accept=".chfw,application/chfw,.zip" multiple />
          <span>+ &nbsp; Release hinzufügen</span>
        </label>
        </br>
        <h3>Attached:</h3>
        <ul class="attachements">
        </ul>
        <progress class="width-100 hidden" max="100" value="0"></progress>
        </br>
        </br>
      </div>
    `;

    this._releasePopup = new Popup("Release planen", popupHTML);

    // releasePopup.popup.querySelector(".add-release").addEventListener("mousedown", (ev) => {
    //   this._releasePopup.popup.querySelector(".chcr-import-releases").val("");
    // });


    //-- Add eventlisteners

    this._releaseChangedEvent = this._releaseChanged.bind(this);
    this._saveEvent = this._uploadRelease.bind(this);

    this._releasePopup.popup.querySelector(".import-releases")?.addEventListener("change", this._releaseChangedEvent);
    this._releasePopup.on("save", this._saveEvent);

  }


  private _releaseChanged(ev: Event) {

    const inputTarget = ev.target as HTMLInputElement;

    if(inputTarget === null){
      return;
    }

    if(inputTarget.files !== null){

      for(let f = 0; f < inputTarget.files.length; f++){

        const name = inputTarget.files[f].name;

        const device = name.split("-")[0];
        const version = name.split("-")[1].replace(".zip", "").replace(".chfw", "");

        const versionInput = this._releasePopup.popup.querySelector("input.version." + device) as HTMLInputElement;

        if(versionInput !== null){
          versionInput.value = version;
        }

        const li = document.createElement("li");
        li.innerHTML = name;

        this._releasePopup.popup.querySelector("ul.attachements")?.appendChild(li);

      }
    }

  }


  private _uploadRelease() {

    const changelog = (this._releasePopup.popup.querySelector("textarea.changelog") as HTMLTextAreaElement).value;
    const automaVersion = (this._releasePopup.popup.querySelector("input.version.automa") as HTMLInputElement).value;
    const appVersion = (this._releasePopup.popup.querySelector("input.version.app") as HTMLInputElement).value;
    const creatorVersion = (this._releasePopup.popup.querySelector("input.version.creator") as HTMLInputElement).value;
    const datetime = (this._releasePopup.popup.querySelector("input.datetime") as HTMLInputElement).value;
    const datetimeDate = new Date(datetime);


    //-- Upload files --//

    const uploadFiles = () => {

      const formData = new FormData();


      //-- Add files

      const fileInput = this._releasePopup.popup.querySelector(".import-releases") as HTMLInputElement;

      if(fileInput.files !== null){
        for(let f = 0; f < fileInput.files.length; f++){
          formData.append("files[]", fileInput.files[f], fileInput.files[f].name);
        }
      }

      formData.append("changelog", changelog);
      formData.append("automaVersion", automaVersion);
      formData.append("appVersion", appVersion);
      formData.append("creatorVersion", creatorVersion);
      formData.append("uploadRelease", "true");

      const request = new XMLHttpRequest();

      request.open("POST", constants.URLS.UPLOAD_PATH, true);

      request.upload.onprogress = ev => {

        const progressElement = this._releasePopup.popup.querySelector("progress");

        if(progressElement !== null){
          progressElement.classList.remove("hidden");
          progressElement.value = (100 / ev.total * ev.loaded);
        }

      };

      request.onload = () => {
        if(request.status === 200){
          this._releasePopup.close();
          location.reload();
        }
      };

      request.send(formData);

    };

    if(automaVersion === "nightly" || appVersion === "nightly" || creatorVersion === "nightly"){
      uploadFiles();
    } else {

      API.createRelease({
        "app": appVersion,
        "creator": creatorVersion,
        "automa": automaVersion,
        "changelog": changelog,
        "releaseDate": datetimeDate
      }).then(response => {
        console.log("response: ", response);
        if(response.status === "success"){
          uploadFiles();
        }
      }).catch(err => {
        console.error("dev-create-release error: ", err);
      });

    }

  }

}


