import "./style.css";


class ImageViewer_ {

  private _clickEvent: (ev: Event) => void;
  private _keyDownEvent: (ev: Event) => void;

  private _overlayElement: HTMLDivElement;
  private _imageContainerElement: HTMLDivElement;
  private _imageElement: HTMLImageElement;
  private _leftArrowElement: HTMLDivElement;
  private _rightArrowElement: HTMLDivElement;

  private _currentTarget: HTMLImageElement | undefined;

  private _isOpen: boolean = false;

  constructor() {

    this._clickEvent = this._click.bind(this);
    this._keyDownEvent = this._keyDown.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("keydown", this._keyDownEvent);

    this._overlayElement = document.createElement("div");
    this._overlayElement.classList.add("image-viewer-overlay");

    this._imageContainerElement = document.createElement("div");
    this._imageContainerElement.classList.add("image-viewer-container");

    this._leftArrowElement = document.createElement("div");
    this._leftArrowElement.classList.add("image-viewer-arrow");
    this._leftArrowElement.classList.add("left");
    this._leftArrowElement.innerHTML = `
      <i class="f7-icons">chevron_left</i>
    `;


    this._rightArrowElement = document.createElement("div");
    this._rightArrowElement.classList.add("image-viewer-arrow");
    this._rightArrowElement.classList.add("right");
    this._rightArrowElement.innerHTML = `
      <i class="f7-icons">chevron_right</i>
    `;

    this._imageElement = document.createElement("img");

  }


  public register() {

  }


  private _keyDown(ev) {

    if(ev.keyCode === 37){
      this.previous();
    }
    if(ev.keyCode === 39){
      this.next();
    }

  }


  private _click(ev: Event) {

    const target = ev.target as HTMLImageElement;

    if(target.tagName.toLowerCase() === "img" && target.classList.contains("image-viewer")){
      this.openImage(target);
    }

    if(target.classList.contains("image-viewer-overlay")){
      this.close();
    }

    if(target.classList.contains("image-viewer-arrow")){
      if(target.classList.contains("left")){
        this.previous();
      }
      if(target.classList.contains("right")){
        this.next();
      }
    }

  }


  public openImage(imageElement: HTMLImageElement) {
    if(imageElement !== null && imageElement !== undefined){

      this._currentTarget = imageElement;
      const imageSource = imageElement.getAttribute("src");

      if(imageSource){
        this.openImageURL(imageSource);
      }
    }
  }


  public openImageURL(url: string) {

    if(!document.body.contains(this._overlayElement)){
      document.body.appendChild(this._overlayElement);
    }
    if(!document.body.contains(this._imageContainerElement)){
      document.body.appendChild(this._imageContainerElement);
    }
    if(!this._imageContainerElement.contains(this._leftArrowElement)){
      this._imageContainerElement.appendChild(this._leftArrowElement);
    }
    if(!this._imageContainerElement.contains(this._imageElement)){
      this._imageContainerElement.appendChild(this._imageElement);
    }
    if(!this._imageContainerElement.contains(this._rightArrowElement)){
      this._imageContainerElement.appendChild(this._rightArrowElement);
    }

    this._imageElement.setAttribute("src", url);

    this._imageContainerElement.classList.add("visible");
    this._overlayElement.classList.add("visible");


    //-- Wait 2 event loop cycles to ensure transition

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this._imageContainerElement.classList.add("open");
        this._overlayElement.classList.add("open");
      });
    });

    this._isOpen = true;

  }


  public close() {

    this._overlayElement.classList.add("closing");
    this._imageContainerElement.classList.add("closing");

    setTimeout(() => {

      this._isOpen = false;

      this._imageContainerElement.classList.remove("visible");
      this._overlayElement.classList.remove("visible");

      this._imageContainerElement.classList.remove("closing");
      this._overlayElement.classList.remove("closing");

      this._imageContainerElement.classList.remove("open");
      this._overlayElement.classList.remove("open");

    }, 300);

  }


  public next() {

    if(this._currentTarget !== undefined){
      const nextElement = this._getNextSibling(this._currentTarget, ".image-viewer");
      if(nextElement !== undefined){
        this.openImage(nextElement);
      }
    }

  }


  public previous() {

    if(this._currentTarget !== undefined){
      const previousElement = this._getPreviousSibling(this._currentTarget, ".image-viewer");
      if(previousElement !== undefined){
        this.openImage(previousElement);
      }
    }

  }


  private _getPreviousSibling(element: HTMLImageElement, selector: string): HTMLImageElement | undefined {

    const allElements = document.querySelectorAll(selector);

    for(let i = 0; i < allElements.length; i++){
      if(allElements[i] === element){
        if(allElements[i - 1] !== undefined && allElements[i - 1].tagName.toLowerCase() === "img"){
          return allElements[i - 1] as HTMLImageElement;
        }
      }
    }

  }


  private _getNextSibling(element: HTMLImageElement, selector: string): HTMLImageElement | undefined {

    const allElements = document.querySelectorAll(selector);

    for(let i = 0; i < allElements.length; i++){
      if(allElements[i] === element){
        if(allElements[i + 1] !== undefined && allElements[i + 1].tagName.toLowerCase() === "img"){
          return allElements[i + 1] as HTMLImageElement;
        }
      }
    }

  }

}

const ImageViewer = new ImageViewer_();

export default ImageViewer;