import { AccountingAPI } from "../modules/api";
import "../styles/create-invoice.css";

import * as websiteTypes from "../../../shared/interfaces/website";
import { getUrlVars, isParseableJSON } from "../../../shared/functions/functions";

export default class CreateInvoice {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _customerSelect: HTMLSelectElement;
  private _amountInput: HTMLInputElement;
  private _measuerInput: HTMLInputElement;
  private _priceInput: HTMLInputElement;
  private _titleInput: HTMLInputElement;
  private _textInput: HTMLTextAreaElement;
  private _positionSelect: HTMLSelectElement;
  private _newPositionButton: HTMLButtonElement;
  private _deletePositionButton: HTMLButtonElement;
  private _savePositionButton: HTMLButtonElement;
  private _saveInvoiceButton: HTMLButtonElement;

  private _id: number | undefined;
  private _positions: Array<websiteTypes.CartItem> = [];

  constructor() {


    //-- Get elements

    this._customerSelect = document.querySelector("main.create-invoice .company-selection") as HTMLSelectElement;
    this._amountInput = document.querySelector("main.create-invoice .position-amount-input") as HTMLInputElement;
    this._measuerInput = document.querySelector("main.create-invoice .position-measure-input") as HTMLInputElement;
    this._priceInput = document.querySelector("main.create-invoice .position-price-input") as HTMLInputElement;
    this._titleInput = document.querySelector("main.create-invoice .position-title-input") as HTMLInputElement;
    this._textInput = document.querySelector("main.create-invoice .position-text-input") as HTMLTextAreaElement;
    this._positionSelect = document.querySelector("main.create-invoice .position-selection") as HTMLSelectElement;
    this._newPositionButton = document.querySelector("main.create-invoice .new-position-button") as HTMLButtonElement;
    this._deletePositionButton = document.querySelector("main.create-invoice .delete-position-button") as HTMLButtonElement;
    this._savePositionButton = document.querySelector("main.create-invoice .save-position-button") as HTMLButtonElement;
    this._saveInvoiceButton = document.querySelector("main.create-invoice .save-invoice-button") as HTMLButtonElement;

    this._id = getUrlVars().id;

    if(this._id !== undefined){
      this._fetchInvoice();
    }


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);


    //-- Update customers

    this._updateCustomers();

  }


  private async _updateCustomers() {

    const result = await AccountingAPI.getCustomers();

    if(result.status === "success"){
      for(const customer of result.customers){

        const option = document.createElement("option");
        option.value = customer.ID;
        option.text = customer.Company;

        this._customerSelect.appendChild(option);

      }
    }

  }


  private async _fetchInvoice() {

    if(this._id === undefined){
      return;
    }

    const result = await AccountingAPI.getInvoiceById(this._id);

    if(result.status === "success" && result.invoices.length === 1){

      if(!isParseableJSON(result.invoices[0].Items)){
        return;
      }

      this._positions = JSON.parse(result.invoices[0].Items);
      this._loadPositions();

    }

  }


  private _convertToInvoice() {

    if(this._id === undefined){
      return;
    }

    if(confirm("Die Rechnung wird an den Kunden verschickt, fortfahren?")){
      this._saveInvoice();
      AccountingAPI.convertToInvoice(this._id);
      window.location.href = "/invoices";
    }
  }


  private _saveInvoice() {
    const customer = this._customerSelect.options[this._customerSelect.selectedIndex].value;
    AccountingAPI.saveInvoice(this._positions, +customer, this._id);
  }


  private _addPosition() {

    const position = {
      "name": "Neue Position",
      "quantity": 0,
      "measure": "",
      "price": 0,
      "description": ""
    };

    this._positions.push(position);

    this._loadPositions();

  }


  private _deletePosition() {

    const index = this._positionSelect.selectedIndex;

    for(let i = this._positions.length - 1; i >= 0; i--){
      if(i === index){
        this._positions.splice(i, 1);
      }
    }

    this._loadPositions();

  }


  private _savePosition() {

    const index = this._positionSelect.selectedIndex;

    const position = {
      "name": this._titleInput.value,
      "quantity": +this._amountInput.value,
      "measure": this._measuerInput.value,
      "price": +this._priceInput.value,
      "description": this._textInput.value
    };

    if(index === null || index === undefined || index < 0){


      //-- New position

      this._positions.push(position);

      const option = document.createElement("option");
      option.text = (this._positions.indexOf(position) + 1) + ": " + position.name;

      this._positionSelect.appendChild(option);

      this._clearInputs();

      return;

    } else {


      //-- Update position

      this._positions[index] = position;

    }

  }


  private _loadPositions() {

    this._positionSelect.innerHTML = "";
    this._clearInputs();

    for(const position of this._positions){

      const option = document.createElement("option");
      option.text = (this._positions.indexOf(position) + 1) + ": " + position.name;

      this._positionSelect.appendChild(option);

    }

  }


  private _loadPosition(index: number) {

    if(this._positions[index] === undefined){
      return;
    }

    this._titleInput.value = this._positions[index].name;
    this._amountInput.value = this._positions[index].quantity + "";
    this._measuerInput.value = this._positions[index].measure || "";
    this._priceInput.value = this._positions[index].price + "";
    this._textInput.value = this._positions[index].description;

  }


  private _clearInputs() {
    this._titleInput.value = "";
    this._amountInput.value = "";
    this._measuerInput.value = "";
    this._priceInput.value = "";
    this._textInput.value = "";
  }


  private _click(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("new-position-button")){
      this._addPosition();
    }
    if(target.classList.contains("delete-position-button")){
      this._deletePosition();
    }
    if(target.classList.contains("save-position-button")){
      this._savePosition();
    }
    if(target.classList.contains("save-invoice-button")){
      this._saveInvoice();
    }
    if(target.classList.contains("convert-to-invoice-button")){
      this._convertToInvoice();
    }

  }


  private _inputInput(ev: Event) {

    const target = ev.target as HTMLInputElement;

    if(target.classList.contains("position-selection")){

      const index = this._positionSelect.selectedIndex;

      if(index !== null && index !== undefined || index < 0){
        this._loadPosition(index);
      }

    }

  }


  public destroy() {
    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);
  }


}