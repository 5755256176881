import "./details.css";

class Details_ {

  constructor() {
    document.addEventListener("mouseover", this._enter.bind(this));
  }


  private _enter(ev: MouseEvent): void {

    const target = ev.target as Element;

    if(target.classList.contains("marker") && !target.classList.contains("has-event")){
      target.classList.add("has-event");
      target.addEventListener("mousedown", this._click.bind(this));
    }

  }


  private _click(ev: any): void {

    const target = ev.target as Element;
    const parent = target.parentElement;

    if(parent === null){
      return;
    }

    if(target.classList.contains("marker")){

      ev.stopPropagation();

      let opened = "";

      if(parent.getAttribute("open") !== null){
        parent.removeAttribute("open");
      } else {
        parent.setAttribute("open", "");
        opened = "open";
      }

      if(parent.getAttribute("details") !== null){
        writeData("DETAILS_" + parent.getAttribute("details"), opened);
      }
    }
  }

  isOpen(tag: string) {
    return readData("DETAILS_" + tag) || false;
  }

}


function readData(key: string): string | null {
  return localStorage.getItem(key);
}


function writeData(key: string, value: string): void {
  localStorage.setItem(key, value);
}
const Details = new Details_();
export default Details;