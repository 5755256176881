import API from "../modules/api";
import VirtualInfiniteScrolling from "../modules/infinite-scrolling";
import * as functions from "../../../shared/functions/functions";

import "../styles/retailers.css";

const instances: Array<Retailers> = [];

export default class Retailers {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _virtualInfiniteScroller: VirtualInfiniteScrolling | undefined;

  constructor() {


    //-- Destroy old instances

    Retailers.destroy();

    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("retailer-search")){

      const searchInput = document.querySelector(".retailer-search") as HTMLInputElement;
      const search = searchInput !== null ? searchInput.value : undefined;
      const result = await API.getRetailers(search);

      if(result.status === "success"){
        if(this._virtualInfiniteScroller !== undefined){
          this._virtualInfiniteScroller.setItems(result.retailers);
        }
      }

    }

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

    // if(target.closest(".retailer-edit") !== null){

    //   const retailerElement = target.closest(".retailer-item");

    //   if(retailerElement !== null){
    //     const id = retailerElement.getAttribute("id");
    //     if(id !== null){
    //       this._editRetailer(+id);
    //     }
    //   }

    // }

  }


  public async renderRetailers() {

    const searchInput = document.querySelector(".retailer-search") as HTMLInputElement;
    const search = searchInput !== null ? searchInput.value : undefined;
    const result = await API.getRetailers(search);

    if(typeof result.retailers !== "object"){
      return;
    }

    const items = result.retailers;

    if(this._virtualInfiniteScroller === undefined){
      this._initializeScrolling(items);
    } else {
      this._virtualInfiniteScroller.setItems(items);
    }

  }


  private _initializeScrolling(items) {

    const infiniteScrollContainer = document.querySelector("main.retailers .infinite-scroll") as HTMLElement;
    this._virtualInfiniteScroller = new VirtualInfiniteScrolling(infiniteScrollContainer, items);

    this._virtualInfiniteScroller.renderItem = retailer => {

      const li = document.createElement("li");
      li.setAttribute("id", retailer.ID);
      li.classList.add("retailer-item");
      li.setAttribute("comment", retailer.Comment ?? "");

      const status = retailer.Blocked === 1 ? "Blockiert" : "";

      li.innerHTML = `
        <div class="retailer-infos">
          <div class="retailer-company"><span class="label">Firma: </span><span>${retailer.Company}</span></div>
          <div class="retailer-address"><span class="label">Adresse: </span><span>${retailer.Street} ${retailer.HouseNumber} ${retailer.Zip} ${retailer.City}</span></div>
          <div class="retailer-phone"><span class="label">Telefon: </span><span>${retailer.Phone}</span></div>
          <div class="retailer-amount-open"><span class="label">Offener Betrag: </span><span>CHF: 0.-</span></div>
          <div class="retailer-amount-state"><span class="label">Status: </span><span>${status}</div>
        </div> 
      `;

      return li;

    };

    this._virtualInfiniteScroller.on("loadRequest", async index => {

      const searchInput = document.querySelector(".retailer-search") as HTMLInputElement;

      const result = await API.getRetailers(index + 1, searchInput.value);

      if(result.status === "success"){
        if(result.retailers.length > 0){
          if(this._virtualInfiniteScroller !== undefined){
            this._virtualInfiniteScroller.addItems(result.retailers);
          }
        }
      }

    });

    this._virtualInfiniteScroller.init();

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    if(this._virtualInfiniteScroller === undefined){
      return;
    }

    this._virtualInfiniteScroller.destroy();

  }

}