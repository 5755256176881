import "../styles/docs.css";


//  Import prism

import Prism from "prismjs";

import "../styles/prism.css";
import "../../../node_modules/prismjs/plugins/unescaped-markup/prism-unescaped-markup.css";
import "../../../node_modules/prismjs/plugins/unescaped-markup/prism-unescaped-markup.min.js";

import "../../../node_modules/prismjs/components/prism-typescript.min.js";
import "../../../node_modules/prismjs/components/prism-json.min.js";


const instances: Array<Docs> = [];

export default class Docs {

  private _clickEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _codeElements: NodeListOf<HTMLElement>;

  constructor() {


    //-- Destroy old instances

    Docs.destroy();
    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);


    //-- Get elements

    this._codeElements = document.querySelectorAll("main.docs code");

    console.log("init docs");

    this._highlightAll();


  }


  private _highlightAll() {
    for(let e = 0; e < this._codeElements.length; e++){
      Prism.highlightElement(this._codeElements[e]);
    }
  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _click(ev: Event) {
    const target = ev.target as HTMLElement;
  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }

}