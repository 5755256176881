import * as common from "../common/common";
import * as PopoverTypes from "./types";

import TinyEventEmitter from "../tiny-event-emitter";

import "./popover.css";

export default class Popover extends TinyEventEmitter {

  public popover: HTMLDivElement;
  public content: HTMLDivElement;
  public contentContainer: HTMLDivElement;

  private _closeListener: any;
  private _updatePositionListener: any;
  private _sourceDocument: Document;
  private _targetDocument: Document;
  private _element: HTMLElement | undefined;
  private _options: PopoverTypes.Options | undefined;
  private _coordinates: { x: number, y: number } | undefined;

  constructor(coordinates: { x: number, y: number }, html: string, options?: PopoverTypes.Options, sourceDocument?: Document, targetDocument?: Document)
  constructor(element: HTMLElement, html: string, options?: PopoverTypes.Options, sourceDocument?: Document, targetDocument?: Document)
  constructor(elementOrCoordinates: HTMLElement | { x: number, y: number }, html: string, options?: PopoverTypes.Options, sourceDocument: Document = document, targetDocument: Document = document) {

    super();

    this._closeListener = this._close.bind(this);
    this._updatePositionListener = this._updatePosition.bind(this);
    this._sourceDocument = sourceDocument;
    this._targetDocument = targetDocument;
    this._options = options;

    if(typeof elementOrCoordinates === "object" && (elementOrCoordinates as HTMLElement).attributes !== undefined && ((elementOrCoordinates as HTMLElement).innerHTML !== undefined || (elementOrCoordinates as HTMLElement).innerText !== undefined)){
      this._element = elementOrCoordinates as HTMLElement;
    } else {
      this._coordinates = elementOrCoordinates as { x: number, y: number };
    }


    //-- Remove old popovers

    const oldPopovers = this._targetDocument.getElementsByClassName("chcr-popover");
    for(let o = 0; 0 < oldPopovers.length; o++){
      const parent = oldPopovers[o].parentNode;
      if(parent === null){
        continue;
      }
      parent.removeChild(oldPopovers[o]);
    }


    //-- Create new popover

    this.popover = this._targetDocument.createElement("div");
    this.popover.classList.add("chcr-popover");
    this.contentContainer = this._targetDocument.createElement("div");
    this.contentContainer.classList.add("content-container");
    this.content = this._targetDocument.createElement("div");
    this.content.classList.add("content");
    this.content.innerHTML = html;


    //-- Append element to dom on the top left position to be able to calculate with and height correctly

    this.contentContainer.appendChild(this.content);
    this.popover.appendChild(this.contentContainer);
    this._targetDocument.body.appendChild(this.popover);

    if(this._options !== undefined){
      if(this._options.allowScroll === false){
        this.popover.classList.add("no-scrolling");
      }
    }

    this._updatePosition();


    //-- Initialize event listeners

    setTimeout(() => {

      this._targetDocument.addEventListener("mousedown", this._closeListener);
      this._targetDocument.defaultView?.addEventListener("resize", this._updatePosition.bind(this));
      this._targetDocument.addEventListener("dragstart", this._closeListener);

      if(this._element !== undefined){
        const scrollParent = common.getScrollParent(this._element);
        window.addEventListener("scroll", this._updatePositionListener);
        scrollParent.addEventListener("scroll", this._updatePositionListener);
      }

      if(this._sourceDocument !== this._targetDocument){
        this._sourceDocument.addEventListener("mousedown", this._closeListener);
        this._sourceDocument.addEventListener("dragstart", this._closeListener);
      }

    }, 0);

  }


  private _close(ev) {

    if(ev.target.classList.contains("chcr-popover")){
      return;
    }

    if(common.queryNextParentNodeBySelectors(ev.target, ".chcr-popover") !== undefined){
      return;
    }

    if(common.queryNextParentNodeBySelectors(ev.target, ".chcr-contextmenu") !== undefined){
      return;
    }


    //-- Remove event listeners

    document.removeEventListener("click", this._closeListener);

    if(this._sourceDocument !== this._targetDocument){
      this._sourceDocument.removeEventListener("click", this._closeListener);
    }


    this.close();

  }


  private _updatePosition() {

    let posX = 0;
    let posY = 0;
    let width = 0;
    let height = 0;
    let targetWidth = 0;
    let targetHeight = 0;
    let targetPosX = 0;
    let targetPosY = 0;
    let scaling = 1;

    let arrowPosX = 0;
    let arrowPosY = 0;

    const arrowSize = 28;
    const margin = 6;

    if(this._element !== undefined){
      targetPosX = this._element.getBoundingClientRect().left;
      targetPosY = this._element.getBoundingClientRect().top;
    } else {
      if(this._coordinates !== undefined){
        targetPosX = this._coordinates.x;
        targetPosY = this._coordinates.y;
      }
    }


    //-- Override values if passed via options

    if(this._options !== undefined){
      if(this._options.posX !== undefined){
        targetPosX = this._options.posX;
      }
      if(this._options.posY !== undefined){
        targetPosY = this._options.posY;
      }
      if(this._options.scaling !== undefined){
        scaling = this._options.scaling;
      }
      if(this._options.width !== undefined){
        width = this._options.width;
        this.content.style.setProperty("width", this._options.width + "px");
      }
      if(this._options.maxwidth !== undefined){
        this.content.style.setProperty("max-width", this._options.maxwidth + "px");
      }
      if(this._options.height !== undefined){
        height = this._options.height;
        this.contentContainer.style.setProperty("height", this._options.height + "px");
        this.content.style.setProperty("height", this._options.height + "px");
      }
      if(this._options.maxheight !== undefined){
        height = (height > this._options.maxheight ? this._options.maxheight : height);
        this.contentContainer.style.setProperty("max-height", this._options.maxheight + "px");
        this.content.style.setProperty("max-height", this._options.maxheight + "px");
      }
    }


    //-- Adjust position to top center of element

    width = this.popover.clientWidth;
    height = this.popover.clientHeight;

    if(this._element !== undefined){
      targetWidth = this._element.clientWidth * scaling;
      targetHeight = this._element.clientHeight * scaling;
    }


    //-- Calculate popover position

    posX = (targetPosX + targetWidth / 2) - (width / 2);
    posY = targetPosY - (height + arrowSize / 2);


    //-- Calculate arrow position

    arrowPosY = height;


    //-- Update position if element is outside viewport on the right side

    if(posX + width + margin > window.innerWidth){

      posX = window.innerWidth - width - margin;

    }


    //-- Update position if element is outside viewport on the left side

    if(posX < margin){

      posX = margin;

    }


    //-- Update position if element is outside viewport on the top side

    if(posY < margin){


      //-- Update arrow position

      this.popover.classList.add("top");
      arrowPosY = 0 - arrowSize;


      //-- Update popover position

      posY = targetHeight + targetPosY;

    } else {
      this.popover.classList.remove("top");
    }


    //-- Make sure popover is not under scrollbar

    if(posX + width > this._targetDocument.body.scrollWidth){
      posX = this._targetDocument.body.scrollWidth - width;
    }
    if(posY + height > this._targetDocument.body.scrollHeight){
      posY = this._targetDocument.body.scrollHeight - height;
    }


    //-- Set Arrow position

    if(targetPosX > posX){
      if(targetWidth > width){
        arrowPosX = targetPosX - posX;
      } else {
        arrowPosX = (targetPosX - posX) + (targetWidth / 2);
      }
    } else {
      if(targetWidth > width){
        arrowPosX = targetWidth / 2 - posX + targetPosX;
      } else {
        arrowPosX = targetWidth / 2 - posX + targetPosX;
      }
    }

    if(this._options?.showArrow === false){
      this.popover.style.setProperty("--ch-popover-arrow-visibility", "hidden");
    }

    this.popover.style.setProperty("--ch-popover-left", arrowPosX + "px");
    this.popover.style.setProperty("--ch-popover-top", arrowPosY + "px");
    this.popover.style.setProperty("left", posX + "px");
    this.popover.style.setProperty("top", posY + "px");

  }


  public close() {

    const parent = this.popover.parentNode;

    if(parent === null){
      return;
    }

    this.emit("beforeclose", null);

    parent.removeChild(this.popover);

    this.emit("close", null);

  }

}