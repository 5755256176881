import { types } from "shared/interfaces/types";
import API from "../modules/api";

import * as constants from "shared-constants";

import "../styles/products.css";
import Store from "./store";

const instances: Array<Products> = [];

export default class Products {

  private _productsMainElement: HTMLElement;
  private _footerElement: HTMLElement;

  private _clickEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;
  private _intersectionEvent: (entries: IntersectionObserverEntry[]) => void;

  private _observer: IntersectionObserver;

  constructor() {


    //-- Destroy old instances

    Products.destroy();
    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._intersectionEvent = this._intersection.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);


    //-- Get elements

    this._productsMainElement = document.querySelector("main.products") as HTMLElement;
    this._footerElement = document.querySelector("footer") as HTMLElement;


    //-- Set up intersection observer

    this._observer = new IntersectionObserver(this._intersectionEvent, { threshold: 0 });
    this._observer.observe(this._productsMainElement);
    this._observer.observe(this._footerElement);

  }


  public async renderProducts() {

    const productsContainerElement = document.querySelector("main.products-container") as HTMLElement;
    const result = await API.getProducts(0, true);

    if(result.status !== "success"){
      return;
    }

    const products: Array<types.Object> = result.products;


    //-- Sort search by type

    products.sort((a, b) => {
      if(a.Type < b.Type){
        return -1;
      } else if(a.Type > b.Type){
        return 1;
      }  else {
        return 0;
      }
    });

    let productHTML = "";

    for(let p = 0; p < products.length; p++){


      //-- Insert category title

      // if(p === 0 || (p > 0 && products[p - 1].Type !== products[p].Type)){
      //   productHTML += `
      //     <h2>${products[p].Type}</h2>
      //   `;
      // }

      productHTML += `
        <section id="section-${p + 1}" style="background-image: url('${constants.URLS.WEBSITE_ASSET_PATH + "/controlHome-controller-hero.jpg"}');">
          <div class="top-section">
            <h2>${products[p].Name}</h2>
            <p>${products[p].ShortDescription}</p>
          </div>
          <div class="bottom-section">
            <div class="buttons">
              <a href="${products[p].URL}" class="button filled">Mehr erfahren</a>
              <button class="button add-to-cart signedin-only" product="${products[p].Identifier}"><i class="f7-icons">cart_badge_plus</i><p>In den Warenkorb</p></button>
            </div>
            <a href="#section-2" class="content-reveal-link"><img src="/assets/chevron_compact_down.svg" /></a>
          </div>
        </section>
      `;

    }

    productsContainerElement.innerHTML = `
      ${productHTML}
    `;

  }


  private _intersection(entries: IntersectionObserverEntry[]) {

    for(const entry of entries){
      if(entry.target.classList.contains("footer")){
        if(entry.isIntersecting){
          document.documentElement.classList.remove("scroll-snapping");
        } else {
          document.documentElement.classList.add("scroll-snapping");
        }
      }
    }

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("add-to-cart") || target.closest(".add-to-cart")){

      if(API.accessToken === undefined){
        window.location.reload();
      }

      const product = target.closest(".add-to-cart")?.getAttribute("product");

      if(!product){
        return;
      }

      const storeInstance = Store.getInstance();

      if(storeInstance !== undefined){
        storeInstance.cart = await API.addCartItem({ product, quantity: 1 });
      } else {
        await API.addCartItem({ product, quantity: 1 });
        window.location.reload();
      }

    }

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    document.documentElement.classList.remove("scroll-snapping");
    this._observer.disconnect();

  }

}