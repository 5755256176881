import API from "../modules/api";
import VirtualInfiniteScrolling from "../modules/infinite-scrolling";

import { marked } from "marked";

import "../styles/latest-updates.css";


const instances: Array<Updates> = [];

type UpdateCategories = "module" | "release" | "news";

interface Update {
  title: string,
  releaseDate: number,
  content: string,
  version: string,
  id: number,
  type: UpdateCategories
}

export default class Updates {

  private _container: HTMLElement;

  private _noScrolling: boolean = false;

  private _filter: UpdateCategories | undefined;

  private _moduleIndex: number = 0;
  private _releaseIndex: number = 0;

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _virtualInfiniteScroller: VirtualInfiniteScrolling | undefined;

  constructor(container: HTMLElement, noScrolling: boolean = false) {


    //-- Destroy old instances

    Updates.destroy();

    instances.push(this);

    this._container = container;
    this._noScrolling = noScrolling;


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }


  private _getSearchValue(): string | undefined {

    const searchInput = document.querySelector(".latest-updates-search") as HTMLInputElement;
    const search = searchInput !== null ? searchInput.value : undefined;

    return search;

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _getReleases(): Promise<Array<Update>> {

    const search = this._getSearchValue();

    let modules: any = { modules: []};
    let releases: any = { releases: []};

    if(this._filter === undefined || this._filter === "release"){
      releases = await API.getReleases(this._releaseIndex, search);
    }
    if(this._filter === undefined || this._filter === "module"){
      modules = await API.getModules(this._moduleIndex, search);
    }


    //-- Adjust indices

    this._releaseIndex += releases.releases.length;
    this._moduleIndex += modules.modules.length;

    const releaseArray: Array<Update> = [];

    for(const module of modules.modules){
      releaseArray.push({
        title: module.Name,
        content: module.Changelog,
        id: module.ID,
        type: "module",
        releaseDate: new Date(module.ReleaseDate).getTime(),
        version: module.Version
      });
    }

    for(const release of releases.releases){
      releaseArray.push({
        title: release.Type,
        content: release.Changelog,
        id: release.ID,
        type: "release",
        releaseDate: new Date(release.ReleaseDate).getTime(),
        version: release.Version
      });
    }

    releaseArray.sort((a, b) => {
      return a.releaseDate > b.releaseDate ? -1 : 1;
    });

    return releaseArray;

  }


  public async renderReleases() {

    const items = await this._getReleases();

    if(this._noScrolling === true){

      for(const item of items){
        this._container.appendChild(this._renderFunction(item));
      }

    } else {
      if(this._virtualInfiniteScroller === undefined){
        this._initializeScrolling(items);
      } else {
        this._virtualInfiniteScroller.setItems(items);
      }
    }

  }


  private _renderFunction(item: Update): HTMLLIElement {

    const li = document.createElement("li");
    li.setAttribute("type", item.type);
    li.setAttribute("id", item.id + "");
    li.classList.add("update-item");

    li.innerHTML = `
      <div class="title-bar">
        <div class="update-title">${item.title} v${item.version}</div>
        <div class="update-release-date">${new Date(item.releaseDate).toLocaleDateString()}</div>
      </div>
      <div class="update-content">
        ${marked(item.content)}
      </div>
    `;

    return li;

  }


  private _initializeScrolling(items: Array<Update>) {

    this._virtualInfiniteScroller = new VirtualInfiniteScrolling(this._container, items);

    this._virtualInfiniteScroller.renderItem = this._renderFunction;

    this._virtualInfiniteScroller.on("loadRequest", async index => {

      const items = await this._getReleases();

      if(this._virtualInfiniteScroller !== undefined){
        this._virtualInfiniteScroller.addItems(items);
      }

    });

    this._virtualInfiniteScroller.init();

  }


  private async _click(ev: Event) {

    if(ev.target === null){
      return;
    }

    const target = ev.target as HTMLElement;

    if(target.classList.contains("latest-updates-filter")){
      this._filter = (target.getAttribute("data-filter") ?? undefined) as UpdateCategories;
      this._releaseIndex = 0;
      this._moduleIndex = 0;
      this.renderReleases();
    }

  }


  private async _inputInput(ev: Event) {

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    if(this._virtualInfiniteScroller === undefined){
      return;
    }

    this._virtualInfiniteScroller.destroy();

  }


}