
import { Router } from "html5-history-router";

class PageRouter extends Router {

  private _currentRoute: string | undefined;

  constructor(options?: any) {

    super(options);


    //-- disable

    document.addEventListener("click", ev => {

      const anchorElement = (ev.target as HTMLElement).closest("a");

      if(anchorElement === null){
        return;
      }

      if(anchorElement.classList.contains("external")){
        return;
      }

      const href = anchorElement.getAttribute("href");

      if(href === null){
        return;
      }

      this.pushState(href);

      if(href.substr(0, 1) !== "#"){
        ev.preventDefault();
      }

    });

  }


  public navigate(url: string) {
    super.pushState(url);
  }


  public _loadURL(url: string) {

    const req = new XMLHttpRequest();

    this._currentRoute = url;

    const progressPromise = new Promise((resolve, reject) => {
      req.onprogress = ev => {
        if(ev.lengthComputable){
          const percentComplete = (ev.loaded / ev.total) * 100;
          resolve(percentComplete);
        }
      };
    });

    const loadPromise = new Promise((resolve, reject) => {
      req.open("GET", "/pages" + url + ".php", true);
      req.onreadystatechange = () => {
        if(req.readyState == 4){
          resolve(req.responseText);
        }
      };
    });

    req.send();

    return [progressPromise, loadPromise];

  }


  public get currentRoute(): string | undefined {
    return this._currentRoute;
  }

}

const router = new PageRouter();

export default router;