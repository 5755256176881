import { getUrlVars } from "../../../shared/functions/functions";
import * as constants from "shared-constants";
import API from "../modules/api";

export default class PasswordReset {

  private _hashIsValid: boolean = false;
  private _hash: string | undefined;

  private _resetPasswordFormElement: HTMLFormElement;

  private _resetPasswordEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  constructor() {

    this._hash = getUrlVars().hash;

    this.validateHash();


    //-- Add event listeners

    this._resetPasswordEvent = this._resetPassword.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    this._resetPasswordFormElement = (document.querySelector("main.password-reset .reset-password-form") as HTMLFormElement);

    this._resetPasswordFormElement?.addEventListener("submit", this._resetPasswordEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }


  private async _resetPassword(ev: Event) {

    ev.preventDefault();

    if(this._hash === undefined){
      return;
    }

    const password = (document.querySelector("main.password-reset .reset-password-form .reset-password-input") as HTMLInputElement).value;
    const passwordConfirmation = (document.querySelector("main.password-reset .reset-password-form .reset-password-confirm-input") as HTMLInputElement).value;

    if(password !== passwordConfirmation){
      return;
    }

    if(!this._hashIsValid){
      console.log("hash is invalid");
      return;
    }

    const passwordChangeResult = await API.resetPassword(password, this._hash);

    if(passwordChangeResult.status === "success"){
      window.location.href = constants.URLS.WEBSITE_LOGIN_URL;
    }

  }


  private async validateHash() {
    if(this._hash !== undefined){
      const hashIsValid = await API.checkIfResetPasswordHashIsValid(this._hash);
      this._hashIsValid =  hashIsValid.status === "success";

      if(this._hashIsValid !== true){
        const resetPasswordStatus = (document.querySelector("main.password-reset .reset-password-status") as HTMLElement);
        resetPasswordStatus.innerText = "Der Link ist ungültig";
      }

    }
  }


  public destroy() {
    this._resetPasswordFormElement?.removeEventListener("submit", this._resetPasswordEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);
  }

}
