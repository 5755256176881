import API from "../modules/api";
import * as constants from "shared-constants";

import "../styles/settings.css";

const instances: Array<Settings> = [];

export default class Settings {

  private _clickEvent: (ev: Event) => void;
  private _inputInputEvent: (ev: Event) => void;
  private _beforeRouteLeaveEvent: (ev: Event) => void;

  private _changeAddressEvent: (ev: Event) => void;
  private _changePasswordEvent: (ev: Event) => void;

  private _companyID: string | undefined;


  constructor() {


    //-- Destroy old instances

    Settings.destroy();
    instances.push(this);


    //-- Add event listener

    this._clickEvent = this._click.bind(this);
    this._inputInputEvent = this._inputInput.bind(this);
    this._changeAddressEvent = this._changeAddress.bind(this);
    this._changePasswordEvent = this._changePassword.bind(this);
    this._beforeRouteLeaveEvent = this.destroy.bind(this);

    document.addEventListener("click", this._clickEvent);
    document.addEventListener("input", this._inputInputEvent);
    document.addEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

    const registerForm = document.querySelector("form.address-form") as HTMLFormElement;
    registerForm?.addEventListener("submit", this._changeAddressEvent);

    const changePasswordForm = document.querySelector("form.password-form") as HTMLFormElement;
    changePasswordForm?.addEventListener("submit", this._changePasswordEvent);


    //-- Load account data

    this._fillAccountData();

  }


  private async _fillAccountData() {

    const result = await API.getBillingData();

    if(result.status !== "success"){
      return;
    }

    const billingData = result.account;

    const addressForm = document.querySelector("main.settings form.address-form");

    if(addressForm === null){
      return;
    }

    const firstNameInput = addressForm.querySelector(".address-firstname-input") as HTMLInputElement;
    const lastNameInput = addressForm.querySelector(".address-lastname-input") as HTMLInputElement;
    const companyInput = addressForm.querySelector(".address-company-input") as HTMLInputElement;
    const phoneInput = addressForm.querySelector(".address-phone-input") as HTMLInputElement;
    const streetInput = addressForm.querySelector(".address-street-input") as HTMLInputElement;
    const houseNumberInput = addressForm.querySelector(".address-housenumber-input") as HTMLInputElement;
    const zipInput = addressForm.querySelector(".address-zip-input") as HTMLInputElement;
    const cityInput = addressForm.querySelector(".address-city-input") as HTMLInputElement;

    const profilePicture = document.querySelector("main.settings .profile-picture ") as HTMLImageElement;
    const profilePictureFallback = document.querySelector("main.settings .profile-picture-fallback ") as HTMLImageElement;
    const logo = document.querySelector("main.settings .logo ") as HTMLImageElement;
    const logoFallback = document.querySelector("main.settings .logo-fallback ") as HTMLImageElement;

    firstNameInput.value = billingData.FirstName;
    lastNameInput.value = billingData.Name;
    companyInput.value = billingData.Company;
    phoneInput.value = billingData.Phone;
    streetInput.value = billingData.Street;
    houseNumberInput.value = billingData.HouseNumber;
    zipInput.value = billingData.Zip;
    cityInput.value = billingData.City;

    this._companyID = billingData.CompanyID;

    if(profilePicture !== null && billingData.ProfilePicture !== ""){
      profilePicture.onload = () => {
        profilePicture.classList.remove("hidden");
        profilePictureFallback.classList.add("hidden");
      };
      profilePicture.setAttribute("src", constants.URLS.DOWNLOADS_COMPANIES_BASE_PATH + this._companyID + "/account/images/" + billingData.ProfilePicture + "?" + Date.now());
    }
    if(logo !== null && billingData.Logo !== ""){
      logo.onload = () => {
        logo.classList.remove("hidden");
        logoFallback.classList.add("hidden");
      };
      logo.setAttribute("src", constants.URLS.DOWNLOADS_COMPANIES_BASE_PATH + this._companyID + "/account/images/" + billingData.Logo + "?" + Date.now());
    }

  }


  private async _changeAddress(ev: Event) {

    ev.preventDefault();

    const addressForm = document.querySelector("main.settings form.address-form");

    if(addressForm === null){
      return;
    }

    const firstNameInput = addressForm.querySelector(".address-firstname-input") as HTMLInputElement;
    const lastNameInput = addressForm.querySelector(".address-lastname-input") as HTMLInputElement;
    const companyInput = addressForm.querySelector(".address-company-input") as HTMLInputElement;
    const phoneInput = addressForm.querySelector(".address-phone-input") as HTMLInputElement;
    const streetInput = addressForm.querySelector(".address-street-input") as HTMLInputElement;
    const houseNumberInput = addressForm.querySelector(".address-housenumber-input") as HTMLInputElement;
    const zipInput = addressForm.querySelector(".address-zip-input") as HTMLInputElement;
    const cityInput = addressForm.querySelector(".address-city-input") as HTMLInputElement;
    const changeAddressStatus = addressForm.querySelector(".change-address-status") as HTMLElement;

    const newAddress = {
      firstname: firstNameInput.value,
      lastname: lastNameInput.value,
      company: companyInput.value,
      phone: phoneInput.value,
      street: streetInput.value,
      housenumber: houseNumberInput.value,
      zip: zipInput.value,
      city: cityInput.value
    };

    const result = await API.changeAddress(newAddress);

    if(result.status === "success"){
      changeAddressStatus.classList.add("success");
      changeAddressStatus.classList.remove("error");
      changeAddressStatus.classList.remove("hidden");
      changeAddressStatus.innerHTML = "Adresse wurde aktualisiert.";
    } else {
      changeAddressStatus.classList.add("error");
      changeAddressStatus.classList.remove("success");
      changeAddressStatus.classList.remove("hidden");
      changeAddressStatus.innerHTML = "Überprüfen Sie ihre Eingaben.";
    }

  }


  private async _uploadAssets() {

    if(this._companyID === undefined){
      return;
    }

    const profilePictureInput = document.querySelector("main.settings .profile-picture-upload input[type='file'].profile-picture-file-input ") as HTMLInputElement;
    const logoInput = document.querySelector("main.settings .logo-upload input[type='file'].logo-file-input ") as HTMLInputElement;

    if(profilePictureInput === null && logoInput === null){
      return;
    }

    const formData = new FormData();

    let profilePicture: string | undefined;
    let logo: string | undefined;


    //-- Profile picture

    if(profilePictureInput.files !== null){

      const profilePictureFile = profilePictureInput.files[0];

      if(profilePictureFile !== null && profilePictureFile !== undefined){
        if(profilePictureFile.type.match("image.*")){

          const name = profilePictureFile.name;
          const lastDot = name.lastIndexOf(".");

          const fileName = name.substring(0, lastDot);
          const ext = name.substring(lastDot + 1);

          profilePicture = "profile." + ext.toLowerCase();

          formData.append("files[]", profilePictureFile, profilePicture);
        }
      }
    }


    //-- Logo

    if(logoInput.files !== null){

      const logoFile = logoInput.files[0];

      if(logoFile !== null && logoFile !== undefined){
        if(logoFile.type.match("image.*")){

          const name = logoFile.name;
          const lastDot = name.lastIndexOf(".");

          const fileName = name.substring(0, lastDot);
          const ext = name.substring(lastDot + 1);

          logo = "logo." + ext.toLowerCase();

          formData.append("files[]", logoFile, logo);
        }
      }
    }

    formData.append("uploadAccountPictures", "true");
    formData.append("companyID", this._companyID);

    const request = new XMLHttpRequest();

    request.open("POST", constants.URLS.UPLOAD_PATH, true);

    request.upload.onprogress = ev => {

      // const progressElement = this._modulePopup.popup.querySelector("progress");

      // if(progressElement !== null){
      //   progressElement.classList.remove("hidden");
      //   progressElement.value = (100 / ev.total * ev.loaded);
      // }

    };

    request.onload = async() => {
      console.log(request.status, request.statusText, request.responseText);
      if(request.status === 200){
        await API.changeProfilePicture({ profilePicture, logo });
        this._fillAccountData();
      }
    };

    request.send(formData);

  }


  private async _changePassword(ev: Event) {

    ev.preventDefault();

    const passwordForm = document.querySelector("main.settings form.password-form") as HTMLFormElement;

    if(passwordForm === null){
      return;
    }

    const oldPasswordInput = passwordForm.querySelector(".password-old-input") as HTMLInputElement;
    const passwordInput = passwordForm.querySelector(".password-input") as HTMLInputElement;
    const passwordConfirmInput = passwordForm.querySelector(".password-confirm-input") as HTMLInputElement;
    const changePasswordStatus = passwordForm.querySelector(".change-password-status") as HTMLElement;

    if(passwordInput.value !== passwordConfirmInput.value){
      passwordConfirmInput.classList.add("invalid");
      return;
    } else {
      passwordConfirmInput.classList.remove("invalid");
    }

    const result = await API.changePassword(passwordInput.value, oldPasswordInput.value);

    if(result.status === "success"){
      changePasswordStatus.classList.add("success");
      changePasswordStatus.classList.remove("error");
      changePasswordStatus.classList.remove("hidden");
      changePasswordStatus.innerHTML = "Passwort wurde aktualisiert.";
      passwordForm.reset();
    } else {
      changePasswordStatus.classList.add("error");
      changePasswordStatus.classList.remove("success");
      changePasswordStatus.classList.remove("hidden");
      changePasswordStatus.innerHTML = "Überprüfen Sie ihre Eingaben.";
    }

  }


  public static destroy() {
    for(const instance of instances){
      instance.destroy();
    }
  }


  private async _inputInput(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target.classList.contains("profile-picture-file-input") || target.classList.contains("logo-file-input")){
      this._uploadAssets();
    }

  }


  private async _click(ev: Event) {

    const target = ev.target as HTMLElement;

    // if(target.closest(".retailer-edit") !== null){

    //   const retailerElement = target.closest(".retailer-item");

    //   if(retailerElement !== null){
    //     const id = retailerElement.getAttribute("id");
    //     if(id !== null){
    //       this._editRetailer(+id);
    //     }
    //   }

    // }

  }


  public destroy() {


    //-- Remove event listener

    document.removeEventListener("click", this._clickEvent);
    document.removeEventListener("input", this._inputInputEvent);
    document.removeEventListener("beforerouteleave", this._beforeRouteLeaveEvent);

  }

}